import { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Row, Spin, Tag, Typography } from 'antd';
import PageContainer from '../components/PageContainer';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import Announcement from '../models/Announcement';
import AnnouncementContext from '../state/Announcement';
import r from '../http';
import DateTime from '@helpers/date-time';

const { Text } = Typography;

const ViewAnnouncementScreen = () => {
	const { announcements } = useContext(AnnouncementContext);
	const [announcement, setAnnouncement] = useState<Announcement>();
	const id = useParams().id!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
	const navigate = useNavigate();

	useEffect(() => {
		(async () => {
			const savedAnnouncement = announcements.find((announcement) => announcement.id === parseInt(id));
			if (savedAnnouncement) {
				setAnnouncement(savedAnnouncement);
				return;
			}

			const { data } = await r.get<Announcement>(`/admin/announcements/${id}`);
			setAnnouncement(data);
		})();
	}, [id, announcements]);

	const labelLayout = {
		md: 6,
		sm: 24,
		xs: 24,
	};

	const valueLayout = {
		md: 12,
		sm: 24,
		xs: 24,
	};

	return (
		<PageContainer
			title="Viktigt meddelande detaljer"
			back
			extra={[<Button key="edit" onClick={() => navigate('edit')} type="primary" icon={<EditOutlined />} />]}
		>
			<Spin spinning={!announcement} tip="Hämtar meddelande...">
				<Card>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Titel
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{announcement?.title}</Text>
						</Col>
					</Row>
					<Divider />
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Innehåll
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{announcement?.message}</Text>
						</Col>
					</Row>
					<Divider />
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Aktiverad
							</Text>
						</Col>
						<Col {...valueLayout}>
							{announcement?.isActive ? <Tag color="success">Ja</Tag> : <Tag color="default">Nej</Tag>}
						</Col>
					</Row>
					<Divider />
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Skapad
							</Text>
						</Col>
						<Col {...valueLayout}>
							{announcement && <Text>{DateTime.fromISOUTC(announcement?.createdAt).toFormat('YYYY-MM-DD HH:mm')}</Text>}
						</Col>
					</Row>
					<Divider />
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Uppdaterad
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>
								{announcement?.updatedAt
									? DateTime.fromISOUTC(announcement?.updatedAt).toFormat('YYYY-MM-DD HH:mm')
									: '-'}
							</Text>
						</Col>
					</Row>
				</Card>
			</Spin>
		</PageContainer>
	);
};

export default ViewAnnouncementScreen;
