import { useCallback, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import StoreContext from '../state/Store';
import usePermissions from './permissions';

/**
 * Custom hook to manage store selection logic.
 * @param allowAll Boolean to determine if "all stores" (-1) is allowed for Admin/Maintainer.
 */
const useStoreSelector = (allowAll: boolean) => {
	const { stores, defaultStore } = useContext(StoreContext);
	const [searchParams, setSearchParams] = useSearchParams();
	const { isAdminOrMaintainer } = usePermissions();

	/**
	 * Helper function to extract and validate the `storeId` parameter from search params.
	 */
	const getStoreIdParam = useCallback(
		(searchParams: URLSearchParams) => {
			const storeIdParam = searchParams.get('storeId');
			return storeIdParam && stores.find((s) => s.id === parseInt(storeIdParam)) !== undefined
				? parseInt(storeIdParam)
				: null;
		},
		[stores]
	);

	// State to hold the currently selected store ID.
	const [selectedStore, setSelectedStore] = useState<number | null>(null);

	/**
	 * Effect to handle initial store selection when the component mounts or dependencies change.
	 * Ensures valid store ID selection based on user permissions and provided search params.
	 */
	useEffect(() => {
		// Wait until `stores` and `defaultStore` are loaded before proceeding.
		if (!stores || stores.length === 0 || defaultStore === undefined) {
			return;
		}

		const storeIdParam = getStoreIdParam(searchParams);

		if (isAdminOrMaintainer) {
			// For Admin/Maintainer, allow selecting all stores (-1) or default store based on `allowAll`.
			if (storeIdParam === null || !stores.find((s) => s.id === storeIdParam)) {
				if (allowAll) {
					setSelectedStore(-1); // Represent "all stores".
				} else {
					setSelectedStore(defaultStore); // Fallback to the default store.
				}
			} else {
				setSelectedStore(storeIdParam); // Use valid store ID from params.
			}
		} else {
			// For non-admin (franchise) users, fallback to default store if no valid store ID is found.
			if (storeIdParam === null || !stores.find((s) => s.id === storeIdParam)) {
				setSelectedStore(defaultStore);
			} else {
				setSelectedStore(storeIdParam);
			}
		}
	}, [stores, defaultStore, isAdminOrMaintainer, searchParams, allowAll, getStoreIdParam]);

	/**
	 * Effect to update the URL's search params when the selected store changes.
	 */
	useEffect(() => {
		if (selectedStore === -1) {
			// Clear search params when "all stores" is selected.
			setSearchParams('');
		} else if (selectedStore !== null) {
			// Update the `storeId` in the search params.
			setSearchParams(`storeId=${selectedStore}`);
		}
	}, [selectedStore, setSearchParams]);

	// Return the stores, selected store, and utilities for modifying state.
	return { stores, selectedStore, setSelectedStore, showAll: isAdminOrMaintainer };
};

export default useStoreSelector;
