import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import logoAllWhite from '../../../assets/images/logo-all-white.png';
import PriceLabel, { PriceType } from '../PriceLabel';
import createShadowRoot from '../ShadowRoot';
import { styleContent } from './styles/styleContent';
import { getImage } from '../../functions/getImage';
import ResizableTextWrapper from '@components/resizeable-text/ResizableTextWrapper';
import { previewUpdateDelay } from '../../functions/print';
import { Offer4inA4WithImage } from '../types';
import Draggable from 'react-draggable';
import Scalable from '../Scalable';
import useOfferStyles from '@hooks/offer-styles';
import { priceLabelConstants4inA4 } from '../../constants';

interface Props {
	offers: Offer4inA4WithImage[];
	iFrameId: string;
	show24FoodLogo: boolean;
	setPriceLabelScale: (id: number, scale: number) => void;
	setPriceLabelOffset: (offset: { id: number; x: number; y: number }) => void;
}

const Preview = ({ show24FoodLogo, offers, iFrameId, setPriceLabelScale, setPriceLabelOffset }: Props) => {
	const previewRef = useRef<HTMLDivElement | null>(null);
	const { styles, updateStyle } = useOfferStyles(styleContent);
	const [offsets, setOffsets] = useState<{ id: number; x: number; y: number }[]>([
		{
			id: 0,
			x: offers[0]?.priceLabelOffsetX ?? priceLabelConstants4inA4.defaultOffsetX,
			y: offers[0]?.priceLabelOffsetY ?? priceLabelConstants4inA4.defaultOffsetY,
		},
		{
			id: 1,
			x: offers[1]?.priceLabelOffsetX ?? priceLabelConstants4inA4.defaultOffsetX,
			y: offers[1]?.priceLabelOffsetY ?? priceLabelConstants4inA4.defaultOffsetY,
		},
		{
			id: 2,
			x: offers[2]?.priceLabelOffsetX ?? priceLabelConstants4inA4.defaultOffsetX,
			y: offers[2]?.priceLabelOffsetY ?? priceLabelConstants4inA4.defaultOffsetY,
		},
		{
			id: 3,
			x: offers[3]?.priceLabelOffsetX ?? priceLabelConstants4inA4.defaultOffsetX,
			y: offers[3]?.priceLabelOffsetY ?? priceLabelConstants4inA4.defaultOffsetY,
		},
	]);

	useLayoutEffect(() => {
		const iframe = document.getElementById(iFrameId) as HTMLIFrameElement | null;
		if (iframe) {
			const iframeDocument = iframe.contentDocument;
			if (iframeDocument) {
				const iframeBody = iframeDocument.body;
				if (iframeBody) {
					setTimeout(() => {
						iframeBody.innerHTML = previewRef.current?.outerHTML || '';
					}, previewUpdateDelay);
					setTimeout(() => {
						iframeBody.innerHTML = previewRef.current?.outerHTML || '';
					}, previewUpdateDelay);
				}
			}
		}
	});

	const updateScale = (id: number, scale: number) => {
		setPriceLabelScale(id, scale);
		updateStyle();
	};

	const updateOffset = (e: any, data: any, id: number) => {
		setOffsets((prevOffsets) =>
			prevOffsets.map((offset) => (offset.id === id ? { ...offset, x: data.x, y: data.y } : offset))
		);
		setPriceLabelOffset({ id, x: data.x, y: data.y });
		updateStyle();
	};

	// Update the local offset state, if the user changes the form values manually.
	useEffect(() => {
		setOffsets(
			offers.map(({ id, priceLabelOffsetX, priceLabelOffsetY }) => ({
				id,
				x: priceLabelOffsetX,
				y: priceLabelOffsetY,
			}))
		);
	}, [offers]);

	return (
		<div ref={previewRef} className="preview">
			<style>{styles}</style>
			<div className="pt-four-print">
				{offers.map((offer) => (
					<div key={offer.id} className="pt-four-offer">
						<div className="pt-four-banner" />
						<div className="pt-four-banner-container">
							{show24FoodLogo && <img className="pt-four-logo" src={logoAllWhite} />}
							<ResizableTextWrapper
								textComponent={<h1 className="pt-four-title">{offer.title}</h1>}
								containerSize="100%"
								initialFontSize="70px"
							/>
						</div>
						<div className="pt-four-content">
							<h4 className="pt-four-product">{offer.product}</h4>
							<pre className="pt-four-extra">{offer.extra}</pre>
						</div>
						<div className="pt-four-image-container">
							<img
								className="pt-four-image"
								src={getImage(offer.image)}
								style={{ maxHeight: 240 * offer.imageScale }}
							/>
						</div>
						<Draggable
							position={offsets.find((o) => o.id === offer.id)}
							onStop={(e, data) => updateOffset(e, data, offer.id)}
							bounds={priceLabelConstants4inA4}
						>
							<div className="pt-four-price-container">
								<Scalable
									scale={offer.priceLabelScale}
									min={priceLabelConstants4inA4.min}
									max={priceLabelConstants4inA4.max}
									onScale={(scale) => updateScale(offer.id, scale)}
								>
									{offer.type === PriceType.Price && (
										<PriceLabel
											type="price"
											price={offer.price}
											showPriceDecimals={offer.priceShowDecimals}
											unit={offer.unit}
										/>
									)}
									{offer.type === PriceType.PieceForPiece && (
										<PriceLabel
											type="price-for-piece"
											first={offer.first}
											second={offer.second}
											singlePrice={offer.singlePrice}
										/>
									)}
									{offer.type === PriceType.AmountForPrice && (
										<PriceLabel
											type="amount-for-price"
											quantity={offer.quantity}
											price={offer.price}
											showPriceDecimals={offer.priceShowDecimals}
											save={offer.save}
											showSaveDecimals={offer.saveShowDecimals}
										/>
									)}
								</Scalable>
							</div>
						</Draggable>
					</div>
				))}
			</div>
		</div>
	);
};

export default createShadowRoot(Preview);
