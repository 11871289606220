import { ConfigProvider } from 'antd';
import svSE from 'antd/es/locale/sv_SE';
import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Navigation from './navigation/Navigation';
import { AuthContextProvider } from './state/Auth';
import { FirebaseAuthContextProvider } from './state/FirebaseAuth';

const App = () => {
	return (
		<StrictMode>
			<ConfigProvider locale={svSE}>
				<FirebaseAuthContextProvider>
					<AuthContextProvider>
						<BrowserRouter>
							<Navigation />
						</BrowserRouter>
					</AuthContextProvider>
				</FirebaseAuthContextProvider>
			</ConfigProvider>
		</StrictMode>
	);
};

export default App;
