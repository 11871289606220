import { VendingMachineType } from '../models/VendingMachine';

export const getTypeLabel = (type: VendingMachineType) => {
  switch (type) {
    case VendingMachineType.Medicine:
      return 'Läkemedel';
    case VendingMachineType.Tobacco:
      return 'Tobak';
    case VendingMachineType.Unknown:
    default:
      return 'Okänd';
  }
};

export const typeOptions = [
  {
    value: VendingMachineType.Tobacco,
    label: getTypeLabel(VendingMachineType.Tobacco),
  },
  {
    value: VendingMachineType.Medicine,
    label: getTypeLabel(VendingMachineType.Medicine),
  },
];
