import { useContext, useMemo } from 'react';
import AuthContext from '../state/Auth';
import Role from '../models/Role';

const usePermissions = () => {
	const { user } = useContext(AuthContext);

	const rolesSet = useMemo(() => new Set(user?.roles || []), [user]);

	const hasRole = (role: Role) => rolesSet.has(role);

	const isAdmin = hasRole(Role.Admin);
	const isMaintainer = hasRole(Role.Maintainer);
	const isFranchise = hasRole(Role.FranchiceAdmin);

	const isAdminOrMaintainer = isAdmin || isMaintainer;
	const isAdminOrFranchise = isAdmin || isFranchise;
	const isMaintainerOrFranchise = isMaintainer || isFranchise;

	return { isAdmin, isMaintainer, isFranchise, isAdminOrMaintainer, isAdminOrFranchise, isMaintainerOrFranchise };
};

export default usePermissions;
