import { Button, Divider, notification, Table, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons/';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useContext, useMemo } from 'react';
import PageContainer from '../components/PageContainer';
import TheftActions from './components/FaultActions';
import styles from './styles/FaultScreen.module.scss';
import { useNavigate } from 'react-router-dom';
import useFetch from '../hooks/fetch';
import StoreSelector from '../components/StoreSelector';
import FaultReportContext from '../state/FaultReport';
import FaultReport from '@models/FaultReport';
import usePermissions from '@hooks/permissions';
import useStoreSelector from '@hooks/store-selector';

const FaultReportScreen = () => {
	const { faultReports, setFaultReports } = useContext(FaultReportContext);
	const { stores, selectedStore, showAll, setSelectedStore } = useStoreSelector(true);
	const { isAdminOrFranchise } = usePermissions();

	const navigate = useNavigate();

	const url = useMemo(() => {
		return selectedStore === -1 ? '/admin/fault-reports' : `/admin/fault-reports/store/${selectedStore}`;
	}, [selectedStore]);

	const { isFetching } = useFetch<FaultReport[]>(
		url,
		setFaultReports,
		() => {
			notification.error({
				message: `Vi kunde inte hämta felanmälningar för butiken. Vänligen försök igen.`,
				placement: 'bottomRight',
				duration: 10,
			});
		},
		{ enabled: stores.length > 0 && selectedStore !== null && isAdminOrFranchise }
	);

	const displayedFaults = faultReports
		.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
		.sort((a, b) => Number(a.status) - Number(b.status));

	const columns: ColumnsType<FaultReport> = [
		...(stores.length > 1
			? [
					{
						title: 'Butik',
						key: 'storeId',
						ellipsis: true,
						width: 150,
						sorter: (a: any, b: any) => a.storeId - b.storeId,
						render: (_: any, t: FaultReport) => <>{t.storeName}</>,
					},
			  ]
			: []),

		{
			title: 'Registrerades',
			key: 'createdAt',
			ellipsis: true,
			width: 150,
			sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
			render: (_, t: FaultReport) => <>{dayjs(t.createdAt).format('YYYY-MM-DD HH:mm')}</>,
		},
		{
			title: 'Rapporterad av',
			key: 'createdBy',
			ellipsis: true,
			width: 150,
			render: (_, t: FaultReport) => <>{t.createdBy}</>,
		},
		{
			title: 'Status uppdaterades',
			key: 'updatedAt',
			ellipsis: true,
			width: 150,
			sorter: (a, b) => {
				if (!a.updatedAt && !b.updatedAt) return 0;
				if (!a.updatedAt) return 1;
				if (!b.updatedAt) return -1;
				return a.updatedAt.localeCompare(b.updatedAt);
			},
			render: (_, t: FaultReport) => <>{t.updatedAt ? dayjs(t.updatedAt).format('YYYY-MM-DD HH:mm') : ''}</>,
		},
		{
			title: 'Status uppdaterades av',
			key: 'updatedBy',
			ellipsis: true,
			width: 150,
			render: (_, t: FaultReport) => <>{t.updatedBy}</>,
		},
		{
			title: 'Status',
			key: 'status',
			width: 100,
			sorter: (a, b) => a.status - b.status,
			render: (_, t) => (
				<Tag color={t.status === 1 ? 'green' : 'red'}>{t.status === 1 ? 'Arkiverad' : 'Ej arkiverad'}</Tag>
			),
		},
		{
			width: 50,
			responsive: ['sm'],
			fixed: 'right',
			render: (_, theft: FaultReport) => <TheftActions id={theft.id} />,
		},
		{
			responsive: ['xs'],
			width: 50,
			fixed: 'right',
			render: (_, theft: FaultReport) => <TheftActions id={theft.id} />,
		},
	];

	return (
		<PageContainer
			title="Felanmäningar"
			extra={
				isAdminOrFranchise && (
					<Button
						onClick={() => navigate(selectedStore !== -1 ? `report?storeId=${selectedStore}` : 'report')}
						type="primary"
						icon={<PlusOutlined />}
					>
						Skapa felanmälan
					</Button>
				)
			}
		>
			<div className={styles.actionBar}>
				{stores.length > 1 && (
					<StoreSelector all={showAll} stores={stores} selectedStore={selectedStore} onChange={setSelectedStore} />
				)}
			</div>
			<Divider />
			<Table
				loading={isFetching}
				rowKey={(theft) => theft.id}
				locale={{
					emptyText: 'Inga felanmäningar',
					triggerDesc: 'Tryck för att sortera i fallande ordning',
					triggerAsc: 'Tryck för att sortera i stigande ordning',
					cancelSort: 'Tryck för att nollställa sortering',
				}}
				pagination={{ position: ['bottomRight'], defaultPageSize: 50 }}
				scroll={{ x: '100%' }}
				size="middle"
				columns={columns}
				dataSource={displayedFaults}
			/>
		</PageContainer>
	);
};

export default FaultReportScreen;
