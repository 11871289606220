import { Modal, notification } from 'antd';
import r from '../../http';
import TableActions from '../../components/table/Actions';

interface Props {
	id: number;
	editCategory: () => void;
	removeCategory: (id: number) => void;
}

const DocumentCategoryActions = ({ id, editCategory, removeCategory }: Props) => {
	const removeConfirmation = () => {
		Modal.warning({
			title: `Är du säker att du vill ta bort denna kategori?`,
			content: `Åtgärden går inte att ångra.`,
			okText: 'Ja, ta bort',
			okCancel: true,
			cancelText: 'Avbryt',
			onOk: remove,
		});
	};

	const remove = async () => {
		try {
			await r.delete(`/admin/documents/categories/${id}`);
			removeCategory(id);
			notification.success({
				message: `Kategori borttagen!`,
				placement: 'bottomRight',
				duration: 5,
			});
		} catch (e: any) {
			if (e.response && e.response.status === 400) {
				notification.error({
					message: 'Kan inte ta bort kategorin eftersom den är kopplad till befintliga dokument.',
					placement: 'bottomRight',
					duration: 5,
				});
			} else {
				notification.error({
					message: 'Något gick fel!',
					placement: 'bottomRight',
					duration: 5,
				});
			}
		}
	};

	return <TableActions onEdit={editCategory} onDelete={removeConfirmation} />;
};

export default DocumentCategoryActions;
