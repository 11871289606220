import PageContainer from '@components/PageContainer';
import { Button, Divider, notification, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { DocumentFile } from '@models/DocumentFile';
import { useContext } from 'react';
import DocumentContext from '../state/Document';
import useFetch from '@hooks/fetch';
import useFilterCondition from '@hooks/filter-condition';
import StorageFileCategoryContext from '../state/StorageFileCategory';
import useSearchFilter from '@hooks/search-filter';
import ActionBar, { SearchBar, SelectBar } from '@components/action-bar/ActionBar';
import { documentColumns } from './components/documentColumns';

const DocumentsScreen = () => {
	const { documents, setDocuments } = useContext(DocumentContext);
	const { categories } = useContext(StorageFileCategoryContext);
	const navigate = useNavigate();

	const { filteredData: searchResult, searchTerm, setSearchTerm } = useSearchFilter(documents, ['name']);
	const { filteredData, filterValue, setFilterValue } = useFilterCondition(searchResult, 'category', 'id');

	const { isFetching } = useFetch<DocumentFile[]>(`/admin/documents`, setDocuments, () => {
		notification.error({
			message: 'Vi kunde inte hämta dokumenten. Vänligen försök igen.',
			placement: 'bottomRight',
			duration: 10,
		});
	});

	return (
		<PageContainer
			title="Dokument"
			extra={
				<Button onClick={() => navigate('create')} type="primary" icon={<PlusOutlined />}>
					Lägg till dokument
				</Button>
			}
		>
			<ActionBar>
				<SearchBar placeholder="Sök efter namn" searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
				<SelectBar
					options={categories}
					valueKey="id"
					labelKey="name"
					placeholder="Kategori"
					value={filterValue}
					setValue={setFilterValue}
					allowClear
				/>
			</ActionBar>
			<Divider />
			<Table
				loading={isFetching}
				rowKey={(d) => d.id}
				locale={{
					emptyText: 'Inga dokument',
					triggerDesc: 'Tryck för att sortera i fallande ordning',
					triggerAsc: 'Tryck för att sortera i stigande ordning',
					cancelSort: 'Tryck för att nollställa sortering',
				}}
				pagination={{ position: ['bottomRight'], defaultPageSize: 50 }}
				scroll={{ x: '100%' }}
				size="middle"
				columns={documentColumns}
				dataSource={filteredData}
			/>
		</PageContainer>
	);
};

export default DocumentsScreen;
