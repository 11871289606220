import { Button, Divider, Table, Input, Switch, Alert, notification, Tag, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/lib/table';
import { useContext, useMemo, useState } from 'react';
import styles from './styles/OffersScreen.module.scss';
import { useNavigate } from 'react-router-dom';
import Offer, { OfferType } from '../models/Offer';
import OfferActions from './components/OfferActions';
import OfferContext from '../state/Offer';
import PageContainer from '../components/PageContainer';
import r, { objectToFormData } from '../http';
import { format } from '../helpers/currency';
import StoreSelector from '../components/StoreSelector';
import useFetch from '../hooks/fetch';
import Separator from '../components/Separator';
import ListImage from '@components/table/ListImage';
import dayjs from 'dayjs';
import useStoreSelector from '@hooks/store-selector';

export const getExpirationDateTagColor = (date: string | null | undefined) => {
	if (date == null) return 'green';

	const expirationDate = dayjs(date);

	if (expirationDate) {
		if (dayjs(expirationDate).isSame(dayjs(), 'date')) return 'orange';
		else if (expirationDate < dayjs()) return 'red';
	}

	return 'green';
};

const { Search } = Input;

const ShowOnStoreOpen = ({ offer }: { offer: Offer }) => {
	const { updateOffer } = useContext(OfferContext);
	const [isSaving, setIsSaving] = useState(false);

	const onChange = async (checked: boolean) => {
		setIsSaving(true);

		try {
			const response = await r.put<Offer>(
				`/admin/offers/${offer.id}`,
				objectToFormData({
					...offer,
					showOnStoreOpen: checked,
				})
			);

			updateOffer(response.data);
		} catch (e) {}

		setIsSaving(false);
	};

	return <Switch onChange={onChange} checked={offer.showOnStoreOpen} loading={isSaving} />;
};

const columns: ColumnsType<Offer> = [
	{
		title: 'Titel',
		dataIndex: 'title',
		key: 'title',
		width: 200,
		sorter: (a, b) => a.title.localeCompare(b.title),
	},
	{
		title: 'Bild',
		dataIndex: 'imageUrl',
		key: 'imageUrl',
		width: 70,
		render: (imageUrl) => <ListImage src={imageUrl} />,
	},
	{
		title: 'Pris',
		key: 'price',
		width: 150,
		sorter: (a, b) => a.price - b.price,
		render: (price, offer) => (
			<Typography>
				{(() => {
					switch (offer.type) {
						case OfferType.DiscountedPrice:
							return format(offer.price);

						case OfferType.QuantityForPrice:
							return `${offer.quantity} för ${format(offer.price)}`;

						case OfferType.QuantityForQuantity:
							return `${offer.quantity} för ${offer.quantityOffered}`;

						default:
							return '';
					}
				})()}
			</Typography>
		),
	},
	{
		title: 'Visa när man öppnar butik',
		dataIndex: 'showOnStoreOpen',
		key: 'showOnStoreOpen',
		width: 220,
		sorter: (a, b) => (a.showOnStoreOpen === b.showOnStoreOpen ? 0 : a.showOnStoreOpen ? -1 : 1),
		render: (_showOnStoreOpen, offer) => <ShowOnStoreOpen offer={offer} />,
	},
	{
		title: 'Giltigt till och med',
		dataIndex: 'expirationDate',
		key: 'expirationDate',
		width: 220,
		sorter: (a, b) =>
			!a.expirationDate && !b.expirationDate
				? 0
				: !a.expirationDate
				? 1
				: !b.expirationDate
				? -1
				: a.expirationDate.localeCompare(b.expirationDate),
		render: (_expirationDate) => (
			<Tag color={getExpirationDateTagColor(_expirationDate)}>{`${
				_expirationDate ? dayjs(_expirationDate).format('YYYY-MM-DD') : 'Tillsvidare'
			}`}</Tag>
		),
	},

	{
		width: 150,
		responsive: ['sm'],
		fixed: 'right',
		render: (_, offer: Offer) => <OfferActions id={offer.id} />,
	},
	{
		responsive: ['xs'],
		width: 50,
		fixed: 'right',
		render: (_, offer: Offer) => <OfferActions id={offer.id} />,
	},
];

interface StoreOffers {
	id: number;
	name: string;
	offers: Offer[];
}

const OffersScreen = () => {
	const { offers, setOffers } = useContext(OfferContext);
	const { stores, selectedStore, showAll, setSelectedStore } = useStoreSelector(true);
	const [searchTerm, setSearchterm] = useState('');

	const navigate = useNavigate();

	const { isFetching } = useFetch<Offer[]>(
		'/admin/offers',
		setOffers,
		() => {
			notification.error({
				message: 'Vi kunde inte hämta erbjudanden för butiken. Vänligen försök igen.',
				placement: 'bottomRight',
				duration: 10,
			});
		},
		{ enabled: stores.length > 0 }
	);

	const displayedOffers = offers.filter(
		(offer) =>
			offer.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
			(selectedStore === -1 || offer.storeIds.includes(selectedStore ?? -1))
	);

	const storesShowOnStoreOpenWarning = useMemo(() => {
		const offersByStoreId = offers.reduce((acc: Record<string, StoreOffers>, offer) => {
			if (!offer.showOnStoreOpen) return acc;

			return offer.storeIds.reduce((acc2: Record<string, StoreOffers>, storeId) => {
				const store = stores.find((store) => store.id === storeId);
				if (!store) return acc2;

				return {
					...acc2,
					[storeId]: {
						id: store.id,
						name: store.name,
						offers: acc2[storeId]?.offers ? acc2[storeId].offers.concat(offer) : [offer],
					},
				};
			}, acc);
		}, {});

		return Object.keys(offersByStoreId).reduce((acc: StoreOffers[], storeId) => {
			if (offersByStoreId[storeId].offers.length === 1) return acc;

			return acc.concat(offersByStoreId[storeId]);
		}, []);
	}, [offers, stores]);

	return (
		<PageContainer
			title="Erbjudanden"
			extra={
				stores.length > 0 ? (
					<Button onClick={() => navigate('create')} type="primary" icon={<PlusOutlined />}>
						Skapa erbjudande
					</Button>
				) : (
					<></>
				)
			}
		>
			<div className={styles.actionBar}>
				<Search
					value={searchTerm}
					onChange={(e) => setSearchterm(e.target.value)}
					size="large"
					placeholder="Sök efter titel"
					style={{ width: 400 }}
				/>
				{stores.length > 1 && (
					<>
						<Separator />
						<StoreSelector all={showAll} stores={stores} selectedStore={selectedStore} onChange={setSelectedStore} />
					</>
				)}
			</div>
			<Divider />
			{storesShowOnStoreOpenWarning.map((storeOffer) => (
				<Alert
					key={storeOffer.id}
					className={styles.showOnStoreOpenWarning}
					message={
						<span>
							{storeOffer.name} har <b>{storeOffer.offers.length}</b> erbjudanden som visas när man öppnar butiken.{' '}
							<b>Det kan max vara en.</b>
							<br /> Åtgärda genom att bara välja ett erbjudande som ska visas när butiken öppnas.
						</span>
					}
					type="warning"
					action={
						selectedStore === -1 && (
							<Button onClick={() => setSelectedStore(storeOffer.id)} size="small" type="primary">
								Visa erbjudanden för {storeOffer.name}
							</Button>
						)
					}
				/>
			))}
			<Table
				loading={isFetching}
				rowKey={(offer) => offer.id}
				locale={{
					emptyText: 'Inga erbjudanden',
					triggerDesc: 'Tryck för att sortera i fallande ordning',
					triggerAsc: 'Tryck för att sortera i stigande ordning',
					cancelSort: 'Tryck för att nollställa sortering',
				}}
				pagination={{ position: ['bottomRight'], defaultPageSize: 50 }}
				scroll={{ x: '100%' }}
				size="middle"
				columns={columns}
				dataSource={displayedOffers}
			/>
		</PageContainer>
	);
};

export default OffersScreen;
