import { Divider, Table, Input, Button } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { useContext, useState } from 'react';
import styles from './styles/StoresScreen.module.scss';
import Store from '../models/Store';
import StoreActions from './components/StoreActions';
import PageContainer from '../components/PageContainer';
import StoreContext from '../state/Store';
import ListImage from '@components/table/ListImage';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import usePermissions from '@hooks/permissions';

const { Search } = Input;

function getColumns(isAdmin: boolean): ColumnsType<Store> {
	return [
		{
			title: 'Namn',
			dataIndex: 'name',
			key: 'name',
			width: 150,
			sorter: (a, b) => a.name.localeCompare(b.name),
		},
		{
			title: 'Bild',
			dataIndex: 'imageUrl',
			key: 'imageUrl',
			width: 70,
			render: (imageUrl) => <ListImage src={imageUrl} />,
		},
		{
			title: 'Stad',
			dataIndex: 'city',
			key: 'city',
			width: 120,
			sorter: (a, b) => a.city.localeCompare(b.city),
		},
		{
			title: 'Adress',
			dataIndex: 'address',
			key: 'address',
			width: 130,
			sorter: (a, b) => a.address.localeCompare(b.address),
		},
		{
			title: 'Postnummer',
			dataIndex: 'postalCode',
			key: 'postalCode',
			width: 100,
			sorter: (a, b) => a.postalCode.localeCompare(b.postalCode),
		},
		{
			width: 100,
			responsive: ['sm'],
			fixed: 'right',
			render: (_, store) => <StoreActions store={store} showDelete={isAdmin} />,
		},
		{
			responsive: ['xs'],
			width: 50,
			fixed: 'right',
			render: (_, store) => <StoreActions store={store} showDelete={isAdmin} />,
		},
	];
}

const StoresScreen = () => {
	const navigate = useNavigate();
	const [searchTerm, setSearchTerm] = useState('');
	const { stores } = useContext(StoreContext);
	const { isAdmin } = usePermissions();
	const filterProperties: ['name', 'city', 'address', 'postalCode'] = ['name', 'city', 'address', 'postalCode'];
	const displayedStores = stores.filter((store) =>
		filterProperties.some((filterProperty) => store[filterProperty].toLowerCase().includes(searchTerm.toLowerCase()))
	);

	const columns = getColumns(isAdmin);

	return (
		<PageContainer
			title="Butiker"
			extra={
				isAdmin && (
					<Button onClick={() => navigate('create')} type="primary" icon={<PlusOutlined />}>
						Skapa butik
					</Button>
				)
			}
		>
			<div className={styles.actionBar}>
				<Search
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					size="large"
					placeholder="Sök efter namn, stad, adress, postnummer"
					style={{ width: 400 }}
				/>
			</div>
			<Divider />
			<Table
				rowKey={(store) => store.id}
				locale={{
					emptyText: 'Inga butiker',
					triggerDesc: 'Tryck för att sortera i fallande ordning',
					triggerAsc: 'Tryck för att sortera i stigande ordning',
					cancelSort: 'Tryck för att nollställa sortering',
				}}
				pagination={{ position: ['bottomRight'], defaultPageSize: 50 }}
				scroll={{ x: '100%' }}
				size="middle"
				columns={columns}
				dataSource={displayedStores}
			/>
		</PageContainer>
	);
};

export default StoresScreen;
