import styles from './styles/LoginScreen.module.scss';
import { useContext, useState } from 'react';
import { Button, Form, Input, Typography } from 'antd';
import { LockOutlined, UserOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import logo from '../../assets/images/logo-black.png';
import AuthContext from '../../state/Auth';
import { Link } from 'react-router-dom';
import CenterDiv from '@components/CenterDiv';

const { Text } = Typography;

const LoginScreen = () => {
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const authContext = useContext(AuthContext);

	const login = async (form: any) => {
		setLoading(true);
		try {
			await authContext.signIn(form.email, form.password);
		} catch (e) {
			setErrorMessage('Inloggning misslyckades.');
		}
		setLoading(false);
	};

	return (
		<CenterDiv>
			<div className={styles.container}>
				<div className={styles.information}>
					<img className={styles.img} src={logo} alt="logo" />
					<span className={styles.field}>
						<div className={styles.title}>Administratör inloggning</div>
					</span>
					<Form name="login" className="login-form" onFinish={login}>
						<Form.Item name="email" rules={[{ required: true, message: 'Vänligen ange e-post' }]}>
							<Input type="email" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="E-post" />
						</Form.Item>
						<Form.Item name="password" rules={[{ required: true, message: 'Vänligen ange lösenord' }]}>
							<Input.Password
								prefix={<LockOutlined className="site-form-item-icon" />}
								type="password"
								placeholder="Lösenord"
								iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
							/>
						</Form.Item>
						<Text type="danger">{errorMessage}</Text>
						<Form.Item>
							<Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
								Logga in
							</Button>
						</Form.Item>
						<Link to="reset-password">Återsäll lösenord</Link>
					</Form>
				</div>
			</div>
		</CenterDiv>
	);
};

export default LoginScreen;
