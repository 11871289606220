import PageContainer from '@components/PageContainer';
import { Button, Divider, Input, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useContext, useMemo, useState } from 'react';
import { getColumns } from './functions/getColumns';
import styles from './styles/UsersScreen.module.scss';
import UserContext from '../../state/User';
import StoreContext from '../../state/Store';
import VendingMachineContext from '../../state/VendingMachine';

const { Search } = Input;

const UsersScreen = () => {
	const { users, isFetching } = useContext(UserContext);
	const { stores } = useContext(StoreContext);
	const { vendingMachines } = useContext(VendingMachineContext);
	const [searchTerm, setSearchterm] = useState('');
	const navigate = useNavigate();

	const storeMap = useMemo(() => new Map(stores.map((store) => [store.id, store])), [stores]);
	const vendingMachineMap = useMemo(() => new Map(vendingMachines.map((vm) => [vm.id, vm])), [vendingMachines]);

	const displayedUsers = users.filter((user) => {
		return (
			user.firstName.toLowerCase().indexOf(searchTerm.toString().toLowerCase()) >= 0 ||
			user.lastName.toLowerCase().indexOf(searchTerm.toString().toLowerCase()) >= 0 ||
			user.email.toLowerCase().indexOf(searchTerm.toString().toLowerCase()) >= 0
		);
	});

	const columns = getColumns(storeMap, vendingMachineMap);

	return (
		<PageContainer
			title="Användare"
			extra={
				<Button onClick={() => navigate('create')} type="primary" icon={<PlusOutlined />}>
					Skapa användare
				</Button>
			}
		>
			<div className={styles.actionBar}>
				<Search
					value={searchTerm}
					onChange={(e) => setSearchterm(e.target.value)}
					size="large"
					placeholder="Sök efter namn eller epost"
					style={{ maxWidth: 400 }}
				/>
			</div>
			<Divider />
			<Table
				rowKey={(user) => user.id}
				locale={{
					emptyText: 'Inga användare',
					triggerDesc: 'Tryck för att sortera i fallande ordning',
					triggerAsc: 'Tryck för att sortera i stigande ordning',
					cancelSort: 'Tryck för att nollställa sortering',
				}}
				pagination={{ position: ['bottomRight'], defaultPageSize: 50 }}
				scroll={{ x: '100%' }}
				size="middle"
				columns={columns}
				dataSource={displayedUsers}
				loading={isFetching}
			/>
		</PageContainer>
	);
};

export default UsersScreen;
