import { BannedUser } from '@models/BannedUser';
import { ColumnsType } from 'antd/lib/table';
import BannedUserActions from './BannedUserActions';
import { Link } from 'react-router-dom';
import DateTime from '@helpers/date-time';

const getColumns = (onDelete: (userIdentifier: string) => void) => {
	const columns: ColumnsType<BannedUser> = [
		{
			title: 'Personnummer/Telefonnummer',
			dataIndex: 'userIdentifier',
			key: 'userIdentifier',
			width: 300,
			sorter: (a, b) => a.userIdentifier.localeCompare(b.userIdentifier),
		},
		{
			title: 'Anledning',
			dataIndex: 'reason',
			key: 'reason',
			width: 300,
			ellipsis: true,
			sorter: (a, b) => a.reason.localeCompare(b.reason),
		},
		{
			title: 'Datum spärrad',
			key: 'blockedAt',
			width: 300,
			sorter: (a, b) => a.blockedAt.localeCompare(b.blockedAt),
			render: (_, event) => <>{DateTime.fromISOUTC(event.blockedAt).toFormat('YYYY-MM-DD HH:mm')}</>,
		},
		{
			title: 'Spärrad av',
			key: 'bannedByUser',
			width: 300,
			sorter: (a, b) =>
				!a.bannedByUser?.email && !b.bannedByUser?.email
					? 0
					: !a.bannedByUser?.email
					? 1
					: !b.bannedByUser?.email
					? -1
					: a.bannedByUser?.email.localeCompare(b.bannedByUser?.email),
			render: (_, event) => (
				<Link to={event.bannedByUser ? `/users/${event.bannedByUser.id}` : ''}>
					{event.bannedByUser ? event.bannedByUser.email : ''}
				</Link>
			),
		},
		{
			width: 150,
			responsive: ['sm'],
			fixed: 'right',
			render: (_, event) => (
				<BannedUserActions
					id={event.id}
					identifier={event.userIdentifier}
					onDelete={() => onDelete(event.userIdentifier)}
				/>
			),
		},
		{
			responsive: ['xs'],
			width: 50,
			fixed: 'right',
			render: (_, event) => (
				<BannedUserActions
					id={event.id}
					identifier={event.userIdentifier}
					onDelete={() => onDelete(event.userIdentifier)}
				/>
			),
		},
	];
	return columns;
};

export default getColumns;
