import { Modal, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import r from '../../http';
import { useContext } from 'react';
import NewsArticleContext from '../../state/NewsArticle';
import NewsArticle from '../../models/NewsArticle';
import TableActions from '../../components/table/Actions';

interface Props {
	id: number;
}

const NewsArticleActions = ({ id }: Props) => {
	const navigate = useNavigate();
	const { deleteNewsArticle } = useContext(NewsArticleContext);

	const removeConfirmation = () => {
		Modal.warning({
			title: `Är du säker att du vill ta bort artikeln?`,
			content: `Om du tar bort artikeln kommer den försvinna från alla kopplade butiker.`,
			okText: 'Ja, ta bort',
			okCancel: true,
			cancelText: 'Avbryt',
			onOk: remove,
		});
	};

	const remove = async () => {
		try {
			await r.delete<NewsArticle>(`/admin/news/${id}`);
			deleteNewsArticle(id);
			notification.success({
				message: `Nyhetsartikel borttaget`,
				placement: 'bottomRight',
				duration: 5,
			});
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
	};

	return (
		<TableActions
			onView={() => navigate(id.toString())}
			onEdit={() => navigate(`${id}/edit`)}
			onDelete={removeConfirmation}
		/>
	);
};

export default NewsArticleActions;
