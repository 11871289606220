import { useContext, useEffect, useState } from 'react';
import r from '../http';
import AuthContext from '../state/Auth';

interface FetchOptions {
	enabled: boolean;
}

const useFetch = <T = unknown>(
	url?: string,
	onSuccess?: (data: T) => void,
	onError?: (e: unknown) => void,
	options?: FetchOptions
) => {
	const { user } = useContext(AuthContext);

	const [isFetching, setIsFetching] = useState(false);
	const [error, setError] = useState(false);
	const [data, setData] = useState<T | null>(null);

	useEffect(() => {
		if (!url || (options && !options.enabled) || user === null) return;

		(async () => {
			setIsFetching(true);
			try {
				const response = await r.get<T>(url);
				setData(response.data);
				if (onSuccess) onSuccess(response.data);
			} catch (e) {
				setError(true);
				if (onError) onError(e);
			}
			setIsFetching(false);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [url, options?.enabled, user]);

	return {
		isFetching,
		error,
		data,
	};
};

export default useFetch;
