import { Button, Col, Dropdown, Row, Space, MenuProps } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined, MoreOutlined } from '@ant-design/icons';

interface Props {
	onView?: () => void;
	onEdit?: () => void;
	onDelete?: () => void;
}

const getMenuItems = ({ onView, onEdit, onDelete }: Props) => {
	let items: MenuProps['items'] = [];

	if (onView) {
		items = items.concat([
			{
				label: 'Detaljer',
				key: '0',
				icon: <EyeOutlined />,
				onClick: onView,
			},
			{
				type: 'divider',
			},
		]);
	}
	if (onEdit) {
		items = items.concat([
			{
				label: 'Redigera',
				key: '1',
				icon: <EditOutlined />,
				onClick: onEdit,
			},
			{
				type: 'divider',
			},
		]);
	}
	if (onDelete) {
		items = items.concat([
			{
				danger: true,
				label: 'Ta bort',
				key: '2',
				icon: <DeleteOutlined />,
				onClick: onDelete,
			},
			{
				type: 'divider',
			},
		]);
	}

	items.pop();

	return items;
};

const Actions = ({ onView, onEdit, onDelete }: Props) => {
	return (
		<Row>
			<Col sm={24} xs={0}>
				{onView && <Button onClick={onView} type="text" shape="circle" icon={<EyeOutlined />} size="large" />}
				{onEdit && <Button onClick={onEdit} type="text" shape="circle" icon={<EditOutlined />} size="large" />}
				{onDelete && (
					<Button onClick={onDelete} type="text" danger shape="circle" icon={<DeleteOutlined />} size="large" />
				)}
			</Col>
			<Col sm={0} xs={24}>
				<Space>
					<Dropdown
						menu={{
							items: getMenuItems({ onView, onEdit, onDelete }),
						}}
						trigger={['click']}
					>
						<Button type="text" shape="circle" icon={<MoreOutlined />} size="large" />
					</Dropdown>
				</Space>
			</Col>
		</Row>
	);
};

export default Actions;
