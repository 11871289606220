export enum VendingMachineType {
	Unknown = 0,
	Tobacco = 1,
	Medicine = 2,
}

export interface VendingMachineResponse {
	id: number;
	name: string;
	storeId: number;
	deviceId: string;
	latitude: number;
	longitude: number;
	type: VendingMachineType;
	maxProductsPerUserPerHour: number;
}

export interface VendingMachine {
	id: number;
	name: string;
	storeId: number;
	deviceId: string;
	latitude: number;
	longitude: number;
	type: VendingMachineType;
	maxProductsPerUserPerHour: number;
	online: boolean | null;
}
