import styles from './styles/ResetPasswordScreen.module.scss';
import { useState } from 'react';
import { Button, Form, Input, Result, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import logo from '../../assets/images/logo-black.png';
import r from '../../http';
import CenterDiv from '@components/CenterDiv';

const { Text } = Typography;

function ResetPasswordScreen() {
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  async function resetPassword(form: any) {
    setLoading(true);
    try {
      await r.post('/admin/auth/reset-password', {
        email: form.email,
      });
      setShowSuccess(true);
    } catch (e) {
      setErrorMessage('Något gick fel. Försök igen senare.');
    }

    setLoading(false);
  }

  return (
    <CenterDiv>
      <div className={styles.container}>
        <div className={styles.information}>
          {showSuccess ? (
            <Result
              status="success"
              title="Kolla in inkorg"
              subTitle="Vi har skickat ett mail till den angiva e-postadressen med en länk du kan använda för att återsälla ditt lösenord."
            />
          ) : (
            <>
              <img className={styles.img} src={logo} alt="logo" />
              <span className={styles.field}>
                <div className={styles.title}>Återställ lösenord</div>
              </span>
              <Form name="login" className="login-form" onFinish={resetPassword}>
                <Form.Item name="email" rules={[{ required: true, message: 'Vänligen ange e-post' }]}>
                  <Input type="email" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="E-post" />
                </Form.Item>
                <Form.Item>
                  <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
                    Skicka email
                  </Button>
                </Form.Item>
                <Text type="danger">{errorMessage}</Text>
              </Form>
            </>
          )}
        </div>
      </div>
    </CenterDiv>
  );
}

export default ResetPasswordScreen;
