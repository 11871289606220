import { useState, useCallback } from 'react';

const useFilterCondition = <T extends object, K extends keyof T>(
	data: T[],
	attribute: K,
	nestedAttributeKey?: keyof T[K]
) => {
	const [filterValue, setFilterValue] = useState<unknown | undefined>(undefined);

	const filteredData = useCallback(() => {
		if (filterValue === undefined) return data;

		return data.filter((item) => {
			const valueToFilter = nestedAttributeKey
				? item[attribute] && (item[attribute] as any)[nestedAttributeKey]
				: item[attribute];

			return valueToFilter === filterValue;
		});
	}, [data, filterValue, attribute, nestedAttributeKey]);

	return {
		filteredData: filteredData(),
		filterValue,
		setFilterValue,
	};
};

export default useFilterCondition;
