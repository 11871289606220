import TobaccoProduct from '@models/TobaccoProduct';
import React, { useContext, useMemo, useState } from 'react';
import Role from '../models/Role';
import useFetch from '@hooks/fetch';
import AuthContext from './Auth';

interface State {
	tobaccoProducts: TobaccoProduct[];
	addTobaccoProduct: (tobaccoProduct: TobaccoProduct) => void;
	updateTobaccoProduct: (tobaccoProduct: TobaccoProduct) => void;
	deleteTobaccoProduct: (id: number) => void;
}

const initialState: State = {
	tobaccoProducts: [],
	addTobaccoProduct: () => undefined,
	updateTobaccoProduct: () => undefined,
	deleteTobaccoProduct: () => undefined,
};

const TobaccoProductContext = React.createContext<State>(initialState);

interface Props {
	children: React.ReactNode;
}

export const TobaccoProductContextProvider = ({ children }: Props) => {
	const { user } = useContext(AuthContext);
	const [tobaccoProducts, setTobaccoProducts] = useState<TobaccoProduct[]>(initialState.tobaccoProducts);

	const allowedRoles = [Role.Admin];

	useFetch<TobaccoProduct[]>('/admin/tobacco-products', (data) => setTobaccoProducts(data), undefined, {
		enabled: user !== null && allowedRoles.some((role) => user.roles.includes(role)),
	});

	const value: State = useMemo(() => {
		return {
			tobaccoProducts,
			addTobaccoProduct: (product: TobaccoProduct) => setTobaccoProducts(tobaccoProducts.concat(product)),
			updateTobaccoProduct: (product: TobaccoProduct) =>
				setTobaccoProducts(tobaccoProducts.map((p) => (p.id === product.id ? product : p))),
			deleteTobaccoProduct: (id: number) => setTobaccoProducts(tobaccoProducts.filter((p) => p.id !== id)),
		};
	}, [tobaccoProducts]);

	return <TobaccoProductContext.Provider value={value}>{children}</TobaccoProductContext.Provider>;
};

export default TobaccoProductContext;
