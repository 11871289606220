import { Select } from 'antd';
import Store from '../models/Store';
import { useEffect, useRef } from 'react';

interface Props {
	stores: Store[];
	selectedStore: number | null;
	onChange: (storeId: number) => void;
	className?: string;
	all?: boolean;
	width?: number | string;
}

const StoreSelector = ({ stores, selectedStore, onChange, all = false, className, width = 200 }: Props) => {
	const init = useRef<boolean>(false);

	/*
	 * This useEffect handles syncing the initial selected store value with the parent component.
	 * The issue occurs because the stores take some time to load, and initially, the selected store
	 * might be `null`. Once the store is set, the form does not sync with the updated value.
	 * This effect ensures the `onChange` is triggered once the `selectedStore` has a valid value.
	 */
	useEffect(() => {
		if (selectedStore !== null && !init.current) {
			onChange(selectedStore);
			init.current = true;
		}
	}, [selectedStore, onChange]);

	return (
		<Select
			className={className}
			value={selectedStore}
			onChange={onChange}
			placeholder="Välj butik"
			size="large"
			style={{ width }}
		>
			{all && <Select.Option value={-1}>Alla butiker</Select.Option>}
			{stores.map((store) => (
				<Select.Option key={store.id} value={store.id}>
					{store.name}
				</Select.Option>
			))}
		</Select>
	);
};

export default StoreSelector;
