import PageContainer from '@components/PageContainer';
import { Button, Card, Divider, Form, Input, notification, Select, Space, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import r from '../../http';
import styles from './styles/CreateUserScreen.module.scss';
import UserContext from '../../state/User';
import User from '@models/User';
import Role from '../../models/Role';
import { getSwedishRoleName } from '@helpers/role';
import StoreContext from '../../state/Store';
import VendingMachineContext from '../../state/VendingMachine';
import Title from 'antd/es/typography/Title';
import { formLayout } from '../../config/constants';

const CreateUserScreen = () => {
	const { stores } = useContext(StoreContext);
	const { vendingMachines } = useContext(VendingMachineContext);

	const { addUser } = useContext(UserContext);

	const navigate = useNavigate();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isFranchiseError, setIsFranchiseError] = useState(false);
	const [form] = Form.useForm();
	const selectedRole = Form.useWatch('role', form);

	const selectedStores = Form.useWatch('stores', form) ?? [];
	const filteredStores = stores.filter(
		(store) => !selectedStores.find((selectedStore: any) => selectedStore.value === store.id)
	);

	const selectedVendingMachines = Form.useWatch('vendingMachines', form) ?? [];
	const filteredVendingMachines = vendingMachines.filter(
		(vm) => !selectedVendingMachines.find((selectedVendingMachine: any) => selectedVendingMachine.value === vm.id)
	);

	const roleOptions = Object.keys(Role).map((key) => ({
		value: key,
		label: getSwedishRoleName(Role[key as keyof typeof Role]),
	}));

	const submit = async (form: any) => {
		if (form.role === Role.FranchiceAdmin && selectedStores.length === 0 && selectedVendingMachines.length === 0) {
			setIsFranchiseError(true);
			return;
		}

		setIsSubmitting(true);

		try {
			const body = {
				firstName: form.firstName,
				lastName: form.lastName,
				email: form.email,
				password: form.password,
				roles: [form.role],
				franchiceStoreIds: selectedStores.map((s: any) => s.value),
				franchiseVendingMachineIds: selectedVendingMachines.map((vm: any) => vm.value),
			};

			const response = await r.post<User>('/admin/admin-users', body);

			addUser(response.data);

			notification.success({
				message: 'Användare skapad!',
				placement: 'bottomRight',
				duration: 5,
			});

			navigate(-1);
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}

		setIsSubmitting(false);
	};

	const validatePasswordStrength = (rule: any, value: string) => {
		return new Promise<void>((resolve, reject) => {
			const isValid = /^(?=.*[A-Z])(?=.*\d).{6,}$/.test(value);
			if (!isValid) {
				if (value === '' || value === undefined) {
					resolve();
				} else {
					reject('Lösenordet måste vara minst 6 tecken långt och inkludera en versal och en siffra.');
				}
			} else {
				resolve();
			}
		});
	};

	return (
		<PageContainer back title="Skapa användare">
			<Form {...formLayout} labelAlign="left" form={form} size="large" onFinish={submit} autoComplete="off">
				<Card>
					<Form.Item label="Förnamn" name="firstName" rules={[{ required: true, message: 'Vänligen ange förnamn' }]}>
						<Input placeholder="Förnamn" autoComplete="new-firstName" spellCheck="false" />
					</Form.Item>
					<Divider />
					<Form.Item label="Efternamn" name="lastName" rules={[{ required: true, message: 'Vänligen ange efternamn' }]}>
						<Input placeholder="Efternamn" autoComplete="new-lastName" spellCheck="false" />
					</Form.Item>
					<Divider />
					<Form.Item
						label="Email"
						name="email"
						rules={[
							{ required: true, message: 'Vänligen ange email' },
							{ type: 'email', message: 'Ange en giltig email-adress' },
						]}
					>
						<Input placeholder="Email" autoComplete="new-email" spellCheck="false" />
					</Form.Item>
					<Divider />
					<Form.Item
						label="Lösenord"
						name="password"
						rules={[{ required: true, message: 'Vänligen ange lösenord' }, { validator: validatePasswordStrength }]}
					>
						<Input.Password placeholder="Lösenord" type="password" autoComplete="new-password" spellCheck="false" />
					</Form.Item>
					<Divider />
					<Form.Item label="Roll" name="role" rules={[{ required: true, message: 'Vänligen välj en roll' }]}>
						<Select placeholder="Roll" options={roleOptions} />
					</Form.Item>
					{selectedRole === 'FranchiceAdmin' ? (
						<>
							<Divider />
							<Title level={5} style={{ marginBottom: 0 }}>
								Butiker och varuautomater
							</Title>
							<Typography.Text type={isFranchiseError ? 'danger' : 'secondary'}>
								Notera: En franchiseanvändare måste ha minst en butik eller en varuautomat.
							</Typography.Text>
							<Form.Item label="Butiker" name="stores" style={{ marginTop: 12 }}>
								<Select
									filterOption={(input, option) =>
										(option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
									}
									mode="multiple"
									placeholder="Ingen butik vald"
									allowClear
									labelInValue
									style={{ width: '100%' }}
								>
									{filteredStores.map((store) => (
										<Select.Option key={store.id} title={store.name} value={store.id}>
											{store.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Divider />
							<Form.Item label="Varuautomater" name="vendingMachines">
								<Select
									filterOption={(input, option) =>
										(option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
									}
									mode="multiple"
									placeholder="Ingen varuautomat vald"
									allowClear
									labelInValue
									style={{ width: '100%' }}
								>
									{filteredVendingMachines.map((vm) => (
										<Select.Option key={vm.id} title={vm.name} value={vm.id}>
											{vm.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</>
					) : (
						<></>
					)}
				</Card>
				<div className={styles.actions}>
					<Form.Item>
						<Space size="middle">
							<Button onClick={() => navigate(-1)} type="text">
								Avbryt
							</Button>
							<Button icon={<PlusOutlined />} type="primary" htmlType="submit" loading={isSubmitting}>
								Skapa användare
							</Button>
						</Space>
					</Form.Item>
				</div>
			</Form>
		</PageContainer>
	);
};

export default CreateUserScreen;
