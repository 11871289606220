import React, { useEffect, useCallback } from 'react';

interface ScalableProps {
	scale: number;
	min: number;
	max: number;
	children: React.ReactNode;
	disabled?: boolean;
	onScale: (scale: number) => void;
}

const Scalable: React.FC<ScalableProps> = ({ scale, min, max, children, disabled = false, onScale }) => {
	const handleWheel = (event: React.WheelEvent<HTMLDivElement>) => {
		if (disabled) return;
		const delta = event.deltaY;

		const newScale = parseFloat((scale + delta * -0.001).toFixed(3));

		const minScale = min;
		const maxScale = max;

		const boundedScale = Math.max(minScale, Math.min(newScale, maxScale));

		onScale(boundedScale);
	};

	const handleGlobalWheel = useCallback(
		(event: WheelEvent) => {
			if (disabled) return;
			event.preventDefault();
		},
		[disabled]
	);

	useEffect(() => {
		if (disabled) return;

		return () => {
			window.removeEventListener('wheel', handleGlobalWheel);
		};
	}, [disabled, handleGlobalWheel]);

	const handleMouseEnter = () => {
		window.addEventListener('wheel', handleGlobalWheel, { passive: false });
	};

	const handleMouseLeave = () => {
		window.removeEventListener('wheel', handleGlobalWheel);
	};

	return (
		<div
			onWheel={handleWheel}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			style={{
				transform: `scale(${scale})`,
				transition: 'transform 0.1s ease-out',
				cursor: 'pointer',
			}}
		>
			{children}
		</div>
	);
};

export default Scalable;
