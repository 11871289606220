import { color } from '../../../styles/color';

export const styleContent = `
@font-face {
  font-family: Glasgow-Regular;
  src: url(assets/fonts/glasgow/Glasgow-Regular.woff);
}
@font-face {
  font-family: Glasgow-DemiBold;
  src: url(assets/fonts/glasgow/Glasgow-DemiBold.woff);
}
@font-face {
  font-family: Glasgow-ExtraBold;
  src: url(assets/fonts/glasgow/Glasgow-ExtraBold.woff);
}

html, body {
  margin: 0;
  padding: 0;
}

.paper {
  position: 'relative',
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.body {
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.product {
  max-width: 80%;
  align-self: flex-end;
  margin: 0 10%;
  margin-top: 20px;
}

.product__name {
  font-size:70px;
  margin: 0;
  font-family: Glasgow-DemiBold;
  line-height: 100%;
  color: ${color.secondary};
}

.product__extra {
  font-size: 24px;
  line-height: 26px;
  margin: 0;
  font-family: Glasgow-Regular;
  color: ${color.secondary};
}

.footer {
  justify-self: flex-end;
  transform: rotate(-4deg);
  width: 110%;
  height: 40%;
  margin-bottom: -5%;
  display: flex;
  flex-direction: column;
}

.footer__line {
  height: 15px;
  background: ${color.primary};
}

.footer__body {
  background: ${color.secondary};
  flex: 1;
  display: flex;
  flex-direction: column;
}

.footer__content {
  transform: rotate(4deg);
  flex: 1;
}

.pre-title {
  color: #FFF;
  margin: 0;
  margin-top: 70px;
  margin-left: 100px;
  font-family: Glasgow-DemiBold;
  line-height:1.2;
}

.title {
  color: #FFF;
  font-weight: 900;
  margin: 0;
  line-height:1.2;
  margin-left: 100px;
  font-family: Glasgow-ExtraBold;
}

.logo {
  height: 40px;
  transform: translate(632px, 48px);
}
`;
