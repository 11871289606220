import { Image, Space, Typography } from 'antd';
import Image1 from '../assets/ttlock-lock-id-1.png';
import Image2 from '../assets/ttlock-lock-id-2.png';

function TTLockLockIdHelp() {
  return (
    <div>
      <Typography.Title level={5}>Lås ID hittar man genom TTLocks hemsida:</Typography.Title>
      <Space style={{ margin: 10 }}>
        <Image height={200} src={Image1} />
        <Image height={200} src={Image2} />
      </Space>
    </div>
  );
}

export default TTLockLockIdHelp;
