import PageContainer from '@components/PageContainer';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import StoreSelector from '@components/StoreSelector';
import { useNavigate } from 'react-router-dom';
import useStoreSelector from '@hooks/store-selector';

const TagScreen = () => {
	const { stores, selectedStore, setSelectedStore } = useStoreSelector(false);
	const navigate = useNavigate();

	return (
		<PageContainer
			title="Taggar"
			extra={
				<>
					<StoreSelector stores={stores} selectedStore={selectedStore} onChange={setSelectedStore} />
					<Button
						onClick={() => navigate(selectedStore !== -1 ? `create?storeId=${selectedStore}` : 'create')}
						type="primary"
						icon={<PlusOutlined />}
					>
						Skapa tagg
					</Button>
				</>
			}
		></PageContainer>
	);
};

export default TagScreen;
