import { useState, useMemo } from 'react';

// Generic hook that filters an array of data based on specified attributes
const useSearchFilter = <T>(data: T[], searchAttributes: (keyof T)[]) => {
	const [searchTerm, setSearchTerm] = useState<string>('');

	const filteredData = useMemo(() => {
		return data.filter((item) => {
			return searchAttributes.some((attribute) => {
				const value = item[attribute];

				// If value is undefined or null, we skip it
				if (value == null) return false;

				// Ensure the value is a string before calling .toLowerCase()
				const stringValue = value.toString().toLowerCase();
				return stringValue.includes(searchTerm.toLowerCase());
			});
		});
	}, [data, searchAttributes, searchTerm]);

	return { filteredData, searchTerm, setSearchTerm };
};

export default useSearchFilter;
