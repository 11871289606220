import DateTime from '@helpers/date-time';
import { DocumentFile } from '@models/DocumentFile';
import { ColumnsType } from 'antd/es/table';
import DocumentActions from './DocumentActions';

export const documentColumns: ColumnsType<DocumentFile> = [
	{
		title: 'Namn',
		dataIndex: 'name',
		key: 'name',
		width: 160,
		ellipsis: true,
		sorter: (a, b) => a.name.localeCompare(b.name),
	},
	{
		title: 'Filtyp',
		dataIndex: 'fileType',
		key: 'fileType',
		width: 160,
		ellipsis: true,
		sorter: (a, b) => a.fileType.localeCompare(b.fileType),
	},
	{
		title: 'Kategori',
		dataIndex: 'category',
		key: 'category',
		width: 160,
		ellipsis: true,
		render: (category) => category.name,
		sorter: (a, b) => a.category.name.localeCompare(b.category.name),
	},
	{
		title: 'Länk',
		dataIndex: 'url',
		key: 'url',
		width: 210,
		ellipsis: true,
		render: (url) => (
			<a href={url} target="_blank" rel="noopener noreferrer">
				{url}
			</a>
		),
	},
	{
		title: 'Skapad',
		dataIndex: 'createdAt',
		key: 'createdAt',
		render: (createdAt) => DateTime.fromISOUTC(createdAt).toFormat('YYYY-MM-DD HH:mm'),
		sortDirections: ['descend', 'ascend'],
		width: 160,
		sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
	},
	{
		width: 150,
		responsive: ['sm'],
		fixed: 'right',
		render: (_, document: DocumentFile) => <DocumentActions id={document.id} />,
	},
	{
		responsive: ['xs'],
		width: 50,
		fixed: 'right',
		render: (_, document: DocumentFile) => <DocumentActions id={document.id} />,
	},
];
