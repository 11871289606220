export enum StorageKey {
  AuthToken = '@auth:token',
}

export const getStorageItem = <T>(key: StorageKey): T | null => {
  const persistedTokenString = localStorage.getItem(key);

  if (!persistedTokenString) return null;

  let data: T | null = null;
  try {
    data = JSON.parse(persistedTokenString);
  } catch (e) {}

  return data;
};

export const setStorageItem = (key: StorageKey, value: any): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeStorageItem = (key: StorageKey): void => {
  localStorage.removeItem(key);
};
