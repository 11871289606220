export enum StoreUnlockOpenType {
  Unknown = 0,
  App = 1,
  Keycard = 2,
  QrCode = 3,
}

export type StoreUnlockEvent = {
  timestamp: string;
  storeName: string;
  isSuccessful: boolean;
  openType: StoreUnlockOpenType;
  identifier: string;
  errorMessage: string;
  distanceMeters: number | null;
  name: string;
};
