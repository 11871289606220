import React, { useContext, useMemo, useState } from 'react';
import useFetch from '../hooks/fetch';
import Role from '../models/Role';
import Store from '../models/Store';
import AuthContext from './Auth';
import User from '@models/User';
import r from '../http';
import { setDoc, doc, GeoPoint, updateDoc, deleteDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { getUnits } from './Unit';
import { isProduction } from '../config';

interface State {
	stores: Store[];
	selectedStore?: Store;
	defaultStore?: number;
	setSelectedStore: (storeId: number) => void;
	createStore: (store: StoreCreateUpdateObject) => Promise<void>;
	editStore: (store: StoreCreateUpdateObject) => Promise<void>;
	deleteStore: (store: Store) => Promise<void>;
}

const initialState: State = {
	stores: [],
	defaultStore: 0,
	selectedStore: undefined,
	setSelectedStore: () => undefined,
	createStore: async () => undefined,
	editStore: async () => undefined,
	deleteStore: async () => undefined,
};

const StoreContext = React.createContext<State>(initialState);

interface Props {
	children: React.ReactNode;
}

function getUserStores(stores: Store[], user: User | null) {
	if (!user) return [];

	if (user.roles.includes(Role.Admin)) {
		return stores;
	} else if (user.roles.includes(Role.Maintainer)) {
		return stores;
	} else if (user.roles.includes(Role.FranchiceAdmin)) {
		return stores.filter((store) => user?.franchiceStoreIds.includes(store.id));
	}

	return [];
}

export const StoreContextProvider = ({ children }: Props) => {
	const [stores, setStores] = useState<Store[]>([]);
	const [selectedStore, setSelectedStore] = useState<number>();
	const { user } = useContext(AuthContext);
	useFetch<Store[]>('/stores', setStores);

	const availableStores = getUserStores(stores, user);

	async function createStore(store: StoreCreateUpdateObject) {
		const response = await r.post<Store>('/admin/stores', createFormDataFromStoreObject(store));

		if (isProduction) {
			try {
				const units = await getUnits();
				const lastUnitId = Math.max(...units.map((unit) => parseInt(unit.unitId)));
				const newUnitId = (lastUnitId + 1).toString();

				await setDoc(doc(firestore, 'units', newUnitId), {
					address: store.address,
					city: store.city,
					imageUrl: response.data.imageUrl,
					lockId: store.lockId,
					lockProvider: 'ttlock',
					lockName: store.name,
					position: new GeoPoint(store.latitude, store.longitude),
					postalCode: store.postalCode,
					storeId: response.data.id,
					unitId: newUnitId,
					unitName: store.name,
				});
			} catch (e) {
				// Reset changes if firebase fails to update store
				await r.delete<Store>(`/admin/stores/${response.data.id}`);
				throw new Error('Failed to create store');
			}
		}

		setStores((prev) => [...prev, response.data]);
	}

	async function editStore(store: StoreCreateUpdateObject) {
		const currentStore = r.get<Store>(`/admin/stores/${store.id}`);
		const unitToUpdate = await findUnit(store.name);

		const response = await r.put<Store>(`/admin/stores/${store.id}`, createFormDataFromStoreObject(store));

		if (isProduction) {
			try {
				await updateDoc(doc(firestore, 'units', unitToUpdate.unitId), {
					address: store.address,
					city: store.city,
					imageUrl: response.data.imageUrl,
					lockId: store.lockId,
					lockProvider: 'ttlock',
					lockName: store.name,
					position: new GeoPoint(store.latitude, store.longitude),
					postalCode: store.postalCode,
					unitId: unitToUpdate.unitId,
					unitName: store.name,
					storeId: response.data.id,
				});
			} catch (e) {
				// Reset changes if firebase fails to update store
				await r.put<Store>(`/admin/stores/${store.id}`, currentStore);
				throw new Error(`Failed to update store with id: ${store.id}`);
			}
		}

		setStores(stores.map((s) => (s.id === response.data.id ? response.data : s)));
	}

	async function deleteStore(store: Store) {
		const unitToDelete = await findUnit(store.name);

		await r.delete(`/admin/stores/${store.id}`);
		if (isProduction) {
			await deleteDoc(doc(firestore, 'units', unitToDelete.unitId));
		}

		setStores((prev) => prev.filter((s) => s.id !== store.id));
	}

	const value: State = useMemo(() => {
		return {
			stores: availableStores,
			defaultStore: availableStores[0]?.id,
			selectedStore: stores.find((store) => store.id === selectedStore),
			setSelectedStore: (storeId: number) => {
				setSelectedStore(storeId);
			},
			createStore,
			editStore,
			deleteStore,
		};
	}, [stores, selectedStore, availableStores, createStore, editStore, deleteStore]);

	return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
};

export default StoreContext;

async function findUnit(storeName: string) {
	const units = await getUnits();
	const unit = units.find((u) => u.unitName === storeName);

	if (!unit) {
		throw new Error(`Unit to update not found: store name: ${storeName}`);
	}

	return unit;
}

type StoreCreateUpdateObject = {
	id?: number;
	name: string;
	image: File;
	latitude: number;
	longitude: number;
	lockId: number;
	city: string;
	address: string;
	postalCode: string;
	information: string;
	phoneNumber: string;
	email: string;
};

function createFormDataFromStoreObject(store: StoreCreateUpdateObject) {
	const body = new FormData();
	if (store.id) {
		body.append('id', store.id.toString());
	}
	body.append('name', store.name);
	if (store.image) {
		body.append('image', store.image);
	}
	body.append('latitude', store.latitude.toString());
	body.append('longitude', store.longitude.toString());
	body.append('lockId', store.lockId.toString());
	body.append('city', store.city);
	body.append('address', store.address);
	body.append('postalCode', store.postalCode);
	body.append('information', store.information);
	body.append('email', store.email);
	body.append('phoneNumber', store.phoneNumber);

	return body;
}
