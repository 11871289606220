import React from 'react';
import { Slider as AntdSlider } from 'antd';

type Props = React.ComponentProps<typeof AntdSlider>;

export type Marks = React.ComponentProps<typeof AntdSlider>['marks'];

function Slider({ ...rest }: Props) {
  return <AntdSlider {...rest} />;
}

export default Slider;
