export const { format } = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'SEK',
	minimumFractionDigits: 2,
});

export const format2 = (value: number | bigint) => (new Intl.NumberFormat('sv-SE', {
	style: 'currency',
	currency: 'SEK',
	minimumFractionDigits: 2,
})).format(value).replace('kr', '').trim();
