export const isValidSwedishPersonnummer = (personnummer: string) => {
	if (!/^\d{12}$/.test(personnummer)) {
		return false;
	}

	const year = parseInt(personnummer.slice(0, 4), 10);
	const month = parseInt(personnummer.slice(4, 6), 10);
	const day = parseInt(personnummer.slice(6, 8), 10);

	const currentYear = new Date().getFullYear();

	const isValidDate = month >= 1 && month <= 12 && day >= 1 && day <= 31 && year <= currentYear;

	if (!isValidDate) return false;

	return luhnCheck(personnummer.slice(2));
};

const luhnCheck = (value: string) => {
	let sum = 0;
	let shouldDouble = false;

	for (let i = value.length - 1; i >= 0; i--) {
		let digit = parseInt(value.charAt(i), 10);

		if (shouldDouble) {
			digit *= 2;
			if (digit > 9) {
				digit -= 9;
			}
		}

		sum += digit;
		shouldDouble = !shouldDouble;
	}

	return sum % 10 === 0;
};
