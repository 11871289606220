import ProductCategory from '@models/ProductCategory';
import React, { useContext, useMemo, useState } from 'react';
import useFetch from '@hooks/fetch';
import Role from '../models/Role';
import AuthContext from './Auth';

interface State {
	productCategories: ProductCategory[];
	addProductCategory: (productCategory: ProductCategory) => void;
	updateProductCategory: (productCategory: ProductCategory) => void;
	deleteProductCategory: (id: number) => void;
}

const initialState: State = {
	productCategories: [],
	addProductCategory: () => undefined,
	updateProductCategory: () => undefined,
	deleteProductCategory: () => undefined,
};

const ProductCategoryContext = React.createContext<State>(initialState);

interface Props {
	children: React.ReactNode;
}

export const ProductCategoryContextProvider = ({ children }: Props) => {
	const { user } = useContext(AuthContext);
	const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);

	const allowedRoles = [Role.Admin];

	useFetch<ProductCategory[]>('/admin/product-categories', (data) => setProductCategories(data), undefined, {
		enabled: user !== null && allowedRoles.some((role) => user.roles.includes(role)),
	});

	const value: State = useMemo(() => {
		return {
			productCategories,
			addProductCategory: (productCategory: ProductCategory) =>
				setProductCategories(productCategories.concat(productCategory)),
			updateProductCategory: (productCategory: ProductCategory) =>
				setProductCategories(productCategories.map((p) => (p.id === productCategory.id ? productCategory : p))),
			deleteProductCategory: (id: number) => setProductCategories(productCategories.filter((p) => p.id !== id)),
		};
	}, [productCategories]);

	return <ProductCategoryContext.Provider value={value}>{children}</ProductCategoryContext.Provider>;
};

export default ProductCategoryContext;
