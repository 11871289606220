import { Typography } from 'antd';

interface CouldNotFindItemProps {
	text?: string;
}

const CouldNotFindItem = ({ text }: CouldNotFindItemProps) => {
	return (
		<div
			style={{
				display: 'flex',
				flexGrow: 1,
				height: '100vh',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
			}}
		>
			<Typography>{text ?? 'Could not find the item.'}</Typography>
		</div>
	);
};

export default CouldNotFindItem;
