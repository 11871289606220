import User from '@models/User';
import { setUser as sentrySetUser } from '@sentry/react';
import { useEffect } from 'react';

export const useSetSentryUser = (user: User | null) => {
  useEffect(() => {
    if (!user) {
      sentrySetUser(null);
      return;
    }

    sentrySetUser({
      email: user.email,
      id: user.id,
    });
  }, [user]);
};

