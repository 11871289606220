import styles from './styles/ShowOrdersScreen.module.scss';
import { useContext, useEffect, useState } from 'react';
import r from '../http';
import { Table, Button } from 'antd';
import { LeftOutlined, DownloadOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/lib/table';
import StoreContext from '../state/Store';
import { useNavigate, Link } from 'react-router-dom';
import { errorMessage } from '../navigation/components/Message';
import Order from '../models/Order';

const MyOrdersScreen = () => {
	const { selectedStore: store } = useContext(StoreContext);
	const [orders, setOrders] = useState<Order[]>([]);
	const [tableData, setTableData] = useState<Order[]>([]);
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		setLoading(true);
		const loadOrders = async () => {
			try {
				//SHOULD BE ARRAY
				const { data } = await r.get<Order>(`/admin/orders/${store?.id}`);
				if (!data) return;
				//SHOULD ALSO BE ARRAY
				let temp = orders;
				temp = [data];
				//LOADER
				setOrders(temp);
			} catch (e) {
				errorMessage(e);
			}
		};
		if (store) {
			loadOrders();
		}
	}, [store?.id]);

	useEffect(() => {
		if (orders.length > 0) {
			setTableData(orders);
			setLoading(false);
		}
	}, [orders]);

	const columns: ColumnsType<Order> = [
		{
			title: 'Notering',
			key: `note`,
			render: (order: Order) => <>{order.note}</>,
		},
		{
			title: 'Antal produkter',
			render: (order: Order) => <>{order.products.length} st</>,
		},
		{
			title: 'Hämta kvitto',
			key: 'action',
			render: (order: Order) => (
				<Link
					to={`/${store?.id}/nyheter`}
					onClick={() => {
						console.log(`Hämta order kvitto: ${order.user.id}`);
					}}
				>
					<DownloadOutlined style={{ fontSize: '18px' }} />
				</Link>
			),
		},
	];

	return (
		<div>
			<div className={styles.header}>
				<h1>Mina beställningar</h1>
			</div>
			<div className={styles.search}>
				<div>
					<Button
						onClick={() => {
							navigate(`/${store?.id}/bestall`);
						}}
						type="primary"
						icon={<LeftOutlined />}
						size="large"
					>
						Tillbaka
					</Button>
				</div>
			</div>
			<div className={styles.container}>
				<Table
					locale={{
						emptyText: 'Inga beställningar',
						triggerDesc: 'Tryck för att sortera i fallande ordning',
						triggerAsc: 'Tryck för att sortera i stigande ordning',
						cancelSort: 'Tryck för att nollställa sortering',
					}}
					pagination={{ position: ['bottomCenter'] }}
					columns={columns}
					dataSource={tableData}
					loading={loading}
					size={'middle'}
				/>
			</div>
		</div>
	);
};

export default MyOrdersScreen;
