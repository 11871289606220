import { useLayoutEffect, useRef } from 'react';
import createShadowRoot from '../ShadowRoot';
import { OfferInstagram } from '../types';
import logo from '../../../assets/images/logo-white.png';
import _4inInstagram from './components/4inInstagram';
import _3inInstagram from './components/3inInstagram';
import _2inInstagram from './components/2inInstagram';
import _1inInstagram from './components/1inInstagram';
import { styleContent } from './styles/styleContent';
import ResizableTextWrapper from '@components/resizeable-text/ResizableTextWrapper';
import { previewUpdateDelay } from '../../functions/print';

const Style = () => <style>{styleContent}</style>;

interface Props {
	preTitle: string;
	title: string;
	offers: OfferInstagram[];
	iFrameId: string;
	show24FoodLogo: boolean;
}

const Preview = ({ preTitle, title, offers, iFrameId, show24FoodLogo }: Props) => {
	const previewRef = useRef<HTMLDivElement | null>(null);

	useLayoutEffect(() => {
		const iframe = document.getElementById(iFrameId) as HTMLIFrameElement | null;
		if (iframe) {
			const iframeDocument = iframe.contentDocument;
			if (iframeDocument) {
				const iframeBody = iframeDocument.body;
				if (iframeBody) {
					setTimeout(() => {
						iframeBody.innerHTML = previewRef.current?.outerHTML || '';
					}, previewUpdateDelay);
				}
			}
		}
	});

	return (
		<div ref={previewRef} className="insta-preview">
			<Style />
			{offers.length === 4 ? (
				<_4inInstagram offers={offers} />
			) : offers.length === 3 ? (
				<_3inInstagram offers={offers} />
			) : offers.length === 2 ? (
				<_2inInstagram offers={offers} />
			) : (
				<_1inInstagram offers={offers} />
			)}
			<div className="insta-footer">
				<div className="insta-footer__line">
					<ResizableTextWrapper
						textComponent={<h2 className="insta-pre-title">{preTitle}</h2>}
						containerSize="90%"
						initialFontSize="70px"
					/>
				</div>
				<div className="insta-footer__body">
					<div className="insta-footer__content">
						<ResizableTextWrapper
							textComponent={<h1 className="insta-title">{title}</h1>}
							containerSize="90%"
							initialFontSize="105px"
						/>
						{show24FoodLogo && <img className="insta-logo" src={logo} />}
					</div>
				</div>
			</div>
		</div>
	);
};

export default createShadowRoot(Preview);
