import { useLayoutEffect, useRef } from 'react';
import logo from '../../../assets/images/logo-white.png';
import PriceLabel, { PriceType } from '../PriceLabel';
import { OfferForm } from '../types';
import createShadowRoot from '../ShadowRoot';
import { styleContent } from './styles/styleContent';
import ResizableTextWrapper from '@components/resizeable-text/ResizableTextWrapper';
import { previewUpdateDelay } from '../../functions/print';

type Props = {
	iFrameId: string;
} & OfferForm;

const Style = () => <style>{styleContent}</style>;

const StoreOfferPrintPreview = ({
	preTitle,
	title,
	product,
	extra,
	show24FoodLogo,
	type,
	price,
	unit,
	save,
	iFrameId,
	priceShowDecimals,
	saveShowDecimals,
	first,
	second,
	quantity,
	singlePrice,
}: Props) => {
	const previewRef = useRef<HTMLDivElement | null>(null);

	useLayoutEffect(() => {
		const iframe = document.getElementById(iFrameId) as HTMLIFrameElement | null;
		if (iframe) {
			const iframeDocument = iframe.contentDocument;
			if (iframeDocument) {
				const iframeBody = iframeDocument.body;
				if (iframeBody) {
					setTimeout(() => {
						iframeBody.innerHTML = previewRef.current?.outerHTML || '';
					}, previewUpdateDelay);
				}
			}
		}
	});

	return (
		<div ref={previewRef} className="paper">
			<Style />
			<div className="body">
				{type === PriceType.Price && (
					<PriceLabel type="price" price={price} showPriceDecimals={priceShowDecimals} unit={unit} />
				)}
				{type === PriceType.PieceForPiece && (
					<PriceLabel type="price-for-piece" first={first} second={second} singlePrice={singlePrice} />
				)}
				{type === PriceType.AmountForPrice && (
					<PriceLabel
						type="amount-for-price"
						quantity={quantity}
						price={price}
						showPriceDecimals={priceShowDecimals}
						save={save}
						showSaveDecimals={saveShowDecimals}
					/>
				)}
				<div className="product">
					<h1 className="product__name">{product}</h1>
					{!!extra && <pre className="product__extra">{extra}</pre>}
				</div>
			</div>
			<div className="footer">
				<div className="footer__line"></div>
				<div className="footer__body">
					<div className="footer__content">
						<ResizableTextWrapper
							textComponent={<h2 className="pre-title">{preTitle}</h2>}
							containerSize="90%"
							initialFontSize="70px"
						/>
						<ResizableTextWrapper
							textComponent={<h1 className="title">{title}</h1>}
							containerSize="90%"
							initialFontSize="110px"
						/>
						{show24FoodLogo && <img className="logo" src={logo} />}
					</div>
				</div>
			</div>
		</div>
	);
};

export default createShadowRoot(StoreOfferPrintPreview);
