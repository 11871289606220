import { Button, Spin, Divider, Card, Row, Col, Typography, Image } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import PageContainer from '@components/PageContainer';
import useTobaccoProducts from '@hooks/tobacco-products';
import useProductCategories from '@hooks/product-category';

const { Text } = Typography;

const TobaccoProductDetailsScreen = () => {
	const { tobaccoProducts } = useTobaccoProducts();
	const { productCategories } = useProductCategories();
	const navigate = useNavigate();
	const id = useParams().id || '0';

	const tobaccoProduct = tobaccoProducts.find((tobaccoProduct) => tobaccoProduct.id === parseInt(id));

	const labelLayout = {
		md: 6,
		sm: 24,
		xs: 24,
	};

	const valueLayout = {
		md: 12,
		sm: 24,
		xs: 24,
	};

	return (
		<PageContainer
			title="Automatprodukt detaljer"
			back
			extra={[<Button key="edit" onClick={() => navigate('edit')} type="primary" icon={<EditOutlined />} />]}
		>
			<Spin spinning={!tobaccoProduct} tip="Hämtar produkt...">
				<Card key={tobaccoProduct?.id}>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								ID
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{tobaccoProduct?.id}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Namn
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{tobaccoProduct?.name}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Bild
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Image height={150} src={tobaccoProduct?.imageUrl} />
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Kategori
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>
								{productCategories.find((productCategorie) => productCategorie.id === tobaccoProduct?.categoryId)?.name}
							</Text>
						</Col>
					</Row>
				</Card>
			</Spin>
		</PageContainer>
	);
};

export default TobaccoProductDetailsScreen;
