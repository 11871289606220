import { Button, Form, Upload, UploadProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Rule } from 'antd/lib/form';
import styles from '../../styles/form/Image.module.scss';
import { NamePath } from 'antd/lib/form/interface';

interface Props {
	label: string;
	name: NamePath;
	rules?: Rule[];
	onRemove?: UploadProps['onRemove'];
	removeable?: boolean;
	required?: boolean;
}

const Image = ({
	label,
	name,
	rules,
	onRemove,
	removeable = true,
	required,
}: Props) => {
	const getValueFromEvent = (e: any) => {
		if (Array.isArray(e)) {
			return e;
		}
		return e?.fileList;
	};

	return (
		<Form.Item label={label} name={name} valuePropName="fileList" getValueFromEvent={getValueFromEvent} rules={rules} required={required}>
			<Upload
				accept="image/*"
				listType="picture"
				multiple={false}
				beforeUpload={() => false}
				previewFile={async (file) => URL.createObjectURL(file)}
				maxCount={1}
				style={{ objectFit: 'contain' }}
				className={styles.upload}
				onRemove={onRemove}
				showUploadList={{
					showRemoveIcon: removeable,
				}}
			>
				<Button icon={<UploadOutlined />}>Välj bild</Button>
			</Upload>
		</Form.Item>
	);
};

export default Image;
