import { Button, Space } from 'antd';
import { RightOutlined } from '@ant-design/icons';

interface Props {
	setId: () => void;
	openModal: () => void;
}

const ApplicationActions = ({ setId, openModal }: Props) => {
	return (
		<Space>
			<Button
				type="primary"
				onClick={() => {
					setId();
					openModal();
				}}
			>
				Granska
				<RightOutlined />
			</Button>
		</Space>
	);
};

export default ApplicationActions;
