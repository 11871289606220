import { useEffect, useRef, useState } from 'react';
import { firestore } from '../firebase';
import PageContainer from '../components/PageContainer';
import { setDoc, doc } from 'firebase/firestore';
import Editor from '@toast-ui/editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import styles from './styles/CommonQuestions.module.scss';
import { Button, notification, Space, Spin } from 'antd';
import TextEditor from '../components/form/TextEditor';
import Setting from '@models/Setting';
import r from '../http';
import useFetch from '@hooks/fetch';
import { isProduction } from '../config';

const CommonQuestionsScreen = () => {
	const editor = useRef<Editor | null>(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [setting, setSetting] = useState<Setting | undefined>(undefined);
	const [initialValue, setInitialValue] = useState<string>('');

	const { isFetching } = useFetch<Setting>('/admin/settings/common-questions', setSetting, () => {
		notification.error({
			message: 'Vi kunde inte hämta informationen. Vänligen försök igen.',
			placement: 'bottomRight',
			duration: 10,
		});
	});

	useEffect(() => {
		if (setting === undefined) return;
		setInitialValue(setting.value);
	}, [setting]);

	const save = async () => {
		setIsSubmitting(true);
		const text = editor.current?.getMarkdown();

		try {
			if (isProduction) {
				await setDoc(doc(firestore, 'settings', 'common-questions'), {
					value: text,
				});
			}

			await r.put<Setting>('/admin/settings/common-questions', { name: 'common-questions', value: text });

			notification.success({
				message: 'Ändringar sparade!',
				placement: 'bottomRight',
				duration: 5,
			});
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}

		setIsSubmitting(false);
	};

	return (
		<PageContainer title="Vanliga frågor">
			{initialValue && <TextEditor ref={editor} initialValue={initialValue} />}
			<Spin spinning={isFetching} tip="Hämtar information...">
				<div className={styles.actions}>
					<Space size="middle">
						<Button onClick={save} size="large" type="primary" htmlType="submit" loading={isSubmitting}>
							Spara
						</Button>
					</Space>
				</div>
			</Spin>
		</PageContainer>
	);
};

export default CommonQuestionsScreen;
