import { Button, Card, Form, Input, Select, Space, notification } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useContext, useState } from 'react';
import styles from './styles/CreateDocumentScreen.module.scss';
import { useNavigate } from 'react-router-dom';
import r from '../http';
import PageContainer from '../components/PageContainer';
import LoaderWithLogo from '@components/LoaderWithLogo';
import useFetchItem from '../hooks/fetch-item';
import CouldNotFindItem from '@components/CouldNotFindItem';
import DocumentContext from '../state/Document';
import { DocumentFile } from '@models/DocumentFile';
import { formLayout } from '../config/constants';
import FormStorageFile from '@components/form/StorageFile';
import StorageFileCategoryModal from '@components/StorageFileCategoryModal';
import useStorageFileCategory from '@hooks/storage-file-category';

const EditDocumentScreen = () => {
	const { documents, editDocument } = useContext(DocumentContext);
	const document = useFetchItem<DocumentFile>({
		localData: documents,
		endpoint: (id) => `/admin/documents/${id}`,
	});

	const [isSubmitting, setIsSubmitting] = useState(false);
	const { categories, isModalOpen, addCategory, editCategory, deleteCategory, openModal, closeModal } =
		useStorageFileCategory();

	const navigate = useNavigate();
	const [form] = Form.useForm();

	// Loading
	if (document === undefined) {
		return <LoaderWithLogo delay={200} />;
	}
	// Not found
	else if (document === null) {
		return <CouldNotFindItem text="Could not find the document." />;
	}

	const submit = async (form: any) => {
		const body = new FormData();
		let fileType = null;
		if (form.document && form.document[0]) {
			const file = form.document[0].originFileObj;
			fileType = file.name.split('.')[1] ?? null;
			body.append('file', file);
		}

		body.append('id', document.id.toString());
		body.append('name', form.name);
		body.append('fileType', fileType ?? document.fileType);
		body.append('categoryId', form.categoryId);

		try {
			setIsSubmitting(true);
			const response = await r.put<DocumentFile>(`/admin/documents/${document.id}`, body);
			editDocument(response.data);

			notification.success({
				message: 'Dokument uppdaterat!',
				placement: 'bottomRight',
				duration: 5,
			});

			navigate(-1);
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
		setIsSubmitting(false);
	};

	return (
		<PageContainer back title="Uppdatera dokument">
			<Form
				{...formLayout}
				labelAlign="left"
				form={form}
				size="large"
				onFinish={submit}
				initialValues={{
					name: document?.name,
					categoryId: document?.category?.id,
				}}
			>
				<Card>
					<Form.Item label="Namn" name="name" rules={[{ required: true, message: 'Vänligen ange namn' }]}>
						<Input placeholder="Namn" />
					</Form.Item>
					<Form.Item
						extra={
							<Button style={{ marginTop: 10 }} onClick={openModal}>
								Hantera kategorier
							</Button>
						}
						label="Kategori"
						name="categoryId"
						rules={[{ required: true, message: 'Vänligen välj kategori' }]}
					>
						<Select
							placeholder="Kategori"
							style={{ width: '100%' }}
							optionFilterProp="label"
							options={categories.map((category) => ({
								value: category.id,
								label: category.name,
							}))}
						/>
					</Form.Item>
					<FormStorageFile label="Dokument" name="document" />
				</Card>
				<div className={styles.actions}>
					<Form.Item>
						<Space size="middle">
							<Button onClick={() => navigate(-1)} type="text">
								Avbryt
							</Button>
							<Button icon={<EditOutlined />} type="primary" htmlType="submit" loading={isSubmitting}>
								Uppdatera dokument
							</Button>
						</Space>
					</Form.Item>
				</div>
			</Form>
			<StorageFileCategoryModal
				open={isModalOpen}
				categories={categories}
				addCategory={addCategory}
				editCategory={editCategory}
				removeCategory={deleteCategory}
				onClose={closeModal}
			/>
		</PageContainer>
	);
};

export default EditDocumentScreen;
