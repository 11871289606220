import { Button, Space, Modal, notification } from 'antd';
import config from '../../../config';

interface Props {
	userId: string;
}

const ApplicationActions = ({ userId }: Props) => {
	const acceptConfirm = () => {
		Modal.warning({
			title: 'Godkänn kontoansökan',
			content: 'Är du säker på att du vill godkänna ansökan?',
			okText: 'Gödkänn',
			okCancel: true,
			cancelText: 'Avbryt',
			onOk: () => accept(),
		});
	};

	const accept = async () => {
		await fetch(`${config.firebaseApiUrl}/acceptApplication`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				userId: userId,
			}),
		});
		notification.success({
			message: 'Ansökan godkänd!',
			placement: 'bottomRight',
			duration: 5,
		});
	};

	const denyConfirm = () => {
		Modal.warning({
			title: 'Avböj kontoansökan',
			content: 'Är du säker på att du vill avböja ansökan?',
			okText: 'Avböj',
			okCancel: true,
			cancelText: 'Avbryt',
			onOk: () => deny(),
		});
	};

	const deny = async () => {
		await fetch(`${config.firebaseApiUrl}/denyApplication`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				userId: userId,
			}),
		});
		notification.success({
			message: 'Ansökan avböjd!',
			placement: 'bottomRight',
			duration: 5,
		});
	};

	return (
		<Space>
			<Button type="primary" onClick={acceptConfirm}>
				Godkänn
			</Button>
			<Button type="primary" danger onClick={denyConfirm}>
				Avböj
			</Button>
		</Space>
	);
};

export default ApplicationActions;
