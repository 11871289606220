export enum PurchaseStatus {
  Pending = 0,
  Accepted = 1,
  Denied = 2,
}

export interface PurchasedProduct {
  name: string;
  price: number;
}

export default interface Event {
  personalIdentificationNumber: string;
  identificationImageUrl: string;
  status: PurchaseStatus;
  storeId: number;
  productShipmentRequestFailed: boolean;
  products: PurchasedProduct[];
  createdAt: string;
  updatedAt: string;
}
