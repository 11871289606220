import { useState, useCallback } from 'react';
import dayjs from 'dayjs';

interface UseOfferStylesReturn {
	styles: string;
	updateStyle: () => void;
}

/**
 * This hook is used in the offer-prints, it rerenders the styles so that
 * the prints are always in sync.
 */
const useOfferStyles = (styleContent: string): UseOfferStylesReturn => {
	const [dummy, setDummy] = useState<dayjs.Dayjs>();

	// This triggers a rerender.
	const updateStyle = useCallback(() => {
		setDummy(dayjs());
	}, []);

	return {
		styles: styleContent,
		updateStyle,
	};
};

export default useOfferStyles;
