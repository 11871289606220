import { useContext } from 'react';
import ScienerLockContext from '../state/ScienerLocks';

export function useScienerLocks() {
	const { locks } = useContext(ScienerLockContext);

	return {
		locks,
	};
}
