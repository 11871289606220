import { Alert, Image, Space, Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useContext } from 'react';
import ApplicationActions from './components/ApplicationActions';
import AccountApplicationContext from '../../state/AccountApplication';
import PageContainer from '../../components/PageContainer';
import FirebaseAuthContext, { AuthStatus as FirebaseAuthStatus } from '../../state/FirebaseAuth';

interface ApplicationResponse {
	firstName: string;
	lastName: string;
	identificationImage: string;
	phoneNumber: string;
	status: string;
	createdAt?: number;
}

interface Application extends ApplicationResponse {
	userId: string;
}

const columns: ColumnsType<Application> = [
	{
		title: 'ID',
		dataIndex: 'identificationImage',
		key: 'identificationImage',
		width: 100,
		render: (image) => (
			<Image
				height={70}
				src={`https://firebasestorage.googleapis.com/v0/b/food-dev-972af-applications/o/${image}?alt=media`}
			/>
		),
	},
	{
		title: 'Förnamn',
		dataIndex: 'firstName',
		key: 'firstName',
		width: 130,
	},
	{
		title: 'Efternamn',
		dataIndex: 'lastName',
		key: 'lastName',
		width: 150,
	},
	{
		title: 'Telefonnummer',
		dataIndex: 'phoneNumber',
		key: 'phoneNumber',
		width: 160,
	},
	{
		title: 'Datum',
		dataIndex: 'createdAt',
		key: 'createdAt',
		width: 175,
		render: (createdAt) => {
			if (!createdAt) return null;

			const daysjsDate = dayjs.unix(createdAt);
			const date = daysjsDate.format('YYYY-MM-DD HH:mm');
			const relative = daysjsDate.fromNow();

			return `${date} (${relative})`;
		},
	},
	{
		title: '',
		key: 'action',
		fixed: 'right',
		width: 210,
		render: (_, event) => <ApplicationActions userId={event.userId} />,
	},
];

const AccountApplicationsScreen = () => {
	const { accountApplications } = useContext(AccountApplicationContext);
	const { status: firebaseAuthStatus } = useContext(FirebaseAuthContext);

	return (
		<PageContainer title="Kontoansökningar">
			<Space direction="vertical">
				{firebaseAuthStatus === FirebaseAuthStatus.SignedOut && (
					<Alert
						type="error"
						showIcon
						message={<b>Error!</b>}
						description={
							<b>Error authenticating with account application service. Report this to the administrator!</b>
						}
					/>
				)}
				<Table
					rowKey={(application) => application.userId}
					locale={{
						emptyText: 'Inga kontoansökningar',
						triggerDesc: 'Tryck för att sortera i fallande ordning',
						triggerAsc: 'Tryck för att sortera i stigande ordning',
						cancelSort: 'Tryck för att nollställa sortering',
					}}
					pagination={false}
					scroll={{ x: '100%' }}
					size="middle"
					columns={columns}
					dataSource={accountApplications}
				/>
			</Space>
		</PageContainer>
	);
};

export default AccountApplicationsScreen;
