import React, { useEffect, useState } from 'react';
import { onAuthStateChanged, signInWithEmailAndPassword, User as FirebaseAuthUser } from 'firebase/auth';
import { auth } from '../firebase';

export enum AuthStatus {
  Pending,
  SignedIn,
  SignedOut,
}

interface State {
  status: AuthStatus;
  signIn: (email: string, password: string) => void;
  singOut: () => void;
}

const initialState: State = {
  status: AuthStatus.Pending,
  signIn: () => undefined,
  singOut: () => undefined,
};

const FirebaseAuthContext = React.createContext<State>(initialState);

interface Props {
  children: React.ReactNode;
}

export const FirebaseAuthContextProvider = ({ children }: Props) => {
  const [status, setStatus] = useState<AuthStatus>(initialState.status);

  const handleAuthStateChange = async (user: FirebaseAuthUser | null) => {
    if (user) {
      setStatus(AuthStatus.SignedIn);
    } else {
      setStatus(AuthStatus.SignedOut);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChange);

    return () => unsubscribe();
  }, []);

  function signIn(email: string, password: string) {
    signInWithEmailAndPassword(auth, email, password);
  }

  function singOut() {
    auth.signOut();
  }

  const context = {
    status,
    signIn,
    singOut,
  };

  return <FirebaseAuthContext.Provider value={context}>{children}</FirebaseAuthContext.Provider>;
};

export default FirebaseAuthContext;
