import { EditOutlined } from '@ant-design/icons';
import PageContainer from '@components/PageContainer';
import useBannedUsers from '@hooks/banned-users';
import { Button, Card, Col, Divider, Row, Spin, Typography } from 'antd';
import { useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

const { Text } = Typography;

const labelLayout = {
	md: 6,
	sm: 24,
	xs: 24,
};

const valueLayout = {
	md: 12,
	sm: 24,
	xs: 24,
};

const BannedAccountDetailScreen = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const { bannedUsers } = useBannedUsers();

	const bannedUser = useMemo(() => bannedUsers.find((user) => user.id === parseInt(id!)), [bannedUsers, id]);

	return (
		<PageContainer
			title="Spärrat konto detaljer"
			back
			extra={<Button icon={<EditOutlined />} onClick={() => navigate('edit')} type="primary" />}
		>
			<Spin spinning={!bannedUser} tip="Hämtar spärrat konto...">
				<Card>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Identifierare
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{bannedUser?.userIdentifier}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Anledning
							</Text>
						</Col>
						<Col {...valueLayout}>
							<pre>{bannedUser?.reason}</pre>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Datum spärrad
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{dayjs(bannedUser?.blockedAt).format('YYYY-MM-DD HH:mm')}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Spärrad av
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Link to={bannedUser?.bannedByUser ? `/users/${bannedUser.bannedByUser.id}` : ''}>
								{bannedUser?.bannedByUser ? bannedUser.bannedByUser.email : ''}
							</Link>
						</Col>
					</Row>
				</Card>
			</Spin>
		</PageContainer>
	);
};

export default BannedAccountDetailScreen;
