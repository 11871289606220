import { Button, Form, Modal, Upload, UploadProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Rule } from 'antd/lib/form';
import styles from '../../styles/form/Image.module.scss';
import { NamePath } from 'antd/lib/form/interface';
import { RcFile } from 'antd/lib/upload';

interface Props {
	label: string;
	name: NamePath;
	rules?: Rule[];
	onRemove?: UploadProps['onRemove'];
	removeable?: boolean;
	required?: boolean;
}

const maxSizeMb = 500;

const FormStorageFile = ({ label, name, rules, onRemove, removeable = true, required }: Props) => {
	const getValueFromEvent = (e: any) => {
		if (Array.isArray(e)) {
			return e;
		}

		const validFiles: RcFile[] = [];

		e?.fileList.forEach((file: RcFile) => {
			if (file.size < maxSizeMb * 1000000) {
				validFiles.push(file);
			}
		});

		return validFiles;
	};

	return (
		<>
			<Form.Item
				label={label}
				name={name}
				valuePropName="fileList"
				getValueFromEvent={getValueFromEvent}
				rules={rules}
				required={required}
				help={maxSizeMb ? `Max filstorlek: ${maxSizeMb} MB` : ''}
			>
				<Upload
					listType="text"
					multiple={false}
					beforeUpload={(file) => {
						if (file.size > maxSizeMb * 1000000) {
							Modal.error({
								title: 'Filen är för stor!',
								content: `Filen är för stor, max storleken är ${maxSizeMb} MB. Vänligen välj en mindre fil!`,
								okText: 'Stäng',
							});
						}

						return false;
					}}
					maxCount={1}
					style={{ objectFit: 'contain' }}
					className={styles.upload}
					onRemove={onRemove}
					showUploadList={{
						showRemoveIcon: removeable,
					}}
				>
					<Button icon={<UploadOutlined />}>Välj fil</Button>
				</Upload>
			</Form.Item>
		</>
	);
};

export default FormStorageFile;
