import React, { useMemo, useState } from 'react';
import Offer from '../models/Offer';

interface State {
	offers: Offer[];
	setOffers: (offers: Offer[]) => void;
	addOffer: (offer: Offer) => void;
	updateOffer: (offer: Offer) => void;
	deleteOffer: (id: number) => void;
}

const initialState: State = {
	offers: [],
	setOffers: () => undefined,
	addOffer: () => undefined,
	updateOffer: () => undefined,
	deleteOffer: () => undefined,
};

const OfferContext = React.createContext<State>(initialState);

interface Props {
	children: React.ReactNode;
}

export const OfferContextProvider = ({ children }: Props) => {
	const [offers, setOffers] = useState<Offer[]>([]);

	const value: State = useMemo(() => {
		return {
			offers,
			setOffers: (offers: Offer[]) => setOffers(offers),
			addOffer: (offer: Offer) => setOffers(offers.concat(offer)),
			updateOffer: (offer: Offer) => setOffers(offers.map((o) => (o.id === offer.id ? offer : o))),
			deleteOffer: (id: number) => setOffers(offers.filter((o) => o.id !== id)),
		};
	}, [offers]);

	return <OfferContext.Provider value={value}>{children}</OfferContext.Provider>;
};

export default OfferContext;
