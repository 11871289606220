import type { ErrorResponse } from './models';

export class RequestError extends Error {
	name = 'RequestError';
	response?: ErrorResponse;

	constructor(message: string, response?: ErrorResponse) {
		super(message);
		this.response = response;
	}
}
