import { Input, Select } from 'antd';
import styles from './styles/ActionBar.module.scss';

const { Search } = Input;

interface SearchBarProps {
	searchTerm: string;
	placeholder: string;
	setSearchTerm: (value: string) => void;
}

export const SearchBar = ({ searchTerm, placeholder, setSearchTerm }: SearchBarProps) => {
	return (
		<Search
			className={styles.actionBarComponent}
			value={searchTerm}
			onChange={(e) => setSearchTerm(e.target.value)}
			size="large"
			placeholder={placeholder}
		/>
	);
};

interface SelectBarProps<T> {
	options: T[];
	valueKey: keyof T;
	labelKey: keyof T;
	placeholder: string;
	value: any;
	setValue: (value: any) => void;
	allowClear?: boolean;
}

export const SelectBar = <T extends object>({
	options,
	valueKey,
	labelKey,
	placeholder,
	value,
	setValue,
	allowClear = true,
}: SelectBarProps<T>) => {
	return (
		<Select
			className={styles.actionBarComponent}
			placeholder={placeholder}
			options={options.map((option) => ({
				value: option[valueKey],
				label: option[labelKey],
			}))}
			size="large"
			onChange={setValue}
			value={value}
			allowClear={allowClear}
		/>
	);
};

interface ActionBarProps {
	children: React.ReactNode;
}
const ActionBar = ({ children }: ActionBarProps) => {
	return <div className={styles.actionBar}>{children}</div>;
};

export default ActionBar;
