import { useContext, useEffect, useState } from 'react';
import { Image, Button, Spin, Divider, Card, Row, Col, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import PageContainer from '../components/PageContainer';
import DiscountContext from '../state/Discount';
import StoreContext from '../state/Store';
import Discount from '../models/Discount';
import r from '../http';

const { Text } = Typography;

const ViewDiscountScreen = () => {
	const { stores } = useContext(StoreContext);
	const { discounts } = useContext(DiscountContext);
	const navigate = useNavigate();
	const id = useParams().id!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
	const [discount, setDiscount] = useState<Discount>();

	useEffect(() => {
		(async () => {
			const savedDiscount = discounts.find((discount) => discount.id === parseInt(id));
			if (savedDiscount) {
				setDiscount(savedDiscount);
				return;
			}

			const { data } = await r.get<Discount>(`/admin/discounts/${id}`);
			setDiscount(data);
		})();
	}, [id, discounts]);

	const labelLayout = {
		md: 6,
		sm: 24,
		xs: 24,
	};

	const valueLayout = {
		md: 12,
		sm: 24,
		xs: 24,
	};

	return (
		<PageContainer
			title="Rabattkod detaljer"
			back
			extra={[<Button key="edit" onClick={() => navigate('edit')} type="primary" icon={<EditOutlined />} />]}
		>
			<Spin spinning={!discount} tip="Hämtar rabattkod...">
				<Card>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Butiker
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>
								{discount?.storeIds
									.map((storeId) => stores.find((s) => s.id === storeId)?.name)
									.filter((store) => !!store)
									.join(', ')}
							</Text>
						</Col>
					</Row>
					<Divider />
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								QR-kod
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Image height={150} src={discount?.imageUrl} />
						</Col>
					</Row>
					<Divider />
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Beskrivning
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{discount?.text}</Text>
						</Col>
					</Row>
				</Card>
			</Spin>
		</PageContainer>
	);
};

export default ViewDiscountScreen;
