import { useEffect } from 'react';
import r from '../http';
import { Token, TokenResponse } from '@models/Token';
import { createTokenFromTokenResponse, hasTokenExpired } from '@helpers/token';

const useTokenRefresher = (
  token: Token | null,
  {
    onTokenExpire,
    onTokenChange,
  }: {
    onTokenExpire?: () => void;
    onTokenChange?: (token: Token) => void;
  }
) => {
  useEffect(() => {
    if (!token) return;

    const interval = setInterval(() => checkTokenValidity(token), 60000);

    return () => {
      clearInterval(interval);
    };
  }, [token]);

  const checkTokenValidity = (token: Token) => {
    // Check if token expires in 5min or less
    if (!hasTokenExpired(token.accessTokenExpireTimestamp, 300)) return;

    // Check if refresh token has expired
    if (hasTokenExpired(token.refreshTokenExpireTimestamp)) {
      onTokenExpire?.();
      return;
    }

    refreshToken();
  };

  const refreshToken = async () => {
    if (!token) return;

    const response = await r.post<TokenResponse>('/admin/auth/refresh', {
      refreshToken: token.refreshToken,
    });

    onTokenChange?.(createTokenFromTokenResponse(response.data));
  };
};

export default useTokenRefresher;
