import { useContext } from 'react';
import { Image, Button, Spin, Divider, Card, Row, Col, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import PageContainer from '../components/PageContainer';
import ProductContext from '../state/Product';

const { Text } = Typography;

const ViewProductScreen = () => {
	const { products } = useContext(ProductContext);
	const navigate = useNavigate();
	const id = useParams().id || '0';

	const product = products.find((product) => product.id === parseInt(id));

	const labelLayout = {
		md: 6,
		sm: 24,
		xs: 24,
	};

	const valueLayout = {
		md: 12,
		sm: 24,
		xs: 24,
	};

	return (
		<PageContainer
			title="Produkt detaljer"
			back
			extra={[<Button key="edit" onClick={() => navigate('edit')} type="primary" icon={<EditOutlined />} />]}
		>
			<Spin spinning={!product} tip="Hämtar produkt...">
				<Card key={product?.id}>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Namn
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{product?.name}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Bild
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Image height={150} src={product?.imageUrl} />
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Artikelnummer
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{product?.articleNumber}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								EAN
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{product?.ean}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Inköpspris
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>SEK {product?.inPrice.toFixed(2)}</Text>
						</Col>
					</Row>
				</Card>
			</Spin>
		</PageContainer>
	);
};

export default ViewProductScreen;
