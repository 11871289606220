import { Button, Card, Col, Divider, Form, Image, Modal, notification, Row, Spin, Tag, Typography } from 'antd';
import { FileAddOutlined, FileExcelOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageContainer from '../components/PageContainer';
import r from '../http';
import Theft from '../models/Theft';
import styles from './styles/ViewFaultScreen.module.scss';
import useFetch from '@hooks/fetch';
import FaultReportContext from '../state/FaultReport';
import FaultReport from '@models/FaultReport';
import FaultReportDescription from './FaultReportDescription';
import usePermissions from '@hooks/permissions';

const { Text } = Typography;

const ViewFaultReportScreen = () => {
	const id = useParams().id || '0';
	const navigate = useNavigate();
	const { faultReports, archiveFaultReport } = useContext(FaultReportContext);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { isAdmin, isFranchise } = usePermissions();

	const { data } = useFetch<FaultReport>(`/admin/fault-reports/${id}`, undefined, () => {
		notification.error({
			message: `Vi kunde inte hämta felanmälan. Vänligen försök igen.`,
			placement: 'bottomRight',
			duration: 10,
		});
	});

	const faultReport = faultReports.find((t) => t.id === parseInt(id)) || data;

	const labelLayout = {
		md: 6,
		sm: 24,
		xs: 24,
	};

	const valueLayout = {
		md: 16,
		sm: 24,
		xs: 24,
	};

	const submit = async () => {
		if (!faultReport) return;
		try {
			setIsSubmitting(true);
			const body = {
				id: faultReport.id,
				status: faultReport.status === 0 ? 1 : 0,
			};
			await r.put<Theft>(`/admin/fault-reports/${faultReport.id}/status`, body);
			const newFault = faultReports.find((t) => t.id === faultReport.id);
			if (!newFault) return;
			newFault.status = 1;
			archiveFaultReport(newFault);
			notification.success({
				message: 'Status på felanmälan uppdaterad!',
				placement: 'bottomRight',
				duration: 5,
			});

			navigate(-1);
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
		setIsSubmitting(false);
	};

	const confirm = () => {
		Modal.confirm({
			title: faultReport?.status === 0 ? 'Arkviera felanmälan' : 'Avarkviera felanmälan',
			content:
				faultReport?.status === 0 ? 'Vill du arkivera denna felanmälan?' : 'Vill du avarkivera denna felanmälan?',
			okText: faultReport?.status === 0 ? 'Ja, arkivera' : 'Ja, avarkivera',
			cancelText: 'Avbryt',
			onOk: () => submit(),
		});
	};

	return (
		<PageContainer back title="Felanmälan detaljer">
			<Spin spinning={!faultReport} tip="Hämtar felanmälan...">
				<Card key={faultReport?.id}>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Butik
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{faultReport?.storeName}</Text>
						</Col>
					</Row>
					<Divider />
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Registrerad
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{dayjs(faultReport?.createdAt).format('YYYY-MM-DD HH:mm')}</Text>
						</Col>
					</Row>
					<Divider />
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Rapporterad av
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{faultReport?.createdBy}</Text>
						</Col>
					</Row>
					<Divider />
					{faultReport?.updatedAt ? (
						<>
							<Row>
								<Col {...labelLayout}>
									<Text strong type="secondary">
										Status uppdaterad
									</Text>
								</Col>
								<Col {...valueLayout}>
									<Text>{dayjs(faultReport?.updatedAt).format('YYYY-MM-DD HH:mm')}</Text>
								</Col>
							</Row>
							<Divider />
						</>
					) : (
						<></>
					)}
					{faultReport?.updatedAt ? (
						<>
							<Row>
								<Col {...labelLayout}>
									<Text strong type="secondary">
										Status uppdaterad av
									</Text>
								</Col>
								<Col {...valueLayout}>
									<Text>{faultReport?.updatedBy}</Text>
								</Col>
							</Row>
							<Divider />
						</>
					) : (
						<></>
					)}
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Information
							</Text>
						</Col>
						<Col {...valueLayout}>
							{faultReport ? <FaultReportDescription content={faultReport.information} /> : <></>}
						</Col>
					</Row>
					<Divider />
					{faultReport && faultReport.imageUrls.length > 0 ? (
						<>
							<Row>
								<Col {...labelLayout}>
									<Text strong type="secondary">
										Bilder
									</Text>
								</Col>
								<Col {...valueLayout}>
									<Row>
										{faultReport?.imageUrls.map((imageSrc) => (
											<div key={imageSrc} style={{ margin: 4 }}>
												<Image height={150} src={imageSrc} />
											</div>
										))}
									</Row>
								</Col>
							</Row>
							<Divider />
						</>
					) : (
						<></>
					)}
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Status
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Tag color={faultReport?.status === 1 ? 'green' : 'red'}>
								{faultReport?.status === 1 ? 'Arkiverad' : 'Ej arkiverad'}
							</Tag>
						</Col>
					</Row>
				</Card>
				{isAdmin || (isFranchise && faultReport?.status === 0) ? (
					<div className={styles.actions}>
						<Form onFinish={confirm}>
							<Form.Item>
								<Button
									icon={faultReport?.status === 1 ? <FileExcelOutlined /> : <FileAddOutlined />}
									type="primary"
									danger={faultReport?.status === 1}
									htmlType="submit"
									loading={isSubmitting}
									size="large"
								>
									{faultReport?.status === 0 ? 'Arkivera felanmälan' : 'Avarkivera felanmälan'}
								</Button>
							</Form.Item>
						</Form>
					</div>
				) : (
					<></>
				)}
			</Spin>
		</PageContainer>
	);
};

export default ViewFaultReportScreen;
