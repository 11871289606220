import { UploadFile } from 'antd';

export const getImage = (image: UploadFile[] | null) => {
	if (!image || !image[0]) return undefined;

	if (image[0].thumbUrl) {
		return image[0].thumbUrl;
	}

	if (!image[0].originFileObj) return undefined;

	return URL.createObjectURL(image[0].originFileObj);
};
