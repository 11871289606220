import { useContext } from 'react';
import BannedUserContext from '../state/BannedUsers';
import { BannedUser } from '@models/BannedUser';
import r from '../http';
import useNotification from './notification';
import { RequestError } from '../http/exceptions';

function getErrorMessage(exception: unknown) {
	let errorMessage = 'Något gick fel';

	if (exception instanceof RequestError) {
		const statusCode = exception.response?.status;

		if (statusCode === 400) {
			errorMessage = 'Personnumret eller telefonnumret är inte giltigt. Kontrollera uppgifterna och försök igen';
		}
		if (statusCode === 409) {
			errorMessage = 'Användaren är redan spärrat';
		}
	}

	return errorMessage;
}

const useBannedUsers = () => {
	const { successNotification, errorNotification } = useNotification();
	const {
		bannedUsers,
		addBannedUser: contextAdd,
		deleteBannedUser: contextDelete,
		updateBannedUser: contextUpdate,
	} = useContext(BannedUserContext);

	const addBannedUser = async (body: { userIdentifier: string; reason: string }): Promise<boolean> => {
		try {
			const { data } = await r.post<BannedUser>(`/admin/banned-users/`, body);
			contextAdd(data);
			successNotification('Användare spärrad!');
			return true;
		} catch (e) {
			errorNotification(getErrorMessage(e));
			return false;
		}
	};

	const editBannedUser = async (body: {
		id: number;
		userIdentifier: string;
		reason: string;
		userId: number;
	}): Promise<boolean> => {
		try {
			const { data } = await r.put<BannedUser>(`/admin/banned-users/${body.id}`, body);
			contextUpdate(data);
			successNotification('Spärrad användare uppdaterad!');
			return true;
		} catch (e) {
			errorNotification(getErrorMessage(e));
			return false;
		}
	};

	const removeBannedUser = async (body: { userIdentifier: string }) => {
		try {
			await r.delete<BannedUser>(`/admin/banned-users/`, body);
			contextDelete(body.userIdentifier);
			successNotification(`Spärrat konto borttaget`);
		} catch (e) {
			errorNotification('Något gick fel');
		}
	};

	return { bannedUsers, addBannedUser, removeBannedUser, editBannedUser };
};

export default useBannedUsers;
