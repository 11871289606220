import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import logo from '../assets/images/logo-black.png';

interface LoaderWithLogoProps {
	delay?: number; // Optional delay in milliseconds
}

const LoaderWithLogo: React.FC<LoaderWithLogoProps> = ({ delay = 0 }) => {
	const [showLoader, setShowLoader] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowLoader(true);
		}, delay);

		return () => clearTimeout(timer);
	}, [delay]);

	if (!showLoader) {
		return null;
	}

	return (
		<div
			style={{
				display: 'flex',
				flexGrow: 1,
				height: '100vh',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
			}}
		>
			<img style={{ height: 36, marginBottom: '12px' }} src={logo} alt="logo" />
			<Spin indicator={<LoadingOutlined spin />} size="large" />
		</div>
	);
};

export default LoaderWithLogo;
