import { Tag } from 'antd';
import { PurchaseStatus } from '../models/event';

const DispatchResultColumn = ({ success, status }: { success: boolean, status: PurchaseStatus }) => {
  if (status !== PurchaseStatus.Accepted) return null;

  const color = success ? 'green' : 'volcano';
  const message = success ? 'Lyckades' : 'Misslyckades';

  return <Tag color={color}>{message}</Tag>;
};

export default DispatchResultColumn;
