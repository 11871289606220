export enum OfferType {
	DiscountedPrice = 0, // A simple discounted price offer
	QuantityForPrice = 1, // e.g., Buy 3 for 30 kr
	QuantityForQuantity = 2, // e.g., Buy 3 for the price of 2
}

export const offerTypeTranslations: { [key in OfferType]: string } = {
	[OfferType.DiscountedPrice]: 'Rabatterat pris',
	[OfferType.QuantityForQuantity]: 'Antal för priset av antal', // e.g., 3 för priset av 2
	[OfferType.QuantityForPrice]: 'Antal för fast pris', // e.g., 3 för 30 kr
};

export default interface Offer {
	id: number;
	title: string;
	price: number;
	imageUrl: string;
	showOnStoreOpen: boolean;
	createdAt: string;
	updatedAt?: string;
	type: OfferType;
	quantity: number;
	quantityOffered: number;
	storeIds: number[];
	expirationDate: string | null;
}
