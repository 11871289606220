import React, { useMemo, useState } from 'react';
import { DocumentFile } from '../models/DocumentFile';

interface State {
	documents: DocumentFile[];
	setDocuments: (documents: DocumentFile[]) => void;
	addDocument: (documents: DocumentFile) => void;
	editDocument: (documents: DocumentFile) => void;
	deleteDocument: (id: number) => void;
}

const initialState: State = {
	documents: [],
	setDocuments: () => undefined,
	addDocument: () => undefined,
	editDocument: () => undefined,
	deleteDocument: () => undefined,
};

const DocumentContext = React.createContext<State>(initialState);

interface Props {
	children: React.ReactNode;
}

export const DocumentContextProvider = ({ children }: Props) => {
	const [documents, setDocuments] = useState<DocumentFile[]>(initialState.documents);

	const value: State = useMemo(() => {
		return {
			documents,
			setDocuments,
			addDocument: (document: DocumentFile) => setDocuments([...documents, document]),
			editDocument: (updatedDocument: DocumentFile) =>
				setDocuments(documents.map((doc) => (doc.id === updatedDocument.id ? updatedDocument : doc))),
			deleteDocument: (id: number) => setDocuments(documents.filter((d) => d.id !== id)),
		};
	}, [documents]);

	return <DocumentContext.Provider value={value}>{children}</DocumentContext.Provider>;
};

export default DocumentContext;
