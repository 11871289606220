import { color } from '../../../styles/color';

export const styleContent = `
@font-face {
  font-family: Glasgow-Regular;
  src: url(assets/fonts/glasgow/Glasgow-Regular.woff);
}
@font-face {
  font-family: Glasgow-DemiBold;
  src: url(assets/fonts/glasgow/Glasgow-DemiBold.woff);
}
@font-face {
  font-family: Glasgow-ExtraBold;
  src: url(assets/fonts/glasgow/Glasgow-ExtraBold.woff);
}

html,
body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
}

.insta-preview {
  position: relative;
  width: 800px;
  height: 1000px;
  background-color: #fff;
}

.insta-four-print {
  height: 740px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
}

.insta-three-print {
	height: 740px;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	gap: 10px;
  }

  .insta-three-print > div {
	width: 100%;
	height: 100%;
  }

  .insta-three-print > div:nth-child(3) {
	grid-row-start: 2; /* Start at the first row */
	grid-column-start: 1; /* Start at the first column */
	grid-column-end: span 2; /* Span both columns */
	justify-self: center; /* Center horizontally */
	align-self: center; /* Center vertically */
	width: 50%;
  }

  .insta-two-print {
	height: 740px;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(1.2, 1fr);
	grid-template-rows: repeat(1.2, 1fr);
	gap: 10px;
  }

  .insta-one-print {
	height: 740px;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-template-rows: repeat(1, 1fr);
  }

.insta-four-offer {
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
}

.insta-four-content {
	width:80%;
  }

.insta-two-content-one {
  width:80%;
  text-align: end;
}

.insta-two-content-two {
	width:80%;
	text-align: start;
}

.insta-four-product {
  font-family: 'Glasgow-DemiBold', sans-serif;
  font-size: 28px;
  color: #000;
  margin: 0;
}

.insta-four-extra {
  font-family: 'Glasgow-Regular', sans-serif;
  font-size: 16px;
  color: #000;
  margin: 0;
  line-height: 18px;
}

.insta-four-image-container {
  display: flex;
  width: 250px;
  height: 240px;
  align-items:center;
  justify-content:center;
}

.insta-two-image-container-one {
	display: flex;
	width: 420px;
	height: 260px;
	align-items: center;
	justify-content: center;
	margin-left: -250px;
}

.insta-two-image-container-two {
	display: flex;
	width: 420px;
	height: 260px;
	align-items: center;
	justify-content: center;
	margin-right: -250px;
}

.insta-one-image-container {
	display: flex;
	width: 500px;
	height: 620px;
	align-items: center;
	justify-content: center;
	margin-left: -250px;
}

.insta-four-image {
  max-width: 100%;
}

.insta-four-price-container {
  position: absolute;
  margin-left: 250px;
  margin-top: -220px;
  display: flex;
  width: 100%;
  transform: scale(0.25);
}

.insta-two-price-container-one {
	position: absolute;
	margin-left: 280px;
	display: flex;
	width: 100%;
	transform: scale(0.45);
	margin-top:-20px;
}

.insta-two-price-container-two {
	position: absolute;
	margin-right: 280px;
	display: flex;
	width: 100%;
	transform: scale(0.45);
	margin-top:-20px;
}

.insta-one-price-container {
	position: absolute;
	margin-right: -360px;
	display: flex;
	width: 100%;
	transform: scale(0.6);
	margin-bottom:-360px;
}

.insta-footer {
	width: 100%;
	height:260px;
	display: flex;
	flex-direction: column;
  }
  
  .insta-footer__line {
	display: flex;
	height: 80px;
	align-items:center;
	background: ${color.primary};
  }
  
  .insta-footer__body {
	background: ${color.secondary};
	flex: 1;
	display: flex;
	flex-direction: column;
  }
  
  .insta-footer__content {
	flex: 1;
  }
  
  .insta-pre-title {
	color: #FFF;
	margin: 0;
	margin-left: 60px;
	font-family: Glasgow-DemiBold;
  }
  
  .insta-title {
	color: #FFF;
	font-weight: 900;
	line-height: 100%;
	margin: 0;
	margin-left: 60px;
	margin-top: 10px;
	font-family: Glasgow-ExtraBold;
  }
  
  .insta-logo {
	height: 40px;
	position: absolute;
	right: 20px;
	bottom: 10px;
  }
`;
