import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { init } from '@sentry/react';
import { isProduction } from './config';
import './config/dayjs';

init({
  dsn: 'https://d9351bdad2bc4c0a83304f3ac581692c@o4505552851763200.ingest.sentry.io/4505552853729280',
  enabled: isProduction,
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
