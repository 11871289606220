import styles from './styles/CreateTagScreen.module.scss';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Divider, Form, Input, Space, notification } from 'antd';
import PageContainer from '@components/PageContainer';
import { PlusOutlined } from '@ant-design/icons';
import r from '../http';
import { formLayout } from '../config/constants';
import StoreSelector from '@components/StoreSelector';
import useStoreSelector from '@hooks/store-selector';

const CreateTagScreen = () => {
	const { stores, selectedStore, setSelectedStore } = useStoreSelector(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [form] = Form.useForm();

	const navigate = useNavigate();

	const submit = async (form: { cardName: string }) => {
		const store = stores.find((x) => x.id === selectedStore);

		if (!store) return;

		const body = {
			lockId: store.lockId.toString(),
			cardNumber: '00001',
			cardName: form.cardName,
			startDate: 0,
			endDate: 0,
			cardType: 1,
			addType: 2,
		};

		try {
			setIsSubmitting(true);

			await r.post(`/admin/stores/${selectedStore}/ic-cards`, body);

			notification.success({
				message: 'Tagg skapad!',
				placement: 'bottomRight',
				duration: 5,
			});

			navigate(`/tags?storeId=${selectedStore}`);
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
		setIsSubmitting(false);
	};

	return (
		<PageContainer back={'/tags'} title="Skapa tagg">
			<Form
				initialValues={{ cardName: '' }}
				{...formLayout}
				labelAlign="left"
				form={form}
				size="large"
				onFinish={submit}
			>
				<Card>
					<Form.Item label="Butik" name="store" rules={[{ required: true, message: 'Vänligen välj en butik' }]}>
						<StoreSelector stores={stores} selectedStore={selectedStore} onChange={setSelectedStore} />
					</Form.Item>
					<Divider />
					<Form.Item label="Namn" name="cardName" rules={[{ required: true, message: 'Vänligen ange ett namn' }]}>
						<Input placeholder="Namn" />
					</Form.Item>
				</Card>
				<div className={styles.actions}>
					<Form.Item>
						<Space size="middle">
							<Button onClick={() => navigate('/tags')} type="text">
								Avbryt
							</Button>
							<Button icon={<PlusOutlined />} type="primary" htmlType="submit" loading={isSubmitting}>
								Skapa tagg
							</Button>
						</Space>
					</Form.Item>
				</div>
			</Form>
		</PageContainer>
	);
};

export default CreateTagScreen;
