import styles from '../styles/StoreProducts.module.scss';
import { Divider, Input, Table, Typography, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/lib/table';
import { useContext, useMemo, useState } from 'react';
import Product from '../../../models/Product';
import ProductContext from '../../../state/Product';
import ProductActions from './ProductActions';
import AddProductsModal from './AddProductsModal';
import ListImage from '@components/table/ListImage';
import useStoreProducts from '@hooks/store-products';

const { Search } = Input;

const getColumns = (storeId: number, removeStoreProduct: (productId: number) => void): ColumnsType<Product> => [
	{
		title: 'Bild',
		key: 'imageUrl',
		width: 70,
		render: (product: Product) => <ListImage src={product.imageUrl} />,
	},
	{
		title: 'Namn',
		dataIndex: 'name',
		key: 'name',
		ellipsis: true,
		width: 150,
	},
	{
		title: 'Artikelnummer',
		dataIndex: 'articleNumber',
		key: 'articleNumber',
		width: 150,
	},
	{
		title: 'EAN',
		dataIndex: 'ean',
		key: 'ean',
		sortDirections: ['descend', 'ascend'],
		width: 150,
	},
	{
		title: 'Inköpspris',
		key: 'inPrice',
		sortDirections: ['descend', 'ascend'],
		width: 120,
		sorter: (a, b) => a.inPrice - b.inPrice,
		render: (product: Product) => <div className={styles.tablePrice}>SEK {product.inPrice.toFixed(2)}</div>,
	},
	{
		title: '',
		key: 'action',
		width: 50,
		fixed: 'right',
		render: (_, product: Product) => <ProductActions productId={product.id} removeStoreProduct={removeStoreProduct} />,
	},
];

interface Props {
	storeId: number;
}

const StoreProducts = ({ storeId }: Props) => {
	const [searchTerm, setSearchterm] = useState('');
	const [showAddProductsModal, setShowAddProductsModal] = useState(false);
	const { products: allProducts } = useContext(ProductContext);
	const { storeProducts, addStoreProducts, removeStoreProduct, isFetching } = useStoreProducts(storeId);

	const products = useMemo(
		() =>
			storeProducts
				? storeProducts
						.map((productId: number) => allProducts.find((product) => product.id === productId))
						.filter((product): product is Product => !!product)
				: [],
		[storeProducts, allProducts]
	);

	const filterProperties: ['name', 'ean', 'articleNumber'] = ['name', 'ean', 'articleNumber'];
	const displayedProducts = products.filter((product) =>
		filterProperties.some((filterProperty) => product[filterProperty].toLowerCase().includes(searchTerm.toLowerCase()))
	);

	return (
		<div className={styles.storeProducts}>
			<Typography.Title level={2}>Produkter</Typography.Title>
			<Divider />
			<div className={styles.actionBar}>
				<Search
					value={searchTerm}
					onChange={(e) => setSearchterm(e.target.value)}
					size="large"
					placeholder="Sök efter namn, artikelnummer eller EAN"
					style={{ width: 400 }}
				/>
				<Button size="large" onClick={() => setShowAddProductsModal(true)} type="primary" icon={<PlusOutlined />}>
					Lägg till produkter
				</Button>
			</div>
			<Divider />
			<Table
				locale={{
					emptyText: 'Inga produkter',
					triggerDesc: 'Tryck för att sortera i fallande ordning',
					triggerAsc: 'Tryck för att sortera i stigande ordning',
					cancelSort: 'Tryck för att nollställa sortering',
				}}
				loading={isFetching}
				columns={getColumns(storeId, removeStoreProduct)}
				dataSource={displayedProducts}
				size="middle"
				scroll={{ x: '100%' }}
				rowKey={(product) => product.id}
			/>
			<AddProductsModal
				existingProducts={storeProducts}
				storeId={storeId}
				open={showAddProductsModal}
				onClose={() => setShowAddProductsModal(false)}
				addStoreProducts={addStoreProducts}
			/>
		</div>
	);
};

export default StoreProducts;
