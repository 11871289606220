import { useNavigate } from 'react-router-dom';
import TableActions from '../../components/table/Actions';

interface Props {
	id: number;
}

const TheftActions = ({ id }: Props) => {
	const navigate = useNavigate();

	return <TableActions onView={() => navigate(`/thefts/${id.toString()}`)} />;
};

export default TheftActions;
