import PriceLabel, { PriceType } from '../../PriceLabel';
import { OfferInstagram } from '../../types';
import { getImage } from '../../../functions/getImage';

interface Props {
	offers: OfferInstagram[];
}

const _4inInstagram = ({ offers }: Props) => {
	return (
		<div className="insta-four-print">
			{offers.map((offer) => (
				<div key={offer.id} className="insta-four-offer">
					<div className="insta-four-image-container">
						<img
							className="insta-four-image"
							src={getImage(offer.image)}
							style={{ maxHeight: 240 * offer.imageScale }}
						/>
					</div>
					<div className="insta-four-content">
						<h4 className="insta-four-product">{offer.product}</h4>
						<pre className="insta-four-extra">{offer.extra}</pre>
						<div style={{ width: '100%', height: 2, background: 'black', marginTop: 6 }} />
					</div>
					<div className="insta-four-price-container">
						{offer.type === PriceType.Price && (
							<PriceLabel
								type="price"
								price={offer.price}
								showPriceDecimals={offer.priceShowDecimals}
								unit={offer.unit}
							/>
						)}
						{offer.type === PriceType.PieceForPiece && (
							<PriceLabel
								type="price-for-piece"
								first={offer.first}
								second={offer.second}
								singlePrice={offer.singlePrice}
							/>
						)}
						{offer.type === PriceType.AmountForPrice && (
							<PriceLabel
								type="amount-for-price"
								quantity={offer.quantity}
								price={offer.price}
								showPriceDecimals={offer.priceShowDecimals}
								save={offer.save}
								showSaveDecimals={offer.saveShowDecimals}
							/>
						)}
					</div>
				</div>
			))}
		</div>
	);
};

export default _4inInstagram;
