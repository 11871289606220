import React, { useEffect, useMemo, useState } from 'react';
import { requestPermission, sendNotification } from '../helpers/notification';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import Purchase from '../models/Purchase';
import r from '../http';
import { playNotificationSound } from '../functions/playNotificationSound';
import { isDevelopment } from '../config';
import usePermissions from '@hooks/permissions';

interface State {
	purchases: Purchase[];
}

const initialState: State = {
	purchases: [],
};

const PurchaseContext = React.createContext<State>(initialState);

interface Props {
	children: React.ReactNode;
}

export const PurchaseContextProvider = ({ children }: Props) => {
	const [purchases, setPurchases] = useState<Purchase[]>(initialState.purchases);
	const { isAdminOrMaintainer } = usePermissions();
	const navigate = useNavigate();

	const notifyUser = async () => {
		playNotificationSound();

		const browserNotification = sendNotification('Ny köpansökan!');
		if (browserNotification) {
			browserNotification.onclick = () => {
				browserNotification.close();
				navigate('applications');
			};

			return;
		}

		const id = Date.now().toString();
		notification.info({
			key: id,
			message: `Ny köpansökan!`,
			description: '',
			placement: 'topRight',
			onClick: () => {
				navigate('applications');
				notification.destroy(id);
			},
		});
	};

	useEffect(() => {
		if (!isAdminOrMaintainer || isDevelopment) return;

		const destroyed = setInterval(async () => {
			try {
				const response = await r.get<Purchase[]>('/admin/vending-machine/purchases');
				const { data } = response;
				if (data.length > purchases.length) {
					notifyUser();
				}
				setPurchases(data);
			} catch (e) {
				console.log('Error: ', e);
				// TODO: handle
			}
		}, 1000);

		return () => {
			clearInterval(destroyed);
		};
	}, [isAdminOrMaintainer, purchases]);

	useEffect(() => {
		requestPermission();
	}, []);

	const value: State = useMemo(() => {
		return {
			purchases,
		};
	}, [purchases]);

	return <PurchaseContext.Provider value={value}>{children}</PurchaseContext.Provider>;
};

export default PurchaseContext;
