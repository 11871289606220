import { useNavigate } from 'react-router-dom';
import TableActions from '../../components/table/Actions';
import { Modal, notification } from 'antd';
import StoreContext from '../../state/Store';
import { useContext } from 'react';
import Store from '@models/Store';

interface Props {
	store: Store;
	showDelete: boolean;
}

const StoreActions = ({ store, showDelete }: Props) => {
	const navigate = useNavigate();
	const { deleteStore } = useContext(StoreContext);

	const removeConfirmation = () => {
		Modal.warning({
			title: `Är du säker att du vill ta bort butiken?`,
			okText: 'Ja, ta bort',
			okCancel: true,
			cancelText: 'Avbryt',
			onOk: remove,
		});
	};

	const remove = async () => {
		try {
			await deleteStore(store);
			notification.success({
				message: `Butik borttagen`,
				placement: 'bottomRight',
				duration: 5,
			});
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
	};

	return (
		<TableActions
			onView={() => navigate(store.id.toString())}
			onEdit={() => navigate(`${store.id}/edit`)}
			onDelete={showDelete ? removeConfirmation : undefined}
		/>
	);
};

export default StoreActions;
