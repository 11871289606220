interface Config {
	apiUrl: string;
	firebaseApiUrl: string;
}

const development: Config = {
	apiUrl: 'https://24food-api-dev.azurewebsites.net/api',
	firebaseApiUrl: 'http://localhost:5001/food-dev-972af/us-central1',
};

const production: Config = {
	apiUrl: 'https://24food-api.azurewebsites.net/api',
	firebaseApiUrl: 'https://us-central1-food-dev-972af.cloudfunctions.net',
};

export const isProduction = process.env.NODE_ENV === 'production';

export const isDevelopment = !isProduction;

const config = isProduction ? production : development;

export default config;
