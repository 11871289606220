import { notification } from 'antd';

const useNotification = () => {
	const successNotification = (message: string) => {
		notification.success({
			message: message,
			placement: 'bottomRight',
			duration: 5,
		});
	};

	const errorNotification = (message: string) => {
		notification.error({
			message: message,
			placement: 'bottomRight',
			duration: 5,
		});
	};

	const warningNotification = (message: string) => {
		notification.warning({
			message: message,
			placement: 'bottomRight',
			duration: 5,
		});
	};

	return { successNotification, errorNotification, warningNotification };
};

export default useNotification;
