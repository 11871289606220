import { Button, Card, Col, Divider, Form, Input, InputNumber, Row, Space, Switch } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import FormImage from '../../../components/form/Image';
import styles from '../../styles/4inA4PrintScreen.module.scss';
import React, { useId, useState } from 'react';
import Title from 'antd/es/typography/Title';
import Preview from './PriceLabelPrintPreview';
import { OfferPriceLabel } from '../types';
import { getInitialLabelPrintForm } from '../../functions/getInitialForm';
import { printPdf } from '../../functions/print';
import ZoomController from '../ZoomController';

const storageKey = '@offer-print-price-label:form:v1';

const PriceLabelPrint = () => {
	const [form] = Form.useForm();
	const offers = Form.useWatch('offers', form);
	const show24FoodLogo = Form.useWatch('show24FoodLogo', form);
	const [selectedOffer, setSelectedOffer] = useState<number>(0);
	const iFrameId = useId();
	const [zoom, setZoom] = useState<number>(1.0);

	const onFormChange = (
		_: unknown,
		fieldData: {
			offers: OfferPriceLabel[];
			show24FoodLogo: boolean;
		}
	) => {
		const a = {
			offers: fieldData.offers.map((offer) => ({
				...offer,
				image: null,
			})),
			show24FoodLogo: fieldData.show24FoodLogo,
		};

		localStorage.setItem(storageKey, JSON.stringify(a));
	};

	return (
		<Row gutter={20}>
			<Col span={24}>
				<Space>
					<Button
						size="large"
						type="primary"
						onClick={async () => {
							await setZoom(1);
							printPdf(iFrameId);
						}}
						icon={<PrinterOutlined />}
					>
						Skriv ut
					</Button>
					{/* We need to use an svg for the green background. The background is not good quality when exported as image */}
					{/* <Button
						size="large"
						type="primary"
						onClick={async () => {
							await setZoom(1);
							printJpg('price-label-offer-preview');
						}}
						icon={<DownloadOutlined />}
					>
						Ladda ner bild
					</Button> */}
				</Space>
				<Divider />
			</Col>
			<Col span={8}>
				<iframe style={{ visibility: 'hidden', position: 'absolute' }} id={iFrameId} className="iframe" src=""></iframe>
				<Form
					form={form}
					initialValues={getInitialLabelPrintForm(storageKey)}
					onValuesChange={onFormChange}
					layout="vertical"
					size="middle"
				>
					<Card>
						<Title style={{ margin: 0 }} level={3}>
							Sektion
						</Title>
						<Space>
							{[0, 1, 2, 3].map((n) => (
								<Button
									key={n}
									type={n === selectedOffer ? 'primary' : 'default'}
									onClick={() => setSelectedOffer(n)}
									style={{ marginBottom: 10 }}
								>
									{n + 1}
								</Button>
							))}
						</Space>
						<Form.List name="offers">
							{(fields) =>
								fields.map((field, i) =>
									i !== selectedOffer ? (
										<React.Fragment key={field.key}></React.Fragment>
									) : (
										<React.Fragment key={field.key}>
											<Form.Item label="Rubrik" name={[field.name, 'title']}>
												<Input />
											</Form.Item>
											<Form.Item label="Pris" name={[field.name, 'price']}>
												<Input />
											</Form.Item>
											<Form.Item label="Extra" help="T.ex. antal gram, jämförpris" name={[field.name, 'extra']}>
												<TextArea rows={2} />
											</Form.Item>
											<FormImage label="Bild" name={[field.name, 'image']} />
											<Form.Item label="Bildskala" name={[field.name, 'imageScale']}>
												<InputNumber min={0.5} step={0.05} max={2} />
											</Form.Item>
											<div className={styles.priceDivider}>
												<Divider orientation="left" orientationMargin="0">
													Prisetikett
												</Divider>
											</div>
										</React.Fragment>
									)
								)
							}
						</Form.List>
						<div style={{ paddingTop: 1 }}>
							<Title level={3}>Annat</Title>
							<Form.Item label="Visa 24Food logo" name="show24FoodLogo" valuePropName="checked">
								<Switch />
							</Form.Item>
						</div>
					</Card>
				</Form>
			</Col>
			<Col flex="auto" className={styles.previewColumn}>
				<ZoomController zoom={zoom} setZoom={setZoom} />
				<div
					className={styles.previewContainer}
					id="price-label-offer-preview"
					style={{
						transform: `scale(${zoom})`,
						position: 'absolute',
						top: 80,
						left: 0,
						transformOrigin: 'center top',
					}}
				>
					{offers && <Preview iFrameId={iFrameId} show24FoodLogo={show24FoodLogo} offers={offers} />}
				</div>
			</Col>
		</Row>
	);
};

export default PriceLabelPrint;
