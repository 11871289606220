import React, { useContext, useState } from 'react';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Divider, Drawer } from 'antd';
import { Layout as AntLayout, Menu } from 'antd';
import styles from '../styles/Layout.module.scss';
import { MenuOutlined } from '@ant-design/icons';
import logo from '../../assets/images/logo-black.png';
import AuthContext from '../../state/Auth';
import { getSideMenuRoutes } from '../routes';

const getInitialCollapsedValue = (): boolean => {
	const value = localStorage.getItem('@layout:menu-collapsed');
	if (!value) return false;

	return JSON.parse(value);
};

const NavigationBar = ({ children }: { children: React.ReactNode }) => {
	const navigate = useNavigate();
	const auth = useContext(AuthContext);
	const match = useMatch('/:route/*');

	const [drawerOpen, setDrawerOpen] = useState(false);
	const [collapsed, setCollapsed] = useState(getInitialCollapsedValue());

	const updateCollpased = (collapsed: boolean) => {
		setCollapsed(collapsed);
		localStorage.setItem('@layout:menu-collapsed', JSON.stringify(collapsed));
	};

	const menuOnClick = (e: any) => {
		if (e.key === 'logout') {
			auth.signOut();
			return;
		}

		setDrawerOpen(false);
		navigate(e.key);
	};

	const sideMenu = auth.user?.roles && getSideMenuRoutes(auth.user.roles);

	const selectedKeys = match?.params.route ? [match.params.route, match.pathname.replace('/', '')] : undefined;

	return (
		<AntLayout hasSider className={styles.layout}>
			<AntLayout.Sider
				collapsible
				collapsed={collapsed}
				onCollapse={updateCollpased}
				trigger={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
				width={250}
				className={styles.sider}
			>
				<Link to="/" className={styles.logo}>
					<img className={styles.img} src={logo} alt="logo" />
				</Link>
				<Divider style={{ margin: 0 }} />
				<Menu
					mode="inline"
					selectedKeys={selectedKeys}
					items={sideMenu}
					onClick={menuOnClick}
					style={{ paddingBottom: 40 }}
				/>
			</AntLayout.Sider>
			<AntLayout>
				<Drawer
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					//@ts-ignore
					styles={{ body: { padding: 0 } }}
					title="Meny"
					width={300}
					placement="left"
					onClose={() => setDrawerOpen(false)}
					open={drawerOpen}
				>
					<Menu mode="inline" selectedKeys={selectedKeys} items={sideMenu} onClick={menuOnClick} />
				</Drawer>
				<AntLayout.Header className={styles.responsiveHeader}>
					<Button type="text" shape="circle" icon={<MenuOutlined />} onClick={() => setDrawerOpen(true)}></Button>
					<div className={styles.responsiveLogo}>
						<Link to="/">
							<img className={styles.img} src={logo} alt="logo" />
						</Link>
					</div>
				</AntLayout.Header>
				<AntLayout.Content className={styles.content}>{children}</AntLayout.Content>
			</AntLayout>
		</AntLayout>
	);
};

export default NavigationBar;
