import {
	Button,
	Card,
	Checkbox,
	Col,
	Divider,
	Form,
	Input,
	InputNumber,
	Row,
	Select,
	Slider,
	Space,
	Switch,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import { PriceType } from '../PriceLabel';
import React, { useId, useState } from 'react';
import FormImage from '../../../components/form/Image';
import Title from 'antd/es/typography/Title';
import styles from '../../styles/InstagramOfferPrint.module.scss';
import { OfferInstagram, typeSelectOptions } from '../types';
import Preview from './InstagramOfferPrintPreview';
import { getInitial4ItemForm } from '../../functions/getInitialForm';
import { printJpg, printPdf } from '../../functions/print';
import ZoomController from '../ZoomController';

const storageKey = '@offer-print-instagram:form:v2';

const InstagramOfferPrint = () => {
	const [form] = Form.useForm();
	const preTitle = Form.useWatch('preTitle', form);
	const title = Form.useWatch('title', form);
	const offers = Form.useWatch('offers', form);
	const offerQuantity = Form.useWatch('offerQuantity', form);
	const show24FoodLogo = Form.useWatch('show24FoodLogo', form);
	const [selectedOffer, setSelectedOffer] = useState<number>(0);
	const iFrameId = useId();
	const [zoom, setZoom] = useState<number>(1.0);

	const onFormChange = (
		_: unknown,
		fieldData: {
			preTitle: string;
			title: string;
			offerQuantity: number;
			show24FoodLogo: boolean;
			offers: OfferInstagram[];
		}
	) => {
		const a = {
			preTitle: fieldData.preTitle,
			title: fieldData.title,
			offerQuantity: fieldData.offerQuantity,
			show24FoodLogo: fieldData.show24FoodLogo,
			offers: fieldData.offers.map((offer) => ({
				...offer,
				image: null,
			})),
		};

		localStorage.setItem(storageKey, JSON.stringify(a));
	};

	return (
		<Row gutter={20}>
			<Col span={24} style={{ marginBottom: 10 }}>
				<Space>
					<Button
						size="large"
						type="primary"
						onClick={async () => {
							await setZoom(1);
							printPdf(iFrameId);
						}}
						icon={<PrinterOutlined />}
					>
						Skriv ut
					</Button>
					<Button
						size="large"
						type="primary"
						onClick={async () => {
							await setZoom(1);
							printJpg('insta-preview');
						}}
						icon={<DownloadOutlined />}
					>
						Ladda ner bild
					</Button>
				</Space>
			</Col>
			<Col span={8}>
				<iframe style={{ visibility: 'hidden', position: 'absolute' }} id={iFrameId} className="iframe" src=""></iframe>
				<Form
					form={form}
					initialValues={getInitial4ItemForm(storageKey)}
					onValuesChange={onFormChange}
					layout="vertical"
					size="middle"
				>
					<Card>
						<Form.Item label="Före rubrik" name="preTitle">
							<Input />
						</Form.Item>
						<Form.Item label="Rubrik" name="title">
							<Input />
						</Form.Item>
						<Form.Item label="Visa 24Food logo" name="show24FoodLogo" valuePropName="checked">
							<Switch />
						</Form.Item>
						<Form.Item label="Antal erbjudanden" name="offerQuantity">
							<Slider
								marks={{ 1: { label: 1 }, 2: { label: 2 }, 3: { label: 3 }, 4: { label: 4 } }}
								step={1}
								min={1}
								max={4}
								onChange={() => setSelectedOffer(0)}
							/>
						</Form.Item>
						<Divider />
						<Title style={{ margin: 0 }} level={3}>
							Sektion
						</Title>
						<Space>
							{Array.from(Array(offerQuantity ?? 4).keys()).map((n) => (
								<Button
									key={n}
									type={n === selectedOffer ? 'primary' : 'default'}
									onClick={() => setSelectedOffer(n)}
									style={{ marginBottom: 10 }}
								>
									{n + 1}
								</Button>
							))}
						</Space>
						<Form.List name="offers">
							{(fields) =>
								fields.map((field, i) =>
									i !== selectedOffer ? (
										<React.Fragment key={field.key}></React.Fragment>
									) : (
										<React.Fragment key={field.key}>
											<Form.Item label="Namn" name={[field.name, 'product']}>
												<Input />
											</Form.Item>
											<Form.Item label="Extra" help="T.ex. antal gram, jämförpris" name={[field.name, 'extra']}>
												<TextArea rows={2} />
											</Form.Item>
											<FormImage label="Bild" name={[field.name, 'image']} />
											<Form.Item label="Bildskala" name={[field.name, 'imageScale']}>
												<InputNumber min={0.5} step={0.05} max={2} />
											</Form.Item>
											<div className={styles.priceDivider}>
												<Divider orientation="left" orientationMargin="0">
													Prisetikett
												</Divider>
											</div>
											<Form.Item label="Pristyp" name={[field.name, 'type']} required>
												<Select options={typeSelectOptions} />
											</Form.Item>
											{form.getFieldValue(['offers', i, 'type']) === PriceType.Price && (
												<>
													<Form.Item label="Pris" name={[field.name, 'price']}>
														<InputNumber addonAfter="Kr" precision={2} />
													</Form.Item>
													<Form.Item
														valuePropName="checked"
														name={[field.name, 'priceShowDecimals']}
														help="Gäller enbart vid `.00`"
													>
														<Checkbox>Visa decimaler på pris</Checkbox>
													</Form.Item>
													<Form.Item label="Enhet" name={[field.name, 'unit']} help="T.ex. KG, förp, st">
														<Input />
													</Form.Item>
												</>
											)}
											{form.getFieldValue(['offers', i, 'type']) === PriceType.PieceForPiece && (
												<>
													<Form.Item label="Första" name={[field.name, 'first']} required>
														<InputNumber precision={0} />
													</Form.Item>
													<Form.Item label="Andra" name={[field.name, 'second']} required>
														<InputNumber precision={0} />
													</Form.Item>
													<Form.Item label="Styckpris" name={[field.name, 'singlePrice']} help="T.ex. KG, förp, st">
														<Input />
													</Form.Item>
												</>
											)}
											{form.getFieldValue(['offers', i, 'type']) === PriceType.AmountForPrice && (
												<>
													<Form.Item label="Antal" name={[field.name, 'quantity']} required>
														<InputNumber precision={0} />
													</Form.Item>
													<Form.Item label="Pris" name={[field.name, 'price']} required>
														<InputNumber addonAfter="Kr" precision={2} />
													</Form.Item>
													<Form.Item
														valuePropName="checked"
														name={[field.name, 'priceShowDecimals']}
														help="Gäller enbart vid `.00`"
													>
														<Checkbox>Visa decimaler på pris</Checkbox>
													</Form.Item>
													<Form.Item label="Spara" name={[field.name, 'save']}>
														<InputNumber addonAfter="Kr" precision={2} />
													</Form.Item>
													<Form.Item
														valuePropName="checked"
														name={[field.name, 'saveShowDecimals']}
														help="Gäller enbart vid `.00`"
													>
														<Checkbox>Visa decimaler på spara</Checkbox>
													</Form.Item>
												</>
											)}
										</React.Fragment>
									)
								)
							}
						</Form.List>
					</Card>
				</Form>
			</Col>
			<Col flex="auto" className={styles.previewColumn}>
				<ZoomController zoom={zoom} setZoom={setZoom} />
				<div
					className={styles.previewContainer}
					id="insta-preview"
					style={{
						transform: `scale(${zoom})`,
						position: 'absolute',
						top: 80,
						left: 0,
						transformOrigin: 'center top',
					}}
				>
					{offers && (
						<Preview
							iFrameId={iFrameId}
							offers={offers.slice(0, offerQuantity ?? 4)}
							preTitle={preTitle}
							title={title}
							show24FoodLogo={show24FoodLogo}
						/>
					)}
				</div>
			</Col>
		</Row>
	);
};

export default InstagramOfferPrint;
