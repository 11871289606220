import React, { useRef, useEffect, ReactElement } from 'react';

interface ResizableTextProps {
	textComponent: ReactElement;
	containerSize: string;
	initialFontSize?: string;
}

const ResizableText = ({ textComponent, containerSize, initialFontSize = '16px' }: ResizableTextProps) => {
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const container = containerRef.current;

		if (!container) {
			return;
		}

		container.style.fontSize = initialFontSize;

		if (container.firstElementChild instanceof HTMLElement) {
			container.firstElementChild.style.fontSize = initialFontSize;
		}

		const resizeObserver = new ResizeObserver(() => {
			const { offsetWidth, scrollWidth } = container;

			if (scrollWidth > offsetWidth) {
				const newFontSize = (offsetWidth / scrollWidth) * parseFloat(getComputedStyle(container).fontSize);
				container.style.fontSize = `${newFontSize}px`;

				// Modify the style of the child element directly
				if (container.firstElementChild instanceof HTMLElement) {
					container.firstElementChild.style.fontSize = `${newFontSize}px`;
				}
			}
		});

		resizeObserver.observe(container);

		return () => {
			resizeObserver.disconnect();
		};
	}, [textComponent, initialFontSize]);

	return (
		<div ref={containerRef} style={{ width: containerSize, whiteSpace: 'nowrap', overflow: 'hidden' }}>
			{React.cloneElement(textComponent, { style: { fontSize: initialFontSize } })}
		</div>
	);
};

export default ResizableText;
