import React, { useEffect, useMemo, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase';
import Unit from '@models/Unit';

export const getUnits = async () => {
	const units: Unit[] = [];
	const querySnapshot = await getDocs(collection(firestore, 'units'));

	querySnapshot.forEach((doc) => {
		const unit = doc.data() as Unit;
		units.push(unit);
	});

	return units;
};

interface State {
	units: Unit[];
}

const initialState: State = {
	units: [],
};

const UnitContext = React.createContext<State>(initialState);

interface Props {
	children: React.ReactNode;
}

export const UnitContextProvider = ({ children }: Props) => {
	const [units, setUnits] = useState<Unit[]>([]);

	useEffect(() => {
		getUnits().then(setUnits);
	}, []);

	const value: State = useMemo(() => {
		return {
			units,
		};
	}, [units]);

	return <UnitContext.Provider value={value}>{children}</UnitContext.Provider>;
};

export default UnitContext;
