import dayjs from 'dayjs';

class DateTime {
  date: Date;

  constructor(date: Date) {
    this.date = date;
  }

  static fromISOUTC(isoUtcString: string) {
    const date = dayjs.utc(isoUtcString).tz('Europe/Stockholm').toDate();
    return new DateTime(date);
  }

  toFormat(format: string) {
    return dayjs(this.date).format(format);
  }

  toRelative() {
    return dayjs(this.date).fromNow();
  }

  toDate() {
    return this.date;
  }
}

export default DateTime;
