import { Modal, notification } from 'antd';
import r from '../../../http';
import { VendingMachineProduct } from '@models/VendingMachineProducts';
import TableActions from '../../../components/table/Actions';

interface Props {
	product: VendingMachineProduct;
	removeProduct: (productId: number) => void;
	onEdit: (vendingMachineProduct: VendingMachineProduct) => void;
}

const VendingMachineProductActions = ({ product, removeProduct, onEdit }: Props) => {
	const deleteProduct = async () => {
		try {
			await r.delete(`/admin/vending-machine-products/${product.id}`);
			notification.success({
				message: 'Produkt borttagen!',
				placement: 'bottomRight',
				duration: 5,
			});
			removeProduct(product.id);
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
	};

	const confirmDelete = () => {
		Modal.confirm({
			title: 'Ta bort produkt från varuautomat',
			content: 'Är du säker på att du vill ta bort produkten från varuautomaten?',
			okText: 'Ja, ta bort',
			cancelText: 'Avbryt',
			onOk: deleteProduct,
		});
	};
	return <TableActions onEdit={() => onEdit(product)} onDelete={confirmDelete} />;
};

export default VendingMachineProductActions;
