import { useContext, useState } from 'react';
import { Alert, Button, Divider, Input, Modal, Switch, Table, notification } from 'antd';
import PageContainer from '../components/PageContainer';
import { PlusOutlined } from '@ant-design/icons';
import styles from './styles/AnnouncementScreen.module.scss';
import { useNavigate } from 'react-router-dom';
import useFetch from '../hooks/fetch';
import Announcement from '../models/Announcement';
import AnnouncementContext from '../state/Announcement';
import { ColumnsType } from 'antd/lib/table';
import AnnouncementActions from './components/AnnouncementActions';
import r from '../http';

const { Search } = Input;

const ActivateMessage = ({ announcement }: { announcement: Announcement }) => {
	const { updateAnnouncement } = useContext(AnnouncementContext);
	const [isSaving, setIsSaving] = useState(false);

	const onChange = async (checked: boolean) => {
		setIsSaving(true);

		try {
			const response = await r.put<Announcement>(`/admin/announcements/${announcement.id}`, {
				...announcement,
				isActive: checked,
			});
			updateAnnouncement(response.data);
		} catch (e) {}

		setIsSaving(false);
	};

	const confirm = (checked: boolean) => {
		const confirmationText = `Är du säker på att du vill aktivera detta meddelande? Meddelandet kommer att visas för alla i appen.`;
		if (checked) {
			Modal.confirm({
				title: 'Aktivera meddelande',
				content: confirmationText,
				okText: 'Ja, aktivera',
				cancelText: 'Avbryt',
				onOk: () => onChange(checked),
			});
		} else {
			onChange(checked);
		}
	};

	return <Switch onChange={confirm} checked={announcement.isActive} loading={isSaving} />;
};

const AnnouncementScreen = () => {
	const { announcements, setAnnouncements } = useContext(AnnouncementContext);
	const [searchTerm, setSearchterm] = useState('');
	const navigate = useNavigate();

	const { isFetching } = useFetch<Announcement[]>('/admin/announcements', setAnnouncements, () => {
		notification.error({
			message: 'Vi kunde inte hämta meddelanden. Vänligen försök igen.',
			placement: 'bottomRight',
			duration: 10,
		});
	});

	const displayedAnnouncements = announcements.filter((announcement) =>
		announcement.title.toLowerCase().includes(searchTerm.toLowerCase())
	);

	const columns: ColumnsType<Announcement> = [
		{
			title: 'Titel',
			dataIndex: 'title',
			key: 'title',
			width: 200,
			sorter: (a, b) => a.title.localeCompare(b.title),
		},
		{
			title: 'Innehåll',
			dataIndex: 'message',
			key: 'message',
			width: 300,
			sorter: (a, b) => a.message.localeCompare(b.message),
		},
		{
			title: 'Aktiverat',
			dataIndex: 'isActive',
			key: 'isActive',
			width: 220,
			sorter: (a, b) => (a.isActive === b.isActive ? 0 : a.isActive ? -1 : 1),
			render: (_showOnStoreOpen, announcement) => <ActivateMessage announcement={announcement} />,
		},
		{
			width: 150,
			responsive: ['sm'],
			fixed: 'right',
			render: (_, announcement: Announcement) => <AnnouncementActions id={announcement.id} />,
		},
		{
			responsive: ['xs'],
			width: 50,
			fixed: 'right',
			render: (_, announcement: Announcement) => <AnnouncementActions id={announcement.id} />,
		},
	];

	return (
		<PageContainer
			title="Viktiga meddelanden"
			extra={
				<Button onClick={() => navigate('create')} type="primary" icon={<PlusOutlined />}>
					Skapa viktigt meddelande
				</Button>
			}
		>
			<div className={styles.actionBar}>
				<Search
					value={searchTerm}
					onChange={(e) => setSearchterm(e.target.value)}
					size="large"
					placeholder="Sök efter titel"
					style={{ width: 400 }}
				/>
			</div>
			<Divider />
			{announcements.filter((a) => a.isActive).length > 1 ? (
				<Alert
					className={styles.activateMultipleWarning}
					message={
						<span>
							Det finns <b>{announcements.filter((a) => a.isActive).length} st</b> aktiva meddelanden.{' '}
							<b>Det kan max vara ett meddelande aktiverat åt gången.</b>
							<br /> Åtgärda genom att bara välja ett meddelande som ska visas.
						</span>
					}
					type="warning"
				/>
			) : (
				<></>
			)}

			<Table
				loading={isFetching}
				rowKey={(offer) => offer.id}
				locale={{
					emptyText: 'Inga viktiga meddelanden',
					triggerDesc: 'Tryck för att sortera i fallande ordning',
					triggerAsc: 'Tryck för att sortera i stigande ordning',
					cancelSort: 'Tryck för att nollställa sortering',
				}}
				pagination={{ position: ['bottomRight'], defaultPageSize: 50 }}
				scroll={{ x: '100%' }}
				size="middle"
				columns={columns}
				dataSource={displayedAnnouncements}
			/>
		</PageContainer>
	);
};

export default AnnouncementScreen;
