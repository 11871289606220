import React, { useMemo, useState } from 'react';
import FaultReport from '@models/FaultReport';

interface State {
	faultReports: FaultReport[];
	setFaultReports: (faultReports: FaultReport[]) => void;
	addFaultReport: (faulReport: FaultReport) => void;
	archiveFaultReport: (faulReport: FaultReport) => void;
}

const initialState: State = {
	faultReports: [],
	setFaultReports: () => undefined,
	addFaultReport: () => undefined,
	archiveFaultReport: () => undefined,
};

const FaultReportContext = React.createContext<State>(initialState);

interface Props {
	children: React.ReactNode;
}

export const FaultReportContextProvider = ({ children }: Props) => {
	const [faultReports, setFaultReports] = useState<FaultReport[]>(initialState.faultReports);

	const value: State = useMemo(() => {
		return {
			faultReports,
			setFaultReports,
			addFaultReport: (fault: FaultReport) => setFaultReports(faultReports.concat(fault)),
			archiveFaultReport: (fault: FaultReport) =>
				setFaultReports(faultReports.map((f) => (f.id === fault.id ? fault : f))),
		};
	}, [faultReports]);

	return <FaultReportContext.Provider value={value}>{children}</FaultReportContext.Provider>;
};

export default FaultReportContext;
