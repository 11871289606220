import { useContext, useEffect, useState } from 'react';
import StoreContext from '../state/Store';
import AuthContext from '../state/Auth';
import styles from './styles/AccountScreen.module.scss';
import { Spin } from 'antd';

const AccountScreen = () => {
	const { selectedStore: store } = useContext(StoreContext);
	const { user } = useContext(AuthContext);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (store) {
			setLoading(false);
			return;
		}
		setLoading(true);
	}, [store]);
	return (
		<div>
			<div className={styles.header}>
				<h1>Mitt konto</h1>
			</div>

			<div className={styles.container}>
				<div className={styles.information}>
					{loading ? (
						<div className={styles.spinContainer}>
							<Spin size="large" spinning={loading}></Spin>
						</div>
					) : (
						<>
							<span className={styles.field}>
								<div className={styles.title}>Namn</div>
								<div className={styles.info}>
									{user?.firstName} {user?.lastName}
								</div>
							</span>
							<span className={styles.field}>
								<div className={styles.title}>Epost</div>
								<div className={styles.info}>{user?.email}</div>
							</span>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default AccountScreen;
