import { useContext, useState } from 'react';
import { Button, Card, Form, Input, Modal, Space, Switch, notification } from 'antd';
import PageContainer from '../components/PageContainer';
import { PlusOutlined } from '@ant-design/icons';
import styles from './styles/AnnouncementScreen.module.scss';
import { useNavigate } from 'react-router-dom';
import r from '../http';
import Announcement from '../models/Announcement';
import AnnouncementContext from '../state/Announcement';
import { formLayout } from '../config/constants';

const CreateAnnouncementScreen = () => {
	const { addAnnouncement } = useContext(AnnouncementContext);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const submitAnnouncement = async (formValues: any) => {
		setIsSubmitting(true);

		const body = { title: formValues.title, message: formValues.message, isActive: formValues.isActive };

		try {
			setIsSubmitting(true);
			const response = await r.post<Announcement>('/admin/announcements', body);
			addAnnouncement(response.data);

			notification.success({
				message: 'Meddelande skapat!',
				placement: 'bottomRight',
				duration: 5,
			});

			navigate(-1);
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}

		setIsSubmitting(false);
	};

	const confirm = (formValues: any) => {
		const confirmationText = `Är du säker på att du vill aktivera detta meddelande? Meddelandet kommer att visas för alla i appen.`;
		if (formValues.isActive) {
			Modal.confirm({
				title: 'Aktivera meddelande',
				content: confirmationText,
				okText: 'Ja, aktivera',
				cancelText: 'Avbryt',
				onOk: () => submitAnnouncement(formValues),
			});
		} else {
			submitAnnouncement(formValues);
		}
	};

	return (
		<PageContainer title="Skapa viktigt meddelande" back>
			<Form {...formLayout} labelAlign="left" form={form} size="large" onFinish={confirm}>
				<Card>
					<Form.Item label="Titel" name="title" rules={[{ required: true, message: 'Vänligen ange titel' }]}>
						<Input placeholder="Titel" />
					</Form.Item>
					<Form.Item label="Innehåll" name="message" rules={[{ required: true, message: 'Vänligen ange innehåll' }]}>
						<Input placeholder="Innehåll" />
					</Form.Item>
					<Form.Item label="Aktivera" name="isActive" valuePropName="checked">
						<Switch />
					</Form.Item>
				</Card>
				<div className={styles.actions}>
					<Form.Item>
						<Space size="middle">
							<Button type="primary" htmlType="submit" icon={<PlusOutlined />} loading={isSubmitting}>
								Skapa viktigt meddelande
							</Button>
						</Space>
					</Form.Item>
				</div>
			</Form>
		</PageContainer>
	);
};

export default CreateAnnouncementScreen;
