import { Button, Modal } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

interface Props {
	productId: number;
	removeStoreProduct: (productId: number) => void;
}

const ProductActions = ({ productId, removeStoreProduct }: Props) => {
	const deleteProduct = async () => {
		await removeStoreProduct(productId);
	};

	const confirmDelete = () => {
		Modal.confirm({
			title: 'Ta bort produkt från butik',
			content: 'Är du säker på att du vill ta bort produkten från butiken?',
			okText: 'Ja, Ta bort',
			cancelText: 'Avbryt',
			onOk: deleteProduct,
		});
	};
	return <Button onClick={confirmDelete} type="text" danger shape="circle" icon={<DeleteOutlined />} size="large" />;
};

export default ProductActions;
