import React, { useMemo, useState } from 'react';
import { StorageFileCategory } from '../models/DocumentFile';
import useFetch from '@hooks/fetch';
import { notification } from 'antd';

interface State {
	categories: StorageFileCategory[];
	addCategory: (category: StorageFileCategory) => void;
	editCategory: (category: StorageFileCategory) => void;
	deleteCategory: (id: number) => void;
}

const initialState: State = {
	categories: [],
	addCategory: () => undefined,
	editCategory: () => undefined,
	deleteCategory: () => undefined,
};

const StorageFileCategoryContext = React.createContext<State>(initialState);

interface Props {
	children: React.ReactNode;
}

export const StorageFileCategoryContextProvider = ({ children }: Props) => {
	const [categories, setCategories] = useState<StorageFileCategory[]>(initialState.categories);

	useFetch<StorageFileCategory[]>(`/admin/documents/categories`, setCategories, () => {
		notification.error({
			message: 'Vi kunde inte hämta kategorierna. Vänligen försök igen.',
			placement: 'bottomRight',
			duration: 10,
		});
	});

	const value: State = useMemo(() => {
		return {
			categories,
			setCategories,
			addCategory: (category: StorageFileCategory) => setCategories([...categories, category]),
			editCategory: (updatedCategory: StorageFileCategory) =>
				setCategories(categories.map((cat) => (cat.id === updatedCategory.id ? updatedCategory : cat))),
			deleteCategory: (id: number) => setCategories(categories.filter((c) => c.id !== id)),
		};
	}, [categories]);

	return <StorageFileCategoryContext.Provider value={value}>{children}</StorageFileCategoryContext.Provider>;
};

export default StorageFileCategoryContext;
