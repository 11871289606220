import {
	HomeOutlined,
	CodepenOutlined,
	AppstoreOutlined,
	ReadOutlined,
	ContainerOutlined,
	StopOutlined,
	AuditOutlined,
	BellOutlined,
	TagOutlined,
	QuestionOutlined,
	LogoutOutlined,
	PrinterOutlined,
	FileExcelOutlined,
	FileTextOutlined,
	FileExclamationOutlined,
	ExclamationOutlined,
	HddOutlined,
	UserOutlined,
	UnlockOutlined,
	QrcodeOutlined,
	ToolOutlined,
} from '@ant-design/icons';
import Role from '../models/Role';
import { isProduction } from '../config';
import { ItemType } from 'antd/es/menu/interface';

type Route = ItemType & {
	role?: Role[];
	dev?: string; // Keep this as a string, to avoid ItemType console log warning
};

export const getSideMenuRoutes = (roles: Role[]) => {
	const routes: Route[] = [
		{
			key: 'stores',
			icon: <HomeOutlined />,
			label: 'Butiker',
			role: [Role.Admin, Role.FranchiceAdmin],
		},
		{
			key: 'users',
			icon: <UserOutlined />,
			label: 'Användare',
			role: [Role.Admin],
		},
		{
			key: 'information',
			icon: <FileTextOutlined />,
			label: 'Information',
			role: [Role.Admin, Role.FranchiceAdmin],
			children: [
				{
					key: 'information/assortment',
					icon: <FileTextOutlined />,
					label: 'För handlare',
				},
				{
					key: 'information/documents',
					icon: <FileTextOutlined />,
					label: 'Dokument',
				},
			],
		},
		{
			key: 'news',
			icon: <ReadOutlined />,
			label: 'Nyheter',
			role: [Role.Admin, Role.FranchiceAdmin],
		},
		{
			key: 'order',
			icon: <CodepenOutlined />,
			label: 'Beställ',
			role: [Role.Admin],
		},
		{
			key: 'products',
			icon: <AppstoreOutlined />,
			label: 'Produkter',
			role: [Role.Admin, Role.Maintainer],
		},
		{
			key: 'push-notifications',
			icon: <BellOutlined />,
			label: 'Pusnotiser',
			role: [Role.Admin, Role.FranchiceAdmin],
		},
		{
			key: 'offers',
			icon: <TagOutlined />,
			label: 'Erbjudanden',
			role: [Role.Admin, Role.FranchiceAdmin],
		},
		{
			key: 'discounts',
			icon: <QrcodeOutlined />,
			label: 'Rabattkoder',
			role: [Role.Admin, Role.FranchiceAdmin],
			dev: 'true',
		},
		{
			key: 'tags',
			icon: <UnlockOutlined />,
			label: 'Taggar',
			role: [Role.Admin],
		},
		{
			type: 'divider',
		},
		{
			type: 'group',
			label: 'Automater',
			role: [Role.Admin],
			children: [
				{
					key: 'vending-machines',
					icon: <HddOutlined />,
					label: 'Varuautomater',
				},
				{
					key: 'tobacco-products',
					icon: <TagOutlined />,
					label: 'Automatprodukter',
				},
				{
					key: 'vending-machine-events',
					icon: <ContainerOutlined />,
					label: 'Automatköp',
				},
			],
		},
		{
			key: 'vending-machine-events',
			icon: <ContainerOutlined />,
			label: 'Automatköp',
			role: [Role.FranchiceAdmin],
		},
		{
			type: 'divider',
			role: [Role.Admin, Role.FranchiceAdmin],
		},
		{
			key: 'announcements',
			icon: <ExclamationOutlined />,
			label: 'Viktiga meddelanden',
			role: [Role.Admin],
		},
		{
			key: 'offer-print',
			icon: <PrinterOutlined />,
			label: 'Skriv ut erbjudande',
		},
		{
			key: 'events',
			icon: <ContainerOutlined />,
			label: 'Händelser',
			role: [Role.Admin, Role.Maintainer, Role.FranchiceAdmin],
		},
		{
			key: 'applications',
			icon: <AuditOutlined />,
			label: 'Ansökningar',
			role: [Role.Admin, Role.Maintainer],
		},
		{
			key: 'thefts',
			icon: <FileExcelOutlined />,
			label: 'Stölder',
			role: [Role.Admin, Role.Maintainer, Role.FranchiceAdmin],
		},
		{
			key: 'fault-reports',
			icon: <ToolOutlined />,
			label: 'Felanmälan',
			role: [Role.Admin, Role.FranchiceAdmin],
		},
		{
			key: 'banned-accounts',
			icon: <StopOutlined />,
			label: 'Spärrade konton',
			role: [Role.Admin],
		},
		{
			key: 'banned-accounts-franchise',
			icon: <StopOutlined />,
			label: 'Spärrade konton',
			role: [Role.FranchiceAdmin],
		},
		{
			key: 'common-questions',
			icon: <QuestionOutlined />,
			label: 'Vanliga frågor',
			role: [Role.Admin],
		},
		{
			key: 'terms-and-conditions',
			icon: <FileExclamationOutlined />,
			label: 'Allmänna villkor',
			role: [Role.Admin],
		},
		/*
		{
			key: 'settings',
			icon: <SettingOutlined />,
			label: 'Inställningar',
			role: [Role.Admin, Role.Maintainer],
		},
		*/
		{
			key: 'documents',
			icon: <FileTextOutlined />,
			label: 'Dokument',
			role: [Role.Admin],
		},
		{
			type: 'divider',
		},
		{
			key: 'logout',
			icon: <LogoutOutlined />,
			danger: true,
			label: 'Logga ut',
		},
	];

	const permittedRoutes = routes.filter((route) => {
		if (isProduction && route.dev) return false;

		if (!route.role) return true;

		return roles.some((role) => (route.role as Role[]).includes(role));
	});

	return permittedRoutes;
};
