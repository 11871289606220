import styles from './styles/CreateDocumentScreen.module.scss';
import { Button, Card, Space, notification, Form, Input, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import r from '../http';
import PageContainer from '../components/PageContainer';
import FormStorageFile from '@components/form/StorageFile';
import DocumentContext from '../state/Document';
import { DocumentFile } from '@models/DocumentFile';
import { formLayout } from '../config/constants';
import StorageFileCategoryModal from '@components/StorageFileCategoryModal';
import useStorageFileCategory from '@hooks/storage-file-category';

const CreateDocumentScreen = () => {
	const { addDocument } = useContext(DocumentContext);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const { categories, isModalOpen, addCategory, editCategory, deleteCategory, openModal, closeModal } =
		useStorageFileCategory();

	const submit = async (form: any) => {
		const body = new FormData();
		if (form.document[0]) {
			const file = form.document[0].originFileObj;
			const fileType = file.name.split('.')[1] ?? '';

			body.append('name', form.name);
			body.append('fileType', fileType);
			body.append('categoryId', form.categoryId);
			body.append('file', file);
		}

		try {
			setIsSubmitting(true);
			const response = await r.post<DocumentFile>(`/admin/documents`, body);
			addDocument(response.data);
			notification.success({
				message: 'Dokument har lagts till!',
				placement: 'bottomRight',
				duration: 5,
			});

			navigate(-1);
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}

		setIsSubmitting(false);
	};

	return (
		<PageContainer back title="Lägg till dokument">
			<Form {...formLayout} labelAlign="left" form={form} size="large" onFinish={submit}>
				<Card>
					<Form.Item label="Namn" name="name" rules={[{ required: true, message: 'Vänligen ange namn' }]}>
						<Input placeholder="Namn" />
					</Form.Item>
					<Form.Item
						extra={
							<Button style={{ marginTop: 10 }} onClick={openModal}>
								Hantera kategorier
							</Button>
						}
						label="Kategori"
						name="categoryId"
						rules={[{ required: true, message: 'Vänligen välj kategori' }]}
					>
						<Select
							placeholder="Kategori"
							style={{ width: '100%' }}
							optionFilterProp="label"
							options={categories.map((category) => ({
								value: category.id,
								label: category.name,
							}))}
						/>
					</Form.Item>
					<FormStorageFile
						label="Dokument"
						name="document"
						rules={[{ required: true, message: 'Vänligen välj ett dokument' }]}
					/>
				</Card>
				<div className={styles.actions}>
					<Form.Item>
						<Space size="middle">
							<Button onClick={() => navigate(-1)} type="text">
								Avbryt
							</Button>
							<Button icon={<PlusOutlined />} type="primary" htmlType="submit" loading={isSubmitting}>
								Lägg till dokument
							</Button>
						</Space>
					</Form.Item>
				</div>
			</Form>
			<StorageFileCategoryModal
				open={isModalOpen}
				categories={categories}
				addCategory={addCategory}
				editCategory={editCategory}
				removeCategory={deleteCategory}
				onClose={closeModal}
			/>
		</PageContainer>
	);
};

export default CreateDocumentScreen;
