import { Button, Form, Card, Space, Divider, Spin } from 'antd';
import { useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageContainer from '../../components/PageContainer';
import styles from './styles/EditBannedAccountScreen.module.scss';
import TextArea from 'antd/lib/input/TextArea';
import { EditOutlined } from '@ant-design/icons';
import useBannedUsers from '@hooks/banned-users';
import UserIdentifierField from '../../gui/UserIdentifierField';
import { BannedAccountForm } from '../../models/form/BannedAccountForm';

const EditBannedAccountScreen = () => {
	const { bannedUsers, editBannedUser } = useBannedUsers();
	const [form] = Form.useForm();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { id } = useParams();
	const isPhoneNumber = Form.useWatch('isPhoneNumber', form);
	const navigate = useNavigate();

	const bannedUser = useMemo(() => bannedUsers.find((user) => user.id === parseInt(id!)), [bannedUsers, id]);

	if (!bannedUser) {
		return <h1>Loading ...</h1>;
	}

	const onSubmit = async (formData: BannedAccountForm) => {
		if (!bannedUser) return;
		setIsSubmitting(true);
		const userIdentifier = !formData.isPhoneNumber ? formData.personalIdentityNumber : formData.phoneNumber;

		editBannedUser({
			id: bannedUser.id,
			userId: bannedUser.userId,
			reason: formData.reason,
			userIdentifier,
		}).then((result) => {
			if (result === true) navigate(-1);
		});
		setIsSubmitting(false);
	};

	const onChangeIsPhoneNumber = (value: boolean) => {
		form.setFieldValue('isPhoneNumber', value);
	};

	const layout = {
		labelCol: { span: 8 },
		wrapperCol: { span: 14 },
	};

	return (
		<PageContainer title="Uppdatera spärrat konto" back>
			<Spin spinning={!bannedUser} tip="Hämtar spärrat konto...">
				<Form
					form={form}
					labelAlign="left"
					{...layout}
					onFinish={onSubmit}
					size="large"
					initialValues={{
						isPhoneNumber: bannedUser.userIdentifier !== undefined && bannedUser.userIdentifier.includes('+'),
						phoneNumber: bannedUser.userIdentifier,
						personalIdentityNumber: bannedUser.userIdentifier,
						reason: bannedUser.reason,
					}}
				>
					<Card className={styles.inputs}>
						<UserIdentifierField isPhoneNumber={isPhoneNumber} onChangeIsPhoneNumber={onChangeIsPhoneNumber} />
						<Divider />
						<Form.Item<BannedAccountForm> label="Anledning" name="reason">
							<TextArea size="large" style={{ height: 120 }} />
						</Form.Item>
					</Card>
					<div className={styles.actions}>
						<Space size="middle">
							<Button onClick={() => navigate(-1)} type="text">
								Avbryt
							</Button>
							<Button loading={isSubmitting} type="primary" htmlType="submit" icon={<EditOutlined />}>
								Uppdatera spärrat konto
							</Button>
						</Space>
					</div>
				</Form>
			</Spin>
		</PageContainer>
	);
};

export default EditBannedAccountScreen;
