import { forwardRef, useEffect } from 'react';
import Editor from '@toast-ui/editor';
import '@toast-ui/editor/dist/toastui-editor.css';

interface Props {
	initialValue?: string | undefined;
	height?: string | undefined;
}

const TextEditor = forwardRef(({ initialValue, height = '100%' }: Props, ref: any) => {
	useEffect(() => {
		(async () => {
			if (ref.current) return;

			ref.current = new Editor({
				initialValue,
				previewHighlight: false,
				el: document.querySelector('#editor')!,
				height,
				initialEditType: 'markdown',
				previewStyle: 'vertical',
				toolbarItems: [['heading', 'bold', 'italic', 'strike'], ['hr'], ['ul', 'ol']],
			});
		})();
	}, []);

	return <div id="editor" style={{ background: '#FFF' }}></div>;
});

TextEditor.displayName = 'TextEditor';
export default TextEditor;
