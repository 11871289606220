import styles from './styles/CreateProductScreen.module.scss';
import { Button, Card, Divider, Form, Input, Space, InputNumber, notification } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useContext, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Product from '../models/Product';
import ProductContext from '../state/Product';
import r from '../http';
import PageContainer from '../components/PageContainer';
import FormImage from '../components/form/Image';
import LoaderWithLogo from '@components/LoaderWithLogo';
import { formLayout } from '../config/constants';

const EditProductScreen = () => {
	const { products, updateProduct } = useContext(ProductContext);
	const navigate = useNavigate();
	const { id } = useParams();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [form] = Form.useForm();

	const product = useMemo(() => products.find((product) => product.id === parseInt(id!)), [products, id]);

	if (!product) {
		return <LoaderWithLogo />;
	}

	const submit = async (form: any) => {
		const body = new FormData();
		body.append('id', product.id.toString());
		body.append('name', form.name);
		if (form.image[0].originFileObj) {
			body.append('image', form.image[0].originFileObj);
		}
		body.append('articleNumber', form.articleNumber);
		body.append('ean', form.ean);
		body.append('inPrice', form.inPrice);
		try {
			setIsSubmitting(true);
			const response = await r.put<Product>(`/admin/products/${product.id}`, body);
			updateProduct(response.data);
			notification.success({
				message: 'Produkt uppdaterad!',
				placement: 'bottomRight',
				duration: 5,
			});

			navigate(-1);
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
		setIsSubmitting(false);
	};

	return (
		<PageContainer back title="Uppdatera produkt">
			<Form
				{...formLayout}
				labelAlign="left"
				form={form}
				size="large"
				onFinish={submit}
				initialValues={{
					name: product.name,
					articleNumber: product.articleNumber,
					ean: product.ean,
					inPrice: product.inPrice,
					image: product.imageUrl
						? [
								{
									uid: '-1',
									name: product.imageUrl.split('/').reverse()[0],
									status: 'done',
									url: product.imageUrl,
								},
						  ]
						: [],
				}}
			>
				<Card>
					<Form.Item label="Namn" name="name" rules={[{ required: true, message: 'Vänligen ange namn' }]}>
						<Input placeholder="Namn" />
					</Form.Item>
					<Divider />
					<FormImage label="Bild" name="image" removeable={false} />
					<Divider />
					<Form.Item
						label="Artikelnummer"
						name="articleNumber"
						rules={[{ required: true, message: 'Vänligen ange artikelnummer' }]}
					>
						<Input type="number" placeholder="Artikelnummer" />
					</Form.Item>
					<Divider />
					<Form.Item
						label="EAN"
						name="ean"
						rules={[
							{ min: 8, max: 13, message: 'Vänligen ange giltigt EAN' },
							{ required: true, message: 'Vänligen ange EAN' },
						]}
					>
						<Input type="number" placeholder="EAN" />
					</Form.Item>
					<Divider />
					<Form.Item
						label="Inköpspris"
						name="inPrice"
						rules={[{ required: true, message: 'Vänligen ange inköpspris' }]}
					>
						<InputNumber placeholder="Inköpspris" addonAfter="SEK" />
					</Form.Item>
				</Card>
				<div className={styles.actions}>
					<Form.Item>
						<Space size="middle">
							<Button onClick={() => navigate(-1)} type="text">
								Avbryt
							</Button>
							<Button icon={<EditOutlined />} type="primary" htmlType="submit" loading={isSubmitting}>
								Uppdatera produkt
							</Button>
						</Space>
					</Form.Item>
				</div>
			</Form>
		</PageContainer>
	);
};

export default EditProductScreen;
