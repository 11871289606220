import { Button, Space, Typography } from 'antd';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import styles from '../styles/ZoomController.module.scss';

interface Props {
	zoom: number;
	setZoom: (n: number) => void;
}

const ZoomController = ({ zoom, setZoom }: Props) => {
	return (
		<div className={styles.container}>
			<Typography.Text strong className={styles.zoomText}>
				{(zoom * 100).toFixed(0)}%
			</Typography.Text>
			<Space>
				<Button
					size="large"
					icon={<ZoomInOutlined />}
					onClick={() => {
						if (zoom < 1.0) setZoom(zoom + 0.1);
					}}
					disabled={zoom >= 1.0}
				/>
				<Button
					size="large"
					icon={<ZoomOutOutlined />}
					onClick={() => {
						if (zoom > 0.5) setZoom(zoom - 0.1);
					}}
					disabled={zoom <= 0.5}
				/>
			</Space>
		</div>
	);
};

export default ZoomController;
