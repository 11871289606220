import { PriceType } from '../components/PriceLabel';
import { OfferForm, OfferFormWithImage } from '../components/types';
import { priceLabelConstants4inA4, priceLabelConstantsStoreOffer } from '../constants';

export const getInitial1ItemForm = (storageAddress: string): OfferForm => {
	const savedFormString = localStorage.getItem(storageAddress);

	if (!savedFormString) {
		return {
			preTitle: 'Dagens',
			title: 'Erbjudande',
			product: 'KEXCHOKLAD',
			extra: '100g',
			type: PriceType.Price,
			price: 49.9,
			priceShowDecimals: true,
			unit: 'kr/KG',
			save: 10,
			saveShowDecimals: false,
			first: 3,
			second: 2,
			quantity: 3,
			singlePrice: '10kr/st',
			show24FoodLogo: true,
		};
	}

	return JSON.parse(savedFormString);
};

export const getInitialStoreOfferWithImageForm = (storageAddress: string): OfferFormWithImage => {
	const savedFormString = localStorage.getItem(storageAddress);

	if (!savedFormString) {
		return {
			preTitle: 'Dagens',
			title: 'Erbjudande',
			product: 'KEXCHOKLAD',
			extra: '100g',
			image: null,
			imageScale: 1,
			type: PriceType.Price,
			price: 49.9,
			priceShowDecimals: true,
			unit: 'kr/KG',
			save: 10,
			saveShowDecimals: false,
			first: 3,
			second: 2,
			quantity: 3,
			singlePrice: '10kr/st',
			show24FoodLogo: true,
			priceLabelScale: priceLabelConstantsStoreOffer.defaultScale,
			priceLabelOffsetX: priceLabelConstantsStoreOffer.defaultOffsetX,
			priceLabelOffsetY: priceLabelConstantsStoreOffer.defaultOffsetY,
		};
	}

	return JSON.parse(savedFormString);
};

export const getInitial4ItemForm = (storageAddress: string) => {
	const savedFormString = localStorage.getItem(storageAddress);

	if (!savedFormString) {
		return {
			show24FoodLogo: true,
			offerQuantity: 4,
			offers: new Array(4).fill(0).map((_, i) => ({
				id: i,
				title: `ERBJUDANDE${i + 1}`,
				product: 'DELIKATESSKINKA',
				extra: 'Ursprungsland Spanien\nJämförpris 49,90 kr/kg',
				image: null,
				imageScale: 1,
				type: PriceType.Price,
				price: 49.9,
				priceShowDecimals: true,
				unit: 'kr/KG',
				save: 10,
				saveShowDecimals: false,
				first: 3,
				second: 2,
				quantity: 3,
				singlePrice: '10kr/st',
				priceLabelScale: priceLabelConstants4inA4.defaultScale,
				priceLabelOffsetX: priceLabelConstants4inA4.defaultOffsetX,
				priceLabelOffsetY: priceLabelConstants4inA4.defaultOffsetY,
			})),
		};
	}

	return JSON.parse(savedFormString);
};

export const getInitialLabelPrintForm = (storageAddress: string) => {
	const savedFormString = localStorage.getItem(storageAddress);

	if (!savedFormString) {
		return {
			show24FoodLogo: true,
			offerQuantity: 4,
			offers: new Array(4).fill(0).map((_, i) => ({
				id: i,
				title: `Passionsfrukt${i + 1}`,
				extra: 'Sverige.\nklass 1. jfr-pris 25 kr/kg',
				image: null,
				imageScale: 1,
				price: '25kr/kg',
			})),
		};
	}

	return JSON.parse(savedFormString);
};
