import styles from '../styles/TobaccoProducts.module.scss';
import { Divider, Input, Table, Typography, Button, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/lib/table';
import { useContext, useEffect, useMemo, useState } from 'react';
import VendingMachineProductActions from './VendingMachineProductActions';
import ListImage from '@components/table/ListImage';
import ProductCategory from '@models/ProductCategory';
import TobaccoProductContext from '../../../state/TobaccoProduct';
import useProductCategories from '@hooks/product-category';
import { VendingMachineProduct, VendingMachineProductResponse } from '@models/VendingMachineProducts';
import VendingMachineContext from '../../../state/VendingMachine';
import AddVendingMachineProductModal from './AddVendingMachineProductModal';
import EditVendingMachineProductModal from './EditVendingMachineProductModal';
import r from '../../../http';

const { Search } = Input;
const { Text } = Typography;

const getColumns = (
	productCategoryMap: Map<number, ProductCategory>,
	removeVendingMachineProduct: (productId: number) => void,
	onEdit: (vendingMachineProduct: VendingMachineProduct) => void
): ColumnsType<VendingMachineProduct> => [
	{
		title: 'Position',
		key: 'position',
		defaultSortOrder: 'ascend',
		sorter: (a, b) => a.position - b.position,
		width: 100,
		render: (_, vendingMachineProduct) => <Text strong>{vendingMachineProduct.position}</Text>,
	},
	{
		title: 'Bild',
		dataIndex: 'imageUrl',
		key: 'imageUrl',
		render: (_, vendingMachineProduct) => <ListImage src={vendingMachineProduct.tobaccoProduct.imageUrl} />,
		width: 70,
	},
	{
		title: 'Namn',
		render: (_, vendingMachineProduct) => <Text>{vendingMachineProduct.tobaccoProduct.name}</Text>,
		key: 'name',
		width: 160,
		sorter: (a, b) => a.tobaccoProduct.name.localeCompare(b.tobaccoProduct.name),
	},
	{
		title: 'Kategori',
		render: (_, vendingMachineProduct) => (
			<Text>{productCategoryMap?.get(vendingMachineProduct.tobaccoProduct.categoryId)?.name}</Text>
		),
		width: 160,
		sorter: (a, b) => {
			const categoryA = productCategoryMap?.get(a.tobaccoProduct.categoryId)?.name || '';
			const categoryB = productCategoryMap?.get(b.tobaccoProduct.categoryId)?.name || '';
			return categoryA.localeCompare(categoryB);
		},
	},
	{
		title: 'Pris',
		sortDirections: ['descend', 'ascend'],
		width: 160,
		sorter: (a, b) => a.price - b.price,
		render: (_, vendingMachineProduct) => (
			<div className={styles.tablePrice}>SEK {vendingMachineProduct.price.toFixed(2)}</div>
		),
	},
	{
		width: 150,
		responsive: ['sm'],
		fixed: 'right',
		render: (_, vendingMachineProduct: VendingMachineProduct) => (
			<VendingMachineProductActions
				product={vendingMachineProduct}
				removeProduct={() => removeVendingMachineProduct(vendingMachineProduct.id)}
				onEdit={onEdit}
			/>
		),
	},
	{
		responsive: ['xs'],
		width: 50,
		fixed: 'right',
		render: (_, vendingMachineProduct: VendingMachineProduct) => (
			<VendingMachineProductActions
				product={vendingMachineProduct}
				removeProduct={() => removeVendingMachineProduct(vendingMachineProduct.id)}
				onEdit={onEdit}
			/>
		),
	},
];

interface Props {
	vendingMachineId: number;
}

const VendingMachineProductsView = ({ vendingMachineId }: Props) => {
	const { vendingMachines } = useContext(VendingMachineContext);
	const { tobaccoProducts } = useContext(TobaccoProductContext);
	const [vendingMachineProducts, setVendingMachineProducts] = useState<VendingMachineProduct[]>([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [showAddProductsModal, setShowAddProductsModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState<VendingMachineProduct | undefined>(undefined);
	const [isLoading, setIsLoading] = useState(false);

	const { productCategories } = useProductCategories();

	const fetchVendingMachineProducts = async () => {
		try {
			setIsLoading(true);
			const response = await r.get<VendingMachineProductResponse[]>('/admin/vending-machine-products');
			const vendingMachineProducts = response.data
				.map((vp) => {
					const tp = tobaccoProducts.find((tp) => tp.id === vp.tobaccoProductId);
					const vm = vendingMachines.find((vm) => vm.id === vp.vendingMachineId);
					if (vendingMachineId === vp.vendingMachineId && tp && vm)
						return {
							id: vp.id,
							position: vp.position,
							price: vp.price,
							tobaccoProduct: tp,
							vendingMachine: vm,
						};
				})
				.filter(Boolean) as VendingMachineProduct[];
			setVendingMachineProducts(vendingMachineProducts);
		} catch (e) {
			notification.error({
				message: 'Vi kunde inte hämta produkterna. Vänligen försök igen.',
				placement: 'bottomRight',
				duration: 10,
			});
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (tobaccoProducts.length === 0 || vendingMachines.length === 0) return;

		fetchVendingMachineProducts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tobaccoProducts, vendingMachines]);

	const addVendingMachineProducts = (product: VendingMachineProductResponse) => {
		const tp = tobaccoProducts.find((tp) => tp.id === product.tobaccoProductId);
		const vm = vendingMachines.find((vm) => vm.id === product.vendingMachineId);

		if (!vendingMachineProducts || !tp || !vm) return;

		const newProduct: VendingMachineProduct = {
			id: product.id,
			position: product.position,
			price: product.price,
			tobaccoProduct: tp,
			vendingMachine: vm,
		};
		setVendingMachineProducts([...vendingMachineProducts, newProduct]);
	};

	const editVendingMachineProduct = (product: VendingMachineProductResponse) => {
		const tp = tobaccoProducts.find((tp) => tp.id === product.tobaccoProductId);
		const vm = vendingMachines.find((vm) => vm.id === product.vendingMachineId);

		if (!vendingMachineProducts || !tp || !vm) return;

		const updatedProduct: VendingMachineProduct = {
			id: product.id,
			position: product.position,
			price: product.price,
			tobaccoProduct: tp,
			vendingMachine: vm,
		};
		setVendingMachineProducts([...vendingMachineProducts.filter((vpm) => vpm.id !== product.id), updatedProduct]);
	};

	const removeVendingMachineProduct = (vendingMachineProductId: number) => {
		setVendingMachineProducts(vendingMachineProducts.filter((vmp) => vmp.id !== vendingMachineProductId));
	};

	const productCategoryMap = useMemo(
		() => new Map(productCategories.map((productCategory) => [productCategory.id, productCategory])),
		[productCategories]
	);

	const filterProperties: ['name'] = ['name'];
	const displayedVendingMachineProducts = vendingMachineProducts.filter((vendingMachineProduct) =>
		filterProperties.some((filterProperty) =>
			vendingMachineProduct.tobaccoProduct[filterProperty].toLowerCase().includes(searchTerm.toLowerCase())
		)
	);

	const onEdit = (vendingMachineProduct: VendingMachineProduct) => {
		setShowEditModal(true);
		setSelectedProduct(vendingMachineProduct);
		return;
	};

	return (
		<div className={styles.storeProducts}>
			<Typography.Title level={2}>Automatprodukter</Typography.Title>
			<Divider />
			<div className={styles.actionBar}>
				<Search
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					size="large"
					placeholder="Sök efter namn"
					style={{ width: 400 }}
				/>
				<Button size="large" onClick={() => setShowAddProductsModal(true)} type="primary" icon={<PlusOutlined />}>
					Lägg till automatprodukter
				</Button>
			</div>
			<Divider />
			<Table
				locale={{
					emptyText: 'Inga produkter',
					triggerDesc: 'Tryck för att sortera i fallande ordning',
					triggerAsc: 'Tryck för att sortera i stigande ordning',
					cancelSort: 'Tryck för att nollställa sortering',
				}}
				loading={isLoading}
				columns={getColumns(productCategoryMap, removeVendingMachineProduct, onEdit)}
				pagination={{ position: ['bottomRight'], defaultPageSize: 50 }}
				dataSource={displayedVendingMachineProducts}
				size="middle"
				scroll={{ x: '100%' }}
				rowKey={(product) => product.id}
			/>
			<AddVendingMachineProductModal
				open={showAddProductsModal}
				onClose={() => setShowAddProductsModal(false)}
				addProduct={addVendingMachineProducts}
				vendingMachineId={vendingMachineId}
				existingProducts={vendingMachineProducts}
			/>
			<EditVendingMachineProductModal
				open={showEditModal}
				onClose={() => setShowEditModal(false)}
				editProduct={editVendingMachineProduct}
				vendingMachineId={vendingMachineId}
				existingProducts={vendingMachineProducts}
				vendingMachineProduct={selectedProduct}
			/>
		</div>
	);
};

export default VendingMachineProductsView;
