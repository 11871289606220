import { useMemo, ReactElement } from 'react';
import ResizableText from './ResizeableText';

interface ResizableTextWrapperProps {
	textComponent: ReactElement;
	containerSize: string;
	initialFontSize: string;
}

const ResizableTextWrapper = ({ textComponent, containerSize, initialFontSize }: ResizableTextWrapperProps) => {
	const resizableText = useMemo(
		() => (
			<ResizableText textComponent={textComponent} containerSize={containerSize} initialFontSize={initialFontSize} />
		),
		[textComponent, containerSize, initialFontSize]
	);

	return resizableText;
};

export default ResizableTextWrapper;
