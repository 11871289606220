import { Button, Card, Checkbox, Col, Divider, Form, Input, InputNumber, Row, Select, Space, Switch } from 'antd';
import { PrinterOutlined, DownloadOutlined, UndoOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import FormImage from '../../../components/form/Image';
import styles from '../../styles/4inA4PrintScreen.module.scss';
import { PriceType } from '../PriceLabel';
import React, { useEffect, useId, useState } from 'react';
import Title from 'antd/es/typography/Title';
import Preview from './4inA4WithImagePreview';
import { Offer4inA4WithImage, typeSelectOptions } from '../types';
import { getInitial4ItemForm } from '../../functions/getInitialForm';
import { printJpg, printPdf } from '../../functions/print';
import ZoomController from '../ZoomController';
import { priceLabelConstants4inA4 } from '../../constants';

const storageKey = '@offer-print-4inA4-with-image:form:v3';

const _4inA4WithImage = () => {
	const [form] = Form.useForm();
	const offers = Form.useWatch('offers', form);
	const show24FoodLogo = Form.useWatch('show24FoodLogo', form);
	const [selectedOffer, setSelectedOffer] = useState<number>(0);
	const iFrameId = useId();
	const [zoom, setZoom] = useState<number>(1.0);

	useEffect(() => {
		if (!offers) return;
		const a = {
			offers: offers.map((offer: Offer4inA4WithImage) => ({
				...offer,
				image: null,
			})),
			show24FoodLogo: show24FoodLogo,
		};

		localStorage.setItem(storageKey, JSON.stringify(a));
	}, [offers, show24FoodLogo]);

	return (
		<Row gutter={20}>
			<Col span={24}>
				<Space>
					<Button
						size="large"
						type="primary"
						onClick={async () => {
							await setZoom(1);
							printPdf(iFrameId);
						}}
						icon={<PrinterOutlined />}
					>
						Skriv ut
					</Button>
					<Button
						size="large"
						type="primary"
						onClick={async () => {
							await setZoom(1);
							printJpg('fourInA4WithImage-offer-preview');
						}}
						icon={<DownloadOutlined />}
					>
						Ladda ner bild
					</Button>
				</Space>
				<Divider />
			</Col>
			<Col span={8}>
				<iframe style={{ visibility: 'hidden', position: 'absolute' }} id={iFrameId} className="iframe" src=""></iframe>
				<Form form={form} initialValues={getInitial4ItemForm(storageKey)} layout="vertical" size="middle">
					<Card>
						<Title style={{ margin: 0 }} level={3}>
							Sektion
						</Title>
						<Space>
							{[0, 1, 2, 3].map((n) => (
								<Button
									key={n}
									type={n === selectedOffer ? 'primary' : 'default'}
									onClick={() => setSelectedOffer(n)}
									style={{ marginBottom: 10 }}
								>
									{n + 1}
								</Button>
							))}
						</Space>
						<Form.List name="offers">
							{(fields) =>
								fields.map((field, i) =>
									i !== selectedOffer ? (
										<React.Fragment key={field.key}></React.Fragment>
									) : (
										<React.Fragment key={field.key}>
											<Form.Item label="Rubrik" name={[field.name, 'title']}>
												<Input />
											</Form.Item>
											<Form.Item label="Namn" name={[field.name, 'product']}>
												<Input />
											</Form.Item>
											<Form.Item label="Extra" help="T.ex. antal gram, jämförpris" name={[field.name, 'extra']}>
												<TextArea rows={2} />
											</Form.Item>
											<div className={styles.sectionDivider}>
												<Divider orientation="left" orientationMargin="0">
													Bild
												</Divider>
											</div>
											<FormImage label="" name={[field.name, 'image']} />
											<Space>
												<Form.Item label="Skala" name={[field.name, 'imageScale']}>
													<InputNumber min={0.5} step={0.05} max={2} />
												</Form.Item>
												<Button type="primary" onClick={() => form.setFieldValue(['offers', i, 'imageScale'], 1)}>
													<UndoOutlined />
													Återställ skala
												</Button>
											</Space>
											<div className={styles.priceDivider}>
												<Divider orientation="left" orientationMargin="0">
													Prisetikett
												</Divider>
											</div>
											<Form.Item label="Pristyp" name={[field.name, 'type']} required>
												<Select options={typeSelectOptions} />
											</Form.Item>
											{form.getFieldValue(['offers', i, 'type']) === PriceType.Price && (
												<>
													<Form.Item label="Pris" name={[field.name, 'price']}>
														<InputNumber addonAfter="Kr" precision={2} />
													</Form.Item>
													<Form.Item
														valuePropName="checked"
														name={[field.name, 'priceShowDecimals']}
														help="Gäller enbart vid `.00`"
													>
														<Checkbox>Visa decimaler på pris</Checkbox>
													</Form.Item>
													<Form.Item label="Enhet" name={[field.name, 'unit']} help="T.ex. KG, förp, st">
														<Input />
													</Form.Item>
												</>
											)}
											{form.getFieldValue(['offers', i, 'type']) === PriceType.PieceForPiece && (
												<>
													<Form.Item label="Första" name={[field.name, 'first']} required>
														<InputNumber precision={0} />
													</Form.Item>
													<Form.Item label="Andra" name={[field.name, 'second']} required>
														<InputNumber precision={0} />
													</Form.Item>
													<Form.Item label="Styckpris" name={[field.name, 'singlePrice']} help="T.ex. KG, förp, st">
														<Input />
													</Form.Item>
												</>
											)}
											{form.getFieldValue(['offers', i, 'type']) === PriceType.AmountForPrice && (
												<>
													<Form.Item label="Antal" name={[field.name, 'quantity']} required>
														<InputNumber precision={0} />
													</Form.Item>
													<Form.Item label="Pris" name={[field.name, 'price']} required>
														<InputNumber addonAfter="Kr" precision={2} />
													</Form.Item>
													<Form.Item
														valuePropName="checked"
														name={[field.name, 'priceShowDecimals']}
														help="Gäller enbart vid `.00`"
													>
														<Checkbox>Visa decimaler på pris</Checkbox>
													</Form.Item>
													<Form.Item label="Spara" name={[field.name, 'save']}>
														<InputNumber addonAfter="Kr" precision={2} />
													</Form.Item>
													<Form.Item
														valuePropName="checked"
														name={[field.name, 'saveShowDecimals']}
														help="Gäller enbart vid `.00`"
													>
														<Checkbox>Visa decimaler på spara</Checkbox>
													</Form.Item>
												</>
											)}
											<Space>
												<Form.Item label="Skala" name={[field.name, 'priceLabelScale']}>
													<InputNumber
														min={priceLabelConstants4inA4.min}
														max={priceLabelConstants4inA4.max}
														step={0.05}
													/>
												</Form.Item>
												<Button
													type="primary"
													onClick={() =>
														form.setFieldValue(['offers', i, 'priceLabelScale'], priceLabelConstants4inA4.defaultScale)
													}
												>
													<UndoOutlined />
													Återställ skala
												</Button>
											</Space>
											<Space>
												<Form.Item label="Förskjutning X-led" name={[field.name, 'priceLabelOffsetX']}>
													<InputNumber
														min={priceLabelConstants4inA4.left}
														max={priceLabelConstants4inA4.right}
														step={10}
													/>
												</Form.Item>
												<Form.Item label="Förskjutning Y-led" name={[field.name, 'priceLabelOffsetY']}>
													<InputNumber
														min={priceLabelConstants4inA4.top}
														max={priceLabelConstants4inA4.bottom}
														step={10}
													/>
												</Form.Item>
												<Button
													type="primary"
													onClick={() => {
														form.setFieldValue(
															['offers', i, 'priceLabelOffsetX'],
															priceLabelConstants4inA4.defaultOffsetX
														);
														form.setFieldValue(
															['offers', i, 'priceLabelOffsetY'],
															priceLabelConstants4inA4.defaultOffsetY
														);
													}}
												>
													<UndoOutlined />
													Återställ förskjutning
												</Button>
											</Space>
										</React.Fragment>
									)
								)
							}
						</Form.List>
						<div style={{ paddingTop: 1 }}>
							<Title level={3}>Annat</Title>
							<Form.Item label="Visa 24Food logo" name="show24FoodLogo" valuePropName="checked">
								<Switch />
							</Form.Item>
						</div>
					</Card>
				</Form>
			</Col>
			<Col flex="auto" className={styles.previewColumn}>
				<ZoomController zoom={zoom} setZoom={setZoom} />
				<div
					className={styles.previewContainer}
					id="fourInA4WithImage-offer-preview"
					style={{
						transform: `scale(${zoom})`,
						position: 'absolute',
						top: 80,
						left: 0,
						transformOrigin: 'center top',
					}}
				>
					{offers && (
						<Preview
							iFrameId={iFrameId}
							show24FoodLogo={show24FoodLogo}
							offers={offers}
							setPriceLabelScale={(id: number, scale: number) =>
								form.setFieldValue(['offers', id, 'priceLabelScale'], scale)
							}
							setPriceLabelOffset={(offset: { id: number; x: number; y: number }) => {
								form.setFieldValue(['offers', offset.id, 'priceLabelOffsetX'], offset.x);
								form.setFieldValue(['offers', offset.id, 'priceLabelOffsetY'], offset.y);
							}}
						/>
					)}
				</div>
			</Col>
		</Row>
	);
};

export default _4inA4WithImage;
