import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import r from '../http';

type FetchItemHookOptions<T> = {
	localData: T[];
	endpoint?: (id: string) => string;
};

const useFetchItem = <T extends { id: number }>({ localData, endpoint }: FetchItemHookOptions<T>) => {
	const [item, setItem] = useState<T | null | undefined>(undefined);
	const { id } = useParams<{ id: string }>();

	useEffect(() => {
		const fetchItem = async () => {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const savedItem = localData.find((item) => item.id === parseInt(id!));
			if (savedItem) {
				setItem(savedItem);
			} else if (endpoint) {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				const { data } = await r.get<T>(endpoint(id!));
				setItem(data);
			} else {
				setItem(null);
			}
		};

		if (id !== undefined && !item) fetchItem();
	}, [id, localData, item, endpoint]);

	return item;
};

export default useFetchItem;
