import React, { useEffect, useMemo, useState } from 'react';
import { requestPermission, sendNotification } from '../helpers/notification';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import VendingMachineApplication from '../models/VendingMachineApplication';
import r from '../http';
import { playNotificationSound } from '../functions/playNotificationSound';
import { isDevelopment } from '../config';
import { HddOutlined } from '@ant-design/icons';
import { blue } from '@ant-design/colors';
import usePermissions from '@hooks/permissions';

interface State {
	vendingMachineApplications: VendingMachineApplication[];
}

const initialState: State = {
	vendingMachineApplications: [],
};

const VendingMachineApplicationContext = React.createContext<State>(initialState);

interface Props {
	children: React.ReactNode;
}

export const VendingMachineApplicationContextProvider = ({ children }: Props) => {
	const [vendingMachineApplications, setVendingMachineApplications] = useState<VendingMachineApplication[]>(
		initialState.vendingMachineApplications
	);
	const { isAdminOrMaintainer } = usePermissions();
	const navigate = useNavigate();

	const notifyUser = async () => {
		playNotificationSound();
		const browserNotification = sendNotification('Ny ansökan för varuautomat!');
		if (browserNotification) {
			browserNotification.onclick = () => {
				browserNotification.close();
				navigate('applications');
			};

			return;
		}

		const id = Date.now().toString();
		notification.info({
			key: id,
			message: `Ny ansökan för varuautomat!`,
			description: '',
			placement: 'topRight',
			icon: (
				<div style={{ color: blue.primary }}>
					<HddOutlined />
				</div>
			),
			onClick: () => {
				navigate('applications');
				notification.destroy(id);
			},
		});
	};

	useEffect(() => {
		if (!isAdminOrMaintainer || isDevelopment) return;

		const destroyed = setInterval(async () => {
			try {
				const response = await r.get<VendingMachineApplication[]>('/admin/vending-machine/applications');
				const { data } = response;
				if (data.length > vendingMachineApplications.length) {
					notifyUser();
				}
				setVendingMachineApplications(data);
			} catch (e) {
				console.log('Error: ', e);
				// TODO: handle
			}
		}, 1000);

		return () => {
			clearInterval(destroyed);
		};
	}, [isAdminOrMaintainer, vendingMachineApplications]);

	useEffect(() => {
		requestPermission();
	}, []);

	const value: State = useMemo(() => {
		return {
			vendingMachineApplications,
		};
	}, [vendingMachineApplications]);

	return (
		<VendingMachineApplicationContext.Provider value={value}>{children}</VendingMachineApplicationContext.Provider>
	);
};

export default VendingMachineApplicationContext;
