import StoreProducts from '@models/StoreProducts';
import useFetch from './fetch';
import { useState } from 'react';
import r from '../http';
import useNotification from './notification';

const useStoreProducts = (storeId: number | null) => {
	const { successNotification, errorNotification } = useNotification();

	const [storeProducts, setStoreProducts] = useState<number[]>([]);
	const storeProductsUrl = storeId ? `/admin/stores-products` : undefined;

	const { isFetching } = useFetch<StoreProducts>(storeProductsUrl + `/${storeId}`, (data) =>
		setStoreProducts(data.productIds)
	);

	const addStoreProducts = async (body: { id: number; productIds: number[] }): Promise<boolean> => {
		if (!storeProducts) return false;
		try {
			await r.post(`${storeProductsUrl}/store`, body);
			setStoreProducts([...body.productIds, ...storeProducts]);
			successNotification(`${body.productIds.length} produkter tillagda!`);
			return true;
		} catch (e) {
			errorNotification('Något gick fel');
			return false;
		}
	};

	const removeStoreProduct = async (productId: number) => {
		try {
			await r.delete(`${storeProductsUrl}/store/${storeId}/product/${productId}`);
			setStoreProducts(storeProducts.filter((id) => id !== productId));
			successNotification('Produkt borttagen!');
		} catch (e) {
			errorNotification('Något gick fel');
		}
	};

	return { isFetching, storeProducts, setStoreProducts, addStoreProducts, removeStoreProduct };
};

export default useStoreProducts;
