import { Form, Input, Radio } from 'antd';
import { isValidSwedishPersonnummer } from '../functions/swedishPersonnummer';
import { isValidPhoneNumber } from '../functions/phoneNumber';
import { useEffect, useState } from 'react';
import { RadioChangeEvent } from 'antd/lib';

type T = {
	isPhoneNumber: boolean;
	phoneNumber: string;
	personalIdentityNumber: string;
};

interface UserIdentifierFieldProps {
	isPhoneNumber: boolean;
	onChangeIsPhoneNumber: (value: boolean) => void;
}

const UserIdentifierField = ({ isPhoneNumber, onChangeIsPhoneNumber }: UserIdentifierFieldProps) => {
	const [usePhoneNumber, setUsePhoneNumber] = useState<boolean>(isPhoneNumber);

	useEffect(() => {
		setUsePhoneNumber(isPhoneNumber);
	}, [isPhoneNumber]);

	return (
		<>
			<Form.Item<T> label="Typ" name="isPhoneNumber">
				<Radio.Group
					block
					options={[
						{ label: 'Telefonnummer', value: true },
						{ label: 'Personnummer', value: false },
					]}
					optionType="button"
					buttonStyle="solid"
					onChange={(e: RadioChangeEvent) => onChangeIsPhoneNumber(e.target.value)}
				/>
			</Form.Item>
			{usePhoneNumber ? (
				<Form.Item<T>
					label="Telefonnummer"
					name="phoneNumber"
					extra="Telefonnummer måste vara med riktnummer och utan mellanslag. Kopiera telefonnummret som det är från events."
					rules={[
						{ required: true, message: 'Vänligen ange telefonnummer.' },
						{
							pattern: /^\+\d+$/,
							message: 'Telefonnumret måste börja med ett + följt av siffror, utan mellanslag eller bindestreck.',
						},
						() => ({
							validator(_, value) {
								if (!value || isValidPhoneNumber(value)) {
									return Promise.resolve();
								}
								return Promise.reject(new Error('Ogiltigt telefonnummer.'));
							},
						}),
					]}
				>
					<Input />
				</Form.Item>
			) : (
				<Form.Item<T>
					label="Personnummer"
					name="personalIdentityNumber"
					extra="Personnumret måste vara 12 siffror."
					rules={[
						{ required: true, message: 'Vänligen ange personnummer.' },
						{
							pattern: /^\d{12}$/,
							message: 'Personnumret måste vara 12 siffror utan bindestreck eller mellanslag.',
						},
						() => ({
							validator(_, value) {
								if (!value || isValidSwedishPersonnummer(value)) {
									return Promise.resolve();
								}
								return Promise.reject(new Error('Ogiltigt personnummer.'));
							},
						}),
					]}
				>
					<Input />
				</Form.Item>
			)}
		</>
	);
};

export default UserIdentifierField;
