import { Button, Modal, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import r from '../../../http';
import TableActions from '../../../components/table/Actions';
import useTobaccoProducts from '@hooks/tobacco-products';
import { RequestError } from '../../../http/exceptions';

interface Props {
	id: number;
}

const Actions = ({ id }: Props) => {
	const navigate = useNavigate();
	const { deleteTobaccoProduct } = useTobaccoProducts();

	const removeConfirmation = () => {
		Modal.warning({
			title: `Är du säker att du vill ta bort produkten?`,
			okText: 'Ja, ta bort',
			okCancel: true,
			cancelText: 'Avbryt',
			onOk: remove,
		});
	};

	const remove = async () => {
		try {
			await r.delete(`/admin/tobacco-products/${id}`);
			deleteTobaccoProduct(id);
			notification.success({
				message: `Produkten borttaget`,
				placement: 'bottomRight',
				duration: 5,
			});
		} catch (e) {
			if (e instanceof RequestError) {
				if (
					e.response?.data && // Type check
					typeof e.response.data === 'object' && // Type check
					'type' in e.response.data && // Type check
					typeof e.response.data.type === 'string' && // Type check
					e.response.data.type === 'CONNECTED_VENDING_MACHINE_PRODUCTS'
				) {
					// Real check
					const key = Date.now().toString();
					notification.error({
						message: 'Produkten måste tas bort från alla varuautomater innan den kan raderas',
						placement: 'bottomRight',
						btn: (
							<Button type="primary" size="small" onClick={() => notification.destroy(key)}>
								OK
							</Button>
						),
						key,
						duration: null,
					});
					return;
				}
			}

			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
	};

	return (
		<TableActions
			onView={() => navigate(id.toString())}
			onEdit={() => navigate(`${id}/edit`)}
			onDelete={removeConfirmation}
		/>
	);
};

export default Actions;
