import { Typography } from 'antd';
import { format } from '../../helpers/currency';
import { PurchasedProduct } from '../models/event';

interface Props {
  products: PurchasedProduct[];
};

const PurchasedProducts = ({
  products,
}: Props) => {
  return (
    <div>
      <Typography.Title level={5}>Produkter</Typography.Title>
      <ul>
        {products.map((product, i) => <li key={i}><b>{product.name}</b> {format(product.price)}</li>)}
      </ul>
    </div>
  );
}

export default PurchasedProducts;
