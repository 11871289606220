import { Modal, notification } from 'antd';
import r from '../../http';
import { useContext } from 'react';
import TableActions from '../../components/table/Actions';
import { DocumentFile } from '@models/DocumentFile';
import DocumentContext from '../../state/Document';
import { useNavigate } from 'react-router-dom';

interface Props {
	id: number;
}

const DocumentActions = ({ id }: Props) => {
	const navigate = useNavigate();
	const { deleteDocument } = useContext(DocumentContext);

	const removeConfirmation = () => {
		Modal.warning({
			title: `Är du säker att du vill ta bort dokumentet?`,
			content: `Denna åtgärd går inte att ångra.`,
			okText: 'Ja, ta bort',
			okCancel: true,
			cancelText: 'Avbryt',
			onOk: remove,
		});
	};

	const remove = async () => {
		try {
			await r.delete<DocumentFile>(`/admin/documents/${id}`);
			deleteDocument(id);
			notification.success({
				message: `Dokument borttaget`,
				placement: 'bottomRight',
				duration: 5,
			});
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
	};

	return <TableActions onEdit={() => navigate(`${id}/edit`)} onDelete={removeConfirmation} />;
};

export default DocumentActions;
