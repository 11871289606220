import { Table, Divider, Input, Select } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import styles from './styles/VendingMachineEvents.module.scss';
import PageContainer from '../components/PageContainer';
import r from '../http';
import Separator from '../components/Separator';
import StatusColumn from './components/StatusColumn';
import DispatchResultColumn from './components/DispatchResultColumn';
import Event, { PurchaseStatus } from './models/event';
import PurchasedProducts from './components/PurchasedProducts';
import ListImage from '@components/table/ListImage';
import VendingMachineContext from '../state/VendingMachine';
import DateTime from '@helpers/date-time';
import AuthContext from '../state/Auth';
import Role from '../models/Role';

const { Search } = Input;

const getColumns = (): ColumnsType<Event> => [
	{
		title: 'Tid',
		dataIndex: 'createdAt',
		key: 'createdAt',
		render: (createdAt: string) => DateTime.fromISOUTC(createdAt).toFormat('YYYY-MM-DD HH:mm:ss'),
		width: 110,
	},
	{
		title: 'Identitetshandling',
		dataIndex: 'identificationImageUrl',
		key: 'identificationImageUrl',
		render: (imageUrl: string) => <ListImage src={imageUrl} />,
		width: 170,
	},
	{
		title: 'Personnummer',
		dataIndex: 'personalIdentificationNumber',
		key: 'personalIdentificationNumber',
		width: 110,
	},
	{
		title: 'Varuautomat',
		dataIndex: 'vendingMachineName',
		key: 'vendingMachineName',
		width: 170,
	},
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
		width: 100,
		render: (status: PurchaseStatus) => <StatusColumn status={status} />,
	},
	{
		title: 'Utmatning',
		dataIndex: 'productShipmentRequestFailed',
		key: 'productShipmentRequestFailed',
		width: 100,
		render: (productShipmentRequestFailed, event) => (
			<DispatchResultColumn success={!productShipmentRequestFailed} status={event.status} />
		),
	},
];

const PAGE_SIZE = 50;

const VendingMachineEvents = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [events, setEvents] = useState<Event[]>([]);
	const [searchTerm, setSearchTerm] = useState(searchParams.get('searchTerm') || '');
	const [isLoading, setIsLoading] = useState(false);
	const [totalItems, setTotalItems] = useState<number>();
	const [vendingMachineId, setVendingMachineId] = useState<number | undefined>(undefined);

	const { user } = useContext(AuthContext);
	const { vendingMachines } = useContext(VendingMachineContext);

	const isAdmin = [Role.Admin].some((role) => user?.roles.includes(role));

	const currentPage = parseInt(searchParams.get('pageNumber') || '1');

	useEffect(() => {
		const currentSearchParam = searchParams.get('vendingMachineId');
		const parsedSearchParam = currentSearchParam ? parseInt(currentSearchParam) : null;
		let newVendingId = undefined;
		if (parsedSearchParam && !isNaN(parsedSearchParam)) newVendingId = parsedSearchParam;
		else if (isAdmin) newVendingId = -1;
		else if (user && user.franchiseVendingMachineIds[0]) newVendingId = user.franchiseVendingMachineIds[0];

		setVendingMachineId(newVendingId);
		updateSearchParams({
			vendingMachineId: newVendingId !== undefined && newVendingId !== -1 ? newVendingId?.toString() : null,
			pageNumber: null,
		});
		//Update searchTerm accordingly
	}, [user]);

	const updateSearchParams = (newSearchParams: Record<string, string | null>) => {
		const updatedParams = new URLSearchParams(searchParams.toString());

		Object.entries(newSearchParams).forEach(([key, value]) => {
			if (value === null) {
				updatedParams.delete(key);
			} else {
				updatedParams.set(key, value);
			}
		});

		setSearchParams(updatedParams);
	};

	const fetchEvents = async () => {
		setIsLoading(true);
		try {
			let url = `/admin/vending-machine-events?pageSize=${PAGE_SIZE}&pageNumber=${currentPage}`;
			if (vendingMachineId && vendingMachineId !== -1) url += `&vendingMachineId=${vendingMachineId}`;

			if (searchTerm) url += `&searchTerm=${searchTerm}`;

			const response = await r.get<Event[]>(url.toString());
			const totalItemsHeader = response.headers.get('pagination-total-count');
			if (totalItemsHeader) setTotalItems(parseInt(totalItemsHeader));
			setEvents(response.data);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (!user) return;
		// If user is FranchiseAdmin, the vendingMachineId will be initialized to the first vending machine.
		// However, on the first render it will be undefined, which is OK if the user is an Admin, since
		// that equals to "fetch all vending machines". But for a FranchiseAdmin that is not a valid
		// input, therefore we want to avoid calling fetchEvents when the vendingMachineId is undefined
		const isAdmin = user.roles.includes(Role.Admin);
		if (!isAdmin && (!vendingMachineId || user.franchiseVendingMachineIds.length === 0)) return;
		fetchEvents();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vendingMachineId, currentPage]);

	const handleSearch = (value: string) => {
		updateSearchParams({ searchTerm: value, pageNumber: null });
		fetchEvents();
	};

	const handlePageChange = (pageNumber: number) => {
		updateSearchParams({ pageNumber: pageNumber.toString() });
	};

	const handleVendingMachineChange = (id: number) => {
		setVendingMachineId(id);
		updateSearchParams({ vendingMachineId: id === -1 ? null : id.toString(), pageNumber: null });
	};

	return (
		<PageContainer title="Automatköp">
			<div className={styles.actionBar}>
				<Search
					value={searchTerm}
					onChange={({ target }) => setSearchTerm(target.value)}
					size="large"
					placeholder="Sök efter personnummer"
					style={{ width: 400 }}
					loading={isLoading}
					onSearch={handleSearch}
				/>
				{user && (
					<>
						<Separator />
						<Select
							className={styles.storeSelector}
							value={vendingMachineId}
							onChange={handleVendingMachineChange}
							placeholder="Välj varuautomat"
							size="large"
							style={{ width: 200 }}
						>
							{
								<>
									{isAdmin ? <Select.Option value={-1}>Alla varuautomater</Select.Option> : <></>}
									{vendingMachines.map((vendingMachine) => (
										<Select.Option key={vendingMachine.id} value={vendingMachine.id}>
											{vendingMachine.name || (
												<span>
													ID: {vendingMachine.id} <i>(Namn saknas)</i>
												</span>
											)}
										</Select.Option>
									))}
								</>
							}
						</Select>
					</>
				)}
			</div>
			<Divider />
			<Table
				loading={isLoading}
				rowKey={(event) => event.createdAt}
				locale={{
					emptyText: 'Inga händelser',
					triggerDesc: 'Tryck för att sortera i fallande ordning',
					triggerAsc: 'Tryck för att sortera i stigande ordning',
					cancelSort: 'Tryck för att nollställa sortering',
				}}
				pagination={{
					showSizeChanger: false,
					position: ['bottomRight'],
					defaultPageSize: PAGE_SIZE,
					pageSize: PAGE_SIZE,
					total: totalItems,
					defaultCurrent: currentPage,
					current: currentPage,
					onChange: handlePageChange,
				}}
				scroll={{ x: '100%' }}
				expandable={{
					expandedRowRender: (event) => <PurchasedProducts products={event.products} />,
				}}
				size="middle"
				columns={getColumns()}
				dataSource={events}
			/>
		</PageContainer>
	);
};

export default VendingMachineEvents;
