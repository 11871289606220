import { useContext, useState } from 'react';
import StorageFileCategoryContext from '../state/StorageFileCategory';

const useStorageFileCategory = () => {
	const { categories, addCategory, editCategory, deleteCategory } = useContext(StorageFileCategoryContext);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => setIsModalOpen(true);

	const closeModal = () => setIsModalOpen(false);

	return {
		categories,
		isModalOpen,
		addCategory,
		editCategory,
		deleteCategory,
		openModal,
		closeModal,
	};
};

export default useStorageFileCategory;
