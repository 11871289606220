import React, { useMemo, useState } from 'react';
import Theft from '../models/Theft';

interface State {
	thefts: Theft[];
	setThefts: (thefts: Theft[]) => void;
	addTheft: (theft: Theft) => void;
	archiveTheft: (theft: Theft) => void;
}

const initialState: State = {
	thefts: [],
	setThefts: () => undefined,
	addTheft: () => undefined,
	archiveTheft: () => undefined,
};

const TheftContext = React.createContext<State>(initialState);

interface Props {
	children: React.ReactNode;
}

export const TheftContextProvider = ({ children }: Props) => {
	const [thefts, setThefts] = useState<Theft[]>([]);

	const value: State = useMemo(() => {
		return {
			thefts,
			setThefts,
			addTheft: (theft: Theft) => setThefts(thefts.concat(theft)),
			archiveTheft: (theft: Theft) => setThefts(thefts.map((t) => (t.id === theft.id ? theft : t))),
		};
	}, [thefts]);

	return <TheftContext.Provider value={value}>{children}</TheftContext.Provider>;
};

export default TheftContext;
