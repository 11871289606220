import { Modal, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import r from '../../../http';
import TableActions from '../../../components/table/Actions';
import { useContext } from 'react';
import UserContext from '../../../state/User';

interface Props {
	id: number;
}

const Actions = ({ id }: Props) => {
	const { deleteUser } = useContext(UserContext);
	const navigate = useNavigate();

	const removeConfirmation = () => {
		Modal.warning({
			title: `Är du säker att du vill ta bort användaren?`,
			content: `Om du tar bort användaren kommer den att loggas ut från alla sessioner.`,
			okText: 'Ja, ta bort',
			okCancel: true,
			cancelText: 'Avbryt',
			onOk: remove,
		});
	};

	const remove = async () => {
		try {
			await r.delete(`/admin/admin-users/${id}`);
			deleteUser(id);
			notification.success({
				message: `Användare borttagen`,
				placement: 'bottomRight',
				duration: 5,
			});
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
	};

	return (
		<TableActions
			onView={() => navigate(id.toString())}
			onEdit={() => navigate(`${id}/edit`)}
			onDelete={removeConfirmation}
		/>
	);
};

export default Actions;
