import styles from './styles/VendingMachinesScreen.module.scss';
import { VendingMachine } from '@models/VendingMachine';
import { Button, Divider, Input, Spin, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import VendingMachineActions from '../components/VendingMachineActions';
import { useContext, useState } from 'react';
import VendingMachineContext from '../../state/VendingMachine';
import { useNavigate } from 'react-router';
import PageContainer from '@components/PageContainer';
import { PlusOutlined } from '@ant-design/icons';
import StoreContext from '../../state/Store';
import { getTypeLabel } from '../utils';

const { Search } = Input;

const VendingMachinesScreen = () => {
	const [searchTerm, setSearchterm] = useState('');
	const { vendingMachines, isFetchingVendingMachinesStatus } = useContext(VendingMachineContext);
	const { stores } = useContext(StoreContext);

	const navigate = useNavigate();

	const displayedVendingMachines = vendingMachines.filter((vendingMachines) => {
		return vendingMachines.deviceId.toLowerCase().indexOf(searchTerm.toString().toLowerCase()) >= 0;
	});

	const columns: ColumnsType<VendingMachine> = [
		{
			title: 'Namn',
			key: 'name',
			dataIndex: 'name',
			ellipsis: true,
			width: 150,
			sorter: (a, b) => a.name.localeCompare(b.name),
		},
		{
			title: 'Typ',
			key: 'type',
			ellipsis: true,
			width: 150,
			sorter: (a, b) => a.type - b.type,
			render: (vendingMachine: VendingMachine) => getTypeLabel(vendingMachine.type),
		},
		{
			title: 'Butik',
			key: 'storeId',
			ellipsis: true,
			width: 150,
			sorter: (a, b) => a.storeId - b.storeId,
			render: (vendingMachine: VendingMachine) => stores.find((s) => s.id === vendingMachine.storeId)?.name || '-',
		},
		{
			title: 'Enhets ID',
			key: 'deviceId',
			dataIndex: 'deviceId',
			ellipsis: true,
			width: 150,
			sorter: (a, b) => a.deviceId.localeCompare(b.deviceId),
		},
		{
			title: 'Max köp per timme',
			key: 'maxProductsPerUserPerHour',
			dataIndex: 'maxProductsPerUserPerHour',
			width: 160,
			sorter: (a, b) => a.maxProductsPerUserPerHour - b.maxProductsPerUserPerHour,
		},
		{
			title: 'Status',
			key: 'online',
			dataIndex: 'online',
			width: 160,
			sorter: (a, b) => (a.online === b.online ? 0 : a.online ? -1 : 1),
			render: (_, vendingMachine) => {
				if (isFetchingVendingMachinesStatus) {
					return <Spin size="small" />;
				}

				let color;
				let label;
				switch (vendingMachine.online) {
					case null:
						color = 'default';
						label = 'Okänd';
						break;
					case true:
						color = 'success';
						label = 'Online';
						break;
					case false:
						color = 'error';
						label = 'Offline';
						break;
					default:
						color = 'default';
						label = 'Okänd';
						break;
				}

				return <Tag color={color}>{label}</Tag>;
			},
		},
		{
			width: 150,
			responsive: ['sm'],
			fixed: 'right',
			render: (_, vendingMachine: VendingMachine) => <VendingMachineActions id={vendingMachine.id} />,
		},
		{
			responsive: ['xs'],
			width: 50,
			fixed: 'right',
			render: (_, vendingMachine: VendingMachine) => <VendingMachineActions id={vendingMachine.id} />,
		},
	];

	return (
		<PageContainer
			title="Varuautomater"
			extra={
				<Button onClick={() => navigate('create')} type="primary" icon={<PlusOutlined />}>
					Skapa varuautomat
				</Button>
			}
		>
			<div className={styles.actionBar}>
				<Search
					value={searchTerm}
					onChange={(e) => setSearchterm(e.target.value)}
					size="large"
					placeholder="Sök efter enhets ID"
					style={{ width: 400 }}
				/>
			</div>
			<Divider />
			<Table
				rowKey={(vendingMachine) => vendingMachine.id}
				locale={{
					emptyText: 'Inga varuautomater',
					triggerDesc: 'Tryck för att sortera i fallande ordning',
					triggerAsc: 'Tryck för att sortera i stigande ordning',
					cancelSort: 'Tryck för att nollställa sortering',
				}}
				pagination={{ position: ['bottomRight'], defaultPageSize: 50 }}
				scroll={{ x: '100%' }}
				size="middle"
				columns={columns}
				dataSource={displayedVendingMachines}
			/>
		</PageContainer>
	);
};

export default VendingMachinesScreen;
