import React, { useContext, useMemo, useState } from 'react';
import Product from '../models/Product';
import Role from '../models/Role';
import useFetch from '@hooks/fetch';
import AuthContext from './Auth';

interface State {
	products: Product[];
	addProduct: (product: Product) => void;
	updateProduct: (product: Product) => void;
	deleteProduct: (id: number) => void;
}

const initialState: State = {
	products: [],
	addProduct: () => undefined,
	updateProduct: () => undefined,
	deleteProduct: () => undefined,
};

const ProductContext = React.createContext<State>(initialState);

interface Props {
	children: React.ReactNode;
}

export const ProductContextProvider = ({ children }: Props) => {
	const { user } = useContext(AuthContext);
	const [products, setProducts] = useState<Product[]>([]);

	const allowedRoles = [Role.Admin, Role.Maintainer];

	useFetch<Product[]>('/admin/products', (data) => setProducts(data), undefined, {
		enabled: user !== null && allowedRoles.some((role) => user.roles.includes(role)),
	});

	const value: State = useMemo(() => {
		return {
			products,
			addProduct: (product: Product) => setProducts(products.concat(product)),
			updateProduct: (product: Product) => setProducts(products.map((p) => (p.id === product.id ? product : p))),
			deleteProduct: (id: number) => setProducts(products.filter((p) => p.id !== id)),
		};
	}, [products]);

	return <ProductContext.Provider value={value}>{children}</ProductContext.Provider>;
};

export default ProductContext;
