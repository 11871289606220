import { useContext, useEffect, useState } from 'react';
import { Image, Button, Spin, Divider, Card, Row, Col, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import PageContainer from '../components/PageContainer';
import NewsArticleContext from '../state/NewsArticle';
import StoreContext from '../state/Store';
import NewsArticle from '../models/NewsArticle';
import r from '../http';

const { Text } = Typography;

const ViewNewsArticleScreen = () => {
	const { newsArticles } = useContext(NewsArticleContext);
	const [newsArticle, setNewsArticle] = useState<NewsArticle>();
	const { selectedStore } = useContext(StoreContext);
	const navigate = useNavigate();
	const id = useParams().id || '0';

	useEffect(() => {
		(async () => {
			const savedNewsArticle = newsArticles.find((newsArticle) => newsArticle.id === parseInt(id!));
			if (savedNewsArticle) {
				setNewsArticle(savedNewsArticle);
				return;
			}

			const { data } = await r.get<NewsArticle>(`/news/${id}`);
			setNewsArticle(data);
		})();
	}, []);

	const labelLayout = {
		md: 6,
		sm: 24,
		xs: 24,
	};

	const valueLayout = {
		md: 12,
		sm: 24,
		xs: 24,
	};

	return (
		<PageContainer
			title="Nyhet detaljer"
			back
			extra={[<Button key="edit" onClick={() => navigate('edit')} type="primary" icon={<EditOutlined />} />]}
			subTitle={selectedStore?.name}
		>
			<Spin spinning={!newsArticle} tip="Hämtar nyhet...">
				<Card>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Titel
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{newsArticle?.title}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Bild
							</Text>
						</Col>
						<Col {...valueLayout}>
							{newsArticle?.imageUrl !== '' ? (
								<Image height={150} src={newsArticle?.imageUrl} />
							) : (
								<Text>Bild saknas</Text>
							)}
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Beskrivning
							</Text>
						</Col>
						<Col {...valueLayout}>
							<pre>{newsArticle?.body}</pre>
						</Col>
					</Row>
				</Card>
			</Spin>
		</PageContainer>
	);
};

export default ViewNewsArticleScreen;
