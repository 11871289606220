import { PlusOutlined } from '@ant-design/icons';
import { StorageFileCategory } from '@models/DocumentFile';
import { Button, Card, Divider, Form, Input, Modal, notification, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import r from '../http';
import DocumentCategoryActions from '../documents/components/DocumentCategoryActions';
import { formLayout } from '../config/constants';

enum StorageFileCategoryModalView {
	Display,
	Form,
}

interface StorageFileCategoryModalProps {
	open: boolean;
	categories: StorageFileCategory[];
	addCategory: (x: StorageFileCategory) => void;
	editCategory: (x: StorageFileCategory) => void;
	removeCategory: (id: number) => void;
	onClose: () => void;
}

type StorageFileCategoryFormViewProps = Omit<
	StorageFileCategoryModalProps,
	'open' | 'onClose' | 'removeCategory' | 'categories'
> & {
	defaultValues: StorageFileCategory | undefined;
	setView: (view: StorageFileCategoryModalView) => void;
};

const StorageFileCategoryFormView = ({
	defaultValues,
	addCategory,
	editCategory,
	setView,
}: StorageFileCategoryFormViewProps) => {
	const [form] = Form.useForm();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const onSubmit = async (form: any) => {
		const body = new FormData();
		if (!form.name) return;

		body.append('name', form.name);

		try {
			setIsSubmitting(true);
			if (defaultValues) {
				body.append('id', defaultValues.id.toString());
				const response = await r.put<StorageFileCategory>(`/admin/documents/categories/${defaultValues.id}`, body);
				editCategory(response.data);
				notification.success({
					message: 'Kategori har uppdaterats!',
					placement: 'bottomRight',
					duration: 5,
				});
			} else {
				const response = await r.post<StorageFileCategory>(`/admin/documents/categories`, body);
				addCategory(response.data);
				notification.success({
					message: 'Kategori har lagts till!',
					placement: 'bottomRight',
					duration: 5,
				});
			}

			setView(StorageFileCategoryModalView.Display);
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
		setIsSubmitting(false);
	};

	return (
		<Form
			{...formLayout}
			labelAlign="left"
			form={form}
			size="large"
			onFinish={onSubmit}
			initialValues={{ name: defaultValues?.name }}
		>
			<Card>
				<Form.Item label="Namn" name="name" rules={[{ required: true, message: 'Vänligen ange namn' }]}>
					<Input placeholder="Namn" />
				</Form.Item>
			</Card>
			<div style={{ marginTop: '15px', display: 'flex', justifyContent: 'flex-end' }}>
				<Form.Item>
					<Space size="middle">
						<Button onClick={() => setView(StorageFileCategoryModalView.Display)} type="text">
							Avbryt
						</Button>
						<Button icon={<PlusOutlined />} type="primary" htmlType="submit" loading={isSubmitting}>
							{defaultValues ? 'Uppdatera' : 'Skapa'} kategori
						</Button>
					</Space>
				</Form.Item>
			</div>
		</Form>
	);
};

type DisplayStorageFileCategoryViewProps = Omit<
	StorageFileCategoryModalProps,
	'open' | 'onClose' | 'addCategory' | 'editCategory'
> & {
	setView: (view: StorageFileCategoryModalView) => void;
	navigateEdit: (category: StorageFileCategory) => void;
};

const DisplayStorageFileCategoryView = ({
	categories,
	setView,
	navigateEdit,
	removeCategory,
}: DisplayStorageFileCategoryViewProps) => {
	const [searchTerm, setSearchterm] = useState('');
	const filteredCategories = categories.filter((p) => p.name.toLowerCase().includes(searchTerm.toLowerCase()));

	const columns: ColumnsType<StorageFileCategory> = [
		{
			title: 'Namn',
			dataIndex: 'name',
		},
		{
			width: 150,
			responsive: ['sm'],
			fixed: 'right',
			render: (_, category: StorageFileCategory) => (
				<DocumentCategoryActions
					id={category.id}
					editCategory={() => navigateEdit(category)}
					removeCategory={removeCategory}
				/>
			),
		},
		{
			responsive: ['xs'],
			width: 50,
			fixed: 'right',
			render: (_, category: StorageFileCategory) => (
				<DocumentCategoryActions
					id={category.id}
					editCategory={() => navigateEdit(category)}
					removeCategory={removeCategory}
				/>
			),
		},
	];

	return (
		<Card>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Input.Search
					value={searchTerm}
					onChange={(e) => setSearchterm(e.target.value)}
					placeholder="Sök efter namn"
					style={{ width: 240 }}
				/>
				<Button type="primary" onClick={() => setView(StorageFileCategoryModalView.Form)}>
					<PlusOutlined /> Skapa kategori
				</Button>
			</div>
			<Divider />
			<Table
				rowKey={(x) => x.id}
				locale={{
					emptyText: 'Inga kategorier',
					triggerDesc: 'Tryck för att sortera i fallande ordning',
					triggerAsc: 'Tryck för att sortera i stigande ordning',
					cancelSort: 'Tryck för att nollställa sortering',
				}}
				pagination={{ position: ['bottomRight'], defaultPageSize: 50 }}
				scroll={{ x: '100%' }}
				size="middle"
				columns={columns}
				dataSource={filteredCategories}
			/>
		</Card>
	);
};

const StorageFileCategoryModal = ({
	open,
	categories,
	addCategory,
	editCategory,
	removeCategory,
	onClose,
}: StorageFileCategoryModalProps) => {
	const [view, setView] = useState<StorageFileCategoryModalView>(StorageFileCategoryModalView.Display);
	const [selectedCategory, setSelectedCategory] = useState<StorageFileCategory | undefined>(undefined);

	const navigateEdit = (category: StorageFileCategory) => {
		setSelectedCategory(category);
		setView(StorageFileCategoryModalView.Form);
	};

	// Reset selected category on back.
	useEffect(() => {
		if (view === StorageFileCategoryModalView.Display && selectedCategory) {
			setSelectedCategory(undefined);
		}
	}, [view, selectedCategory]);

	return (
		<Modal
			title="Dokument kategorier"
			centered
			open={open}
			onCancel={onClose}
			width={1000}
			styles={{
				body: {
					height: '80vh',
				},
			}}
			closeIcon
			footer={<Button onClick={onClose}>Stäng</Button>}
		>
			{view === StorageFileCategoryModalView.Display ? (
				<DisplayStorageFileCategoryView
					categories={categories}
					setView={setView}
					navigateEdit={navigateEdit}
					removeCategory={removeCategory}
				/>
			) : (
				<StorageFileCategoryFormView
					setView={setView}
					addCategory={addCategory}
					editCategory={editCategory}
					defaultValues={selectedCategory}
				/>
			)}
		</Modal>
	);
};

export default StorageFileCategoryModal;
