import PageContainer from '@components/PageContainer';
import { Card, Divider, notification, Spin, Typography } from 'antd';
import {
	FilePdfOutlined,
	FileOutlined,
	FileJpgOutlined,
	FileImageOutlined,
	FileWordOutlined,
	FilePptOutlined,
	Html5Outlined,
	FileTextOutlined,
	FileExcelOutlined,
	FileGifOutlined,
	VideoCameraOutlined,
	InboxOutlined,
} from '@ant-design/icons';
import { DocumentFile } from '@models/DocumentFile';
import { useContext } from 'react';
import DocumentContext from '../state/Document';
import useFetch from '@hooks/fetch';
import styles from './styles/ViewDocumentScreen.module.scss';
import useSearchFilter from '@hooks/search-filter';
import useFilterCondition from '@hooks/filter-condition';
import ActionBar, { SearchBar, SelectBar } from '@components/action-bar/ActionBar';
import StorageFileCategoryContext from '../state/StorageFileCategory';

interface DocumentItemProps {
	doc: DocumentFile;
	// openDocument: () => void;
}

const iconMap: Record<string, React.ComponentType> = {
	bmp: FileImageOutlined,
	doc: FileWordOutlined,
	docx: FileWordOutlined,
	htm: Html5Outlined,
	html: Html5Outlined,
	jpg: FileJpgOutlined,
	jpeg: FileJpgOutlined,
	pdf: FilePdfOutlined,
	png: FileImageOutlined,
	ppt: FilePptOutlined,
	pptx: FilePptOutlined,
	tiff: FileImageOutlined,
	txt: FileTextOutlined,
	xls: FileExcelOutlined,
	xlsx: FileExcelOutlined,
	other: FileOutlined,
	csv: FileExcelOutlined,
	odt: FileWordOutlined,
	gif: FileGifOutlined,
	webp: FileImageOutlined,
	mp4: VideoCameraOutlined,
};

/* 
interface PreviewModal {
	open: boolean;
	document: IDocument | undefined;
	onClose: () => void;
}

const PreviewModal = ({ open, document, onClose }: PreviewModal) => {
	return (
		<Modal
			title="Visa dokument"
			centered
			open={open}
			onCancel={onClose}
			width={1000}
			styles={{
				body: {
					height: '80vh',
				},
			}}
			closeIcon
			footer={<Button onClick={onClose}>Stäng</Button>}
		>
			<DocViewer
				documents={document ? [document] : []}
				pluginRenderers={DocViewerRenderers}
				style={{ width: 500, height: 500 }}
			/>
		</Modal>
	);
};
*/

const DocumentItem = ({ doc }: DocumentItemProps) => {
	const fileType = doc.fileType;
	const IconComponent: any = iconMap[fileType] || iconMap.other;

	const downloadFile = () => {
		if (doc?.url) {
			const link = document.createElement('a');
			link.href = doc.url;
			link.download = doc.name;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} else {
			console.error('Document URL is not available');
		}
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: '130px',
				background: '#fff',
				border: '1px solid #ddd',
				borderRadius: '8px',
				boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
				overflow: 'hidden',
				cursor: 'pointer',
			}}
			onClick={downloadFile}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100px',
					background: '#f5f5f5',
				}}
			>
				<IconComponent style={{ fontSize: '48px', color: '#4285f4' }} />
			</div>
			<div
				style={{
					padding: '8px 12px',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					textAlign: 'center',
				}}
			>
				<Typography
					style={{
						fontSize: '14px',
						color: '#333',
						whiteSpace: 'nowrap',
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						width: '100%',
					}}
					title={doc.name}
				>
					{doc.name}
				</Typography>
			</div>
		</div>
	);
};

const ViewDocumentsScreen = () => {
	const { documents, setDocuments } = useContext(DocumentContext);
	const { categories } = useContext(StorageFileCategoryContext);
	// const [previewModalIsOpen, setPreviewModalIsOpen] = useState<boolean>(false);
	// const [selectedDocument, setSelectedDocument] = useState<IDocument | undefined>();

	const { filteredData: searchResult, searchTerm, setSearchTerm } = useSearchFilter(documents, ['name']);
	const { filteredData, filterValue, setFilterValue } = useFilterCondition(searchResult, 'category', 'id');

	const { isFetching } = useFetch<DocumentFile[]>(`/admin/documents`, setDocuments, () => {
		notification.error({
			message: 'Vi kunde inte hämta dokumenten. Vänligen försök igen.',
			placement: 'bottomRight',
			duration: 10,
		});
	});

	/* 
	const onDocClick = (doc: IDocument) => {
		setSelectedDocument({ uri: doc.uri, fileName: doc.fileName, fileType: doc.fileType });
		setPreviewModalIsOpen(true);
	*/

	return (
		<PageContainer title="Dokument" subTitle={`${filteredData.length} st`}>
			<Spin spinning={isFetching}>
				<ActionBar>
					<SearchBar placeholder="Sök efter namn" searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
					<SelectBar
						options={categories}
						valueKey="id"
						labelKey="name"
						placeholder="Kategori"
						value={filterValue}
						setValue={setFilterValue}
						allowClear
					/>
				</ActionBar>
				<Divider />
				<Card style={{ marginBottom: '16px' }}>
					{documents.length > 0 ? (
						<>
							{categories.map((category) => {
								const categoryDocuments = filteredData.filter((doc: DocumentFile) => doc.category.id === category.id);

								return (
									<div key={category.id} className={styles.categoryContainer}>
										<Typography style={{ fontSize: '16px', marginBottom: '12px' }}>
											{category.name} ({categoryDocuments.length} st)
										</Typography>
										<div className={styles.documentContainer}>
											{categoryDocuments.map((document: DocumentFile) => (
												<DocumentItem
													key={document.id}
													doc={document}
													// openDocument={() => onDocClick({ uri: document.url, fileName: document.name, fileType: document.fileType })}
												/>
											))}
										</div>
									</div>
								);
							})}
						</>
					) : (
						<div className={styles.noDocuments}>
							<InboxOutlined style={{ fontSize: '48px', color: '#1677ff' }} />
							<Typography>Inga dokument</Typography>
						</div>
					)}
				</Card>
			</Spin>
			{/*
			<PreviewModal
				open={previewModalIsOpen}
				document={selectedDocument}
				onClose={() => setPreviewModalIsOpen(false)}
			/>
			*/}
		</PageContainer>
	);
};

export default ViewDocumentsScreen;
