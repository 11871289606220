export const priceLabelConstantsStoreOffer = {
	top: -365,
	left: -520,
	right: 150,
	bottom: 120,
	min: 0.2,
	max: 1.0,
	defaultScale: 0.6,
	defaultOffsetX: 0,
	defaultOffsetY: 0,
};
export const priceLabelConstants4inA4 = {
	top: -150,
	left: 0,
	right: 190,
	bottom: 25,
	min: 0.2,
	max: 0.55,
	defaultScale: 0.3,
	defaultOffsetX: 0,
	defaultOffsetY: 0,
};
