import React, { useMemo, useState } from 'react';
import Announcement from '../models/Announcement';

interface State {
	announcements: Announcement[];
	setAnnouncements: (announcements: Announcement[]) => void;
	addAnnouncement: (announcement: Announcement) => void;
	updateAnnouncement: (announcement: Announcement) => void;
	deleteAnnouncement: (id: number) => void;
}

const initialState: State = {
	announcements: [],
	setAnnouncements: () => undefined,
	addAnnouncement: () => undefined,
	updateAnnouncement: () => undefined,
	deleteAnnouncement: () => undefined,
};

const AnnouncementContext = React.createContext<State>(initialState);

interface Props {
	children: React.ReactNode;
}

export const AnnouncementContextProvider = ({ children }: Props) => {
	const [announcements, setAnnouncements] = useState<Announcement[]>([]);

	const value: State = useMemo(() => {
		return {
			announcements,
			setAnnouncements: (announcements: Announcement[]) => setAnnouncements(announcements),
			addAnnouncement: (announcement: Announcement) => setAnnouncements(announcements.concat(announcement)),
			updateAnnouncement: (announcement: Announcement) =>
				setAnnouncements(announcements.map((a) => (a.id === announcement.id ? announcement : a))),
			deleteAnnouncement: (id: number) => setAnnouncements(announcements.filter((a) => a.id !== id)),
		};
	}, [announcements]);

	return <AnnouncementContext.Provider value={value}>{children}</AnnouncementContext.Provider>;
};

export default AnnouncementContext;
