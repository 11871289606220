import { useContext, useEffect, useState } from 'react';
import { Image, Button, Spin, Divider, Card, Row, Col, Typography, Tag } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import PageContainer from '../components/PageContainer';
import OfferContext from '../state/Offer';
import { format } from '../helpers/currency';
import StoreContext from '../state/Store';
import Offer, { OfferType } from '../models/Offer';
import r from '../http';
import DateTime from '@helpers/date-time';
import { getExpirationDateTagColor } from './OffersScreen';
import dayjs from 'dayjs';

const { Text } = Typography;

const ViewOfferScreen = () => {
	const { stores } = useContext(StoreContext);
	const { offers } = useContext(OfferContext);
	const navigate = useNavigate();
	const id = useParams().id!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
	const [offer, setOffer] = useState<Offer>();

	useEffect(() => {
		(async () => {
			const savedOffer = offers.find((offer) => offer.id === parseInt(id));
			if (savedOffer) {
				setOffer(savedOffer);
				return;
			}

			const { data } = await r.get<Offer>(`/admin/offers/${id}`);
			setOffer(data);
		})();
	}, [id, offers]);

	const labelLayout = {
		md: 6,
		sm: 24,
		xs: 24,
	};

	const valueLayout = {
		md: 12,
		sm: 24,
		xs: 24,
	};

	return (
		<PageContainer
			title="Erbjudande detaljer"
			back
			extra={[<Button key="edit" onClick={() => navigate('edit')} type="primary" icon={<EditOutlined />} />]}
		>
			<Spin spinning={!offer} tip="Hämtar erbjudande...">
				<Card>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Butiker
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>
								{offer?.storeIds
									.map((storeId) => stores.find((s) => s.id === storeId)?.name)
									.filter((store) => !!store)
									.join(', ')}
							</Text>
						</Col>
					</Row>
					<Divider />
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Titel
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{offer?.title}</Text>
						</Col>
					</Row>
					<Divider />
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Bild
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Image height={150} src={offer?.imageUrl} />
						</Col>
					</Row>
					<Divider />
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Pris
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>
								{offer &&
									(() => {
										switch (offer.type) {
											case OfferType.DiscountedPrice:
												return format(offer.price);

											case OfferType.QuantityForPrice:
												return `${offer.quantity} för ${format(offer.price)}`;

											case OfferType.QuantityForQuantity:
												return `${offer.quantity} för ${offer.quantityOffered}`;

											default:
												return '';
										}
									})()}
							</Text>
						</Col>
					</Row>
					<Divider />
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Visa när man öppnar butik
							</Text>
						</Col>
						<Col {...valueLayout}>
							{offer?.showOnStoreOpen ? <Tag color="success">Ja</Tag> : <Tag color="default">Nej</Tag>}
						</Col>
					</Row>
					<Divider />
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Giltigt till och med
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Tag color={getExpirationDateTagColor(offer?.expirationDate)}>{`${
								offer?.expirationDate ? dayjs(offer.expirationDate).format('YYYY-MM-DD') : 'Tillsvidare'
							}`}</Tag>
						</Col>
					</Row>
					<Divider />
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Skapad
							</Text>
						</Col>
						<Col {...valueLayout}>
							{offer && <Text>{DateTime.fromISOUTC(offer.createdAt).toFormat('YYYY-MM-DD HH:mm')}</Text>}
						</Col>
					</Row>
					<Divider />
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Uppdaterad
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{offer?.updatedAt ? DateTime.fromISOUTC(offer?.updatedAt).toFormat('YYYY-MM-DD HH:mm') : '-'}</Text>
						</Col>
					</Row>
				</Card>
			</Spin>
		</PageContainer>
	);
};

export default ViewOfferScreen;
