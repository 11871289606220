import { StorageKey, getStorageItem } from '@helpers/storage';
import config from '../config';
import { RequestError } from './exceptions';
import type { Body, RequestResponse } from './models';
import { Token } from '@models/Token';

class HTTPClient {
	private prepareBody(body?: Body) {
		if (!body) return undefined;

		if (body instanceof FormData) {
			return body;
		}

		return JSON.stringify(body);
	}

	private async request(method: string, url: string, body?: Body) {
		const headers = new Headers();
		headers.append('Accept', 'application/json');

		if (!(body instanceof FormData)) {
			headers.append('Content-Type', 'application/json');
		}

		const token = getStorageItem<Token>(StorageKey.AuthToken);

		if (token) {
			headers.append('Authorization', `Bearer ${token.accessToken}`);
		}
		if (!(body instanceof FormData)) {
			headers.append('Content-Type', 'application/json');
		}

		const finalUrl = config.apiUrl + url;
		let response: Response;
		try {
			response = await fetch(finalUrl, {
				method,
				body: this.prepareBody(body),
				headers,
			});
		} catch (e) {
			throw new RequestError(`Unable to make request to: ${finalUrl}`);
		}

		if (!response.ok) {
			let data = null;
			try {
				data = await response.json();
			} catch (e) {}

			throw new RequestError(`Request failed with status code ${response.status}`, {
				status: response.status,
				data,
			});
		}

		return response;
	}

	private async createResponse<T = unknown>(response: Response) {
		let data = null;
		try {
			data = await response.json();
		} catch (e) {}

		return {
			status: response.status,
			headers: response.headers,
			data,
		} as RequestResponse<T>;
	}

	async get<T = unknown>(url: string): Promise<RequestResponse<T>> {
		const response = await this.request('GET', url);
		return await this.createResponse<T>(response);
	}

	async post<T = unknown>(url: string, body?: Body): Promise<RequestResponse<T>> {
		const response = await this.request('POST', url, body);
		return await this.createResponse<T>(response);
	}

	async put<T = unknown>(url: string, body: Body): Promise<RequestResponse<T>> {
		const response = await this.request('PUT', url, body);
		return await this.createResponse<T>(response);
	}

	async patch<T = unknown>(url: string, body: Body): Promise<RequestResponse<T>> {
		const response = await this.request('PATCH', url, body);
		return await this.createResponse<T>(response);
	}

	async delete<T = unknown>(url: string, body?: Body): Promise<RequestResponse<T>> {
		const response = await this.request('DELETE', url, body);
		return await this.createResponse<T>(response);
	}
}

const r = new HTTPClient();

export default r;

export const objectToFormData = (obj: Record<string, unknown>) => {
	const data = new FormData();

	Object.entries(obj).forEach(([key, value]) => {
		if (!value) return;

		if (typeof value === 'string') {
			data.append(key, value);
		} else if (value instanceof Blob) {
			data.append(key, value);
		} else if (typeof value === 'number') {
			data.append(key, value.toString());
		} else if (typeof value === 'boolean') {
			data.append(key, value.toString());
		} else if (Array.isArray(value)) {
			value.forEach((entry) => {
				data.append(`${key}[]`, entry);
			});
		} else {
			throw new Error(`Data type ${typeof value} not implemented`);
		}
	});

	return data;
};
