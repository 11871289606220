export const styleContent = `
@font-face {
  font-family: Glasgow-Regular;
  src: url(assets/fonts/glasgow/Glasgow-Regular.woff);
}
@font-face {
  font-family: Glasgow-DemiBold;
  src: url(assets/fonts/glasgow/Glasgow-DemiBold.woff);
}
@font-face {
  font-family: Glasgow-ExtraBold;
  src: url(assets/fonts/glasgow/Glasgow-ExtraBold.woff);
}

html,
body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
}

.preview {
  position: relative;
  height: 100%;
  width: 100%;
}

.pt-four-print {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
}

.pt-four-offer {
  position: relative;
  overflow: hidden;
}

.pt-four-banner {
  background: #db421a;
  width: calc(100% + 100px);
  margin-left: -10px;
  margin-top: -45px;
  height: 230px;
  transform: rotate(6.5deg);
  position: absolute;
  z-index: 0;
}
.pt-four-banner-container {
  position: absolute;
  margin-bottom: -20px;
  margin-left: 50px;
  margin-top: 36px;
  width: 120mm;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.pt-four-logo {
  height: 24px;
  left: 0px;
  margin-bottom: -2px;
}

.pt-four-title {
  line-height: 70px;
  font-family: 'Glasgow-ExtraBold', sans-serif;
  text-transform: uppercase;
  margin: 0;
  color: #ffffff;
}

.pt-four-content {
  max-width: 455px;
  margin-left: 50px;
  position: absolute;
  bottom: 20px;
}

.pt-four-product {
  font-family: 'Glasgow-DemiBold', sans-serif;
  font-size: 28px;
  color: #000;
  margin: 0;
}

.pt-four-extra {
  font-family: 'Glasgow-Regular', sans-serif;
  font-size: 16px;
  color: #000;
  margin: 0;
  line-height: 18px;
}

.pt-four-price-container-2 {
  position: absolute;
  left: 275px;
  bottom: -100px;
  display: flex;
  width: 100%;
  transform: scale(0.5);
}
`;
