import { Modal, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import r from '../../http';
import { useContext } from 'react';
import TableActions from '../../components/table/Actions';
import AnnouncementContext from '../../state/Announcement';
import Announcement from '../../models/Announcement';

interface Props {
	id: number;
}

const AnnouncementActions = ({ id }: Props) => {
	const navigate = useNavigate();
	const { deleteAnnouncement } = useContext(AnnouncementContext);

	const removeConfirmation = () => {
		Modal.warning({
			title: `Är du säker att du vill ta bort meddelandet?`,
			content: `Om du tar bort meddelandet kommer det inte längre kunna visas i appen.`,
			okText: 'Ja, ta bort',
			okCancel: true,
			cancelText: 'Avbryt',
			onOk: remove,
		});
	};

	const remove = async () => {
		try {
			await r.delete<Announcement>(`/admin/announcements/${id}`);
			deleteAnnouncement(id);
			notification.success({
				message: `Meddelande borttaget`,
				placement: 'bottomRight',
				duration: 5,
			});
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
	};

	return (
		<TableActions
			onView={() => navigate(id.toString())}
			onEdit={() => navigate(`${id}/edit`)}
			onDelete={removeConfirmation}
		/>
	);
};

export default AnnouncementActions;
