import React, { useContext, useEffect, useMemo, useState } from 'react';
import { firestore } from '../firebase';
import { requestPermission, sendNotification } from '../helpers/notification';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import { playStoreOpenSound } from '../functions/playNotificationSound';
import FirebaseAuthContext, { AuthStatus as FirebaseAuthStatus } from './FirebaseAuth';
import { StoreUnlockEvent } from '../models/StoreUnlockEvent';
import { ShopOutlined } from '@ant-design/icons';
import { green } from '@ant-design/colors';
import dayjs from 'dayjs';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { localSettingsKey, LocalSettingsStored } from '../settings/SettingsScreen';
import usePermissions from '@hooks/permissions';

interface State {
	events: StoreUnlockEvent[];
}

const initialState: State = {
	events: [],
};

const EventContext = React.createContext<State>(initialState);

interface Props {
	children: React.ReactNode;
}

export const EventContextProvider = ({ children }: Props) => {
	const [events, setEvents] = useState<StoreUnlockEvent[]>(initialState.events); // Not used atm. since we don't store events locally
	const { isAdminOrMaintainer } = usePermissions();
	const { status: firebaseAuthStatus } = useContext(FirebaseAuthContext);
	const navigate = useNavigate();

	const notifyUser = async (storeName: string) => {
		if (!isWorkingHours()) return;

		playStoreOpenSound();

		const browserNotification = sendNotification(`Kund öppnade ${storeName}!`);
		if (browserNotification) {
			browserNotification.onclick = () => {
				browserNotification.close();
				navigate('events');
			};

			return;
		}

		const id = Date.now().toString();
		notification.info({
			key: id,
			message: (
				<>
					Kund öppnade <b>{storeName}</b>!
				</>
			),
			duration: 10,
			description: '',
			placement: 'topRight',
			icon: (
				<div style={{ color: green.primary }}>
					<ShopOutlined />
				</div>
			),
			onClick: () => {
				navigate('events');
				notification.destroy(id);
			},
		});
	};

	const isWorkingHours = () => {
		const swedishNow = new Date().toLocaleString('sv-SE', { timeZone: 'Europe/Stockholm' });
		const nowHour = new Date(swedishNow).getHours();

		const fromHour = 23;
		const toHour = 6;

		// Try to load local settings, if non-exist, use default values.
		/*
		try {
			const settingsString = localStorage.getItem(localSettingsKey);
			if (settingsString) {
				const localSettings: LocalSettingsStored | null = JSON.parse(settingsString);
				if (localSettings && localSettings.notifyOnStoreOpen) {
					fromHour = dayjs(localSettings.activeInterval[0]).get('hour');
					toHour = dayjs(localSettings.activeInterval[1]).get('hour');
				}
				return false; // If the setting is toggled off.
			}
		} catch (e) {
			console.log('Could not load local settings, running with default configuration.');
		}
		*/

		if (fromHour < toHour) {
			return nowHour >= fromHour && nowHour < toHour;
		} else {
			// When the time span is spanning over 00:00
			return nowHour >= fromHour || nowHour < toHour;
		}
	};

	useEffect(() => {
		if ([FirebaseAuthStatus.Pending, FirebaseAuthStatus.SignedOut].includes(firebaseAuthStatus)) return;

		if (!isAdminOrMaintainer) return;

		const fromUnix = dayjs().unix();

		const q = query(
			collection(firestore, 'events'),
			where('timestamp', '>', fromUnix),
			where('type', '==', 'open_store')
		);

		const unsubscribe = onSnapshot(q, (querySnapshot) => {
			querySnapshot.forEach((doc) => {
				notifyUser(doc.data().data.storeName);
			});
		});

		return unsubscribe;
	}, [isAdminOrMaintainer, firebaseAuthStatus]);

	useEffect(() => {
		requestPermission();
	}, []);

	const value: State = useMemo(() => {
		return {
			events,
		};
	}, [events]);

	return <EventContext.Provider value={value}>{children}</EventContext.Provider>;
};

export default EventContext;
