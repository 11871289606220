export async function requestPermission() {
  if (!('Notification' in window)) return;

  const hasRequestedNotificationPermission = localStorage.getItem('notificationPermissionRequested');

  if (hasRequestedNotificationPermission) return;

  const permission = await Notification.requestPermission();
  localStorage.setItem('notificationPermissionRequested', 'true');
  return permission;
}

export function requestPermissionForce() {
  if (!('Notification' in window)) return;

  return Notification.requestPermission();
}

export function sendNotification(message: string, options?: NotificationOptions): Notification | undefined {
  if (!('Notification' in window)) return;

  if (Notification.permission !== 'granted') {
    return;
  }

  let notification: Notification | undefined = undefined;

  try {
    // Notification is not available in some mobile browsers
    notification = new Notification(message, options);
  } catch (e) {}

  return notification;
}
