import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
	apiKey: 'AIzaSyCanGpKuXcfkjFVJt25KIl3zaNmgNIfAoc',
	authDomain: 'food-dev-972af.firebaseapp.com',
	databaseURL: 'https://food-dev-972af.firebaseio.com',
	projectId: 'food-dev-972af',
	storageBucket: 'food-dev-972af.appspot.com',
	messagingSenderId: '922483531091',
	appId: '1:922483531091:web:bcededa221422a254815a6',
	measurementId: 'G-WRJ0WR5X0P',
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const firestore = getFirestore(app);

export const database = getDatabase(app);
