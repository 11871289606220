import { Button, Card, Divider, Form, Input, Modal, notification, Space, Upload, UploadFile } from 'antd';
import { PlusOutlined, SendOutlined } from '@ant-design/icons';
import { useContext, useState } from 'react';
import PageContainer from '../components/PageContainer';
import styles from './styles/ReportFaultScreen.module.scss';
import StoreSelector from '../components/StoreSelector';
import FaultReportContext from '../state/FaultReport';
import { useNavigate } from 'react-router-dom';
import r from '../http';
import FaultReport from '../models/FaultReport';
import { formLayout } from '../config/constants';
import useStoreSelector from '@hooks/store-selector';

const { TextArea } = Input;

const ReportFaultScreen = () => {
	const { addFaultReport } = useContext(FaultReportContext);
	const { stores, selectedStore, setSelectedStore } = useStoreSelector(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [form] = Form.useForm();

	const navigate = useNavigate();

	const submit = async (formValues: any) => {
		if (selectedStore === null) return;

		try {
			setIsSubmitting(true);
			const body = new FormData();
			body.append('storeId', selectedStore.toString());
			body.append('information', formValues.information ?? '');

			if (formValues.image) {
				formValues.image.fileList.map((i: UploadFile) => {
					if (i.originFileObj === undefined) return;
					body.append('images', i.originFileObj);
				});
			}

			const response = await r.post<FaultReport>('/admin/fault-reports', body);
			addFaultReport(response.data);
			notification.success({
				message: 'Felanmälan skapad!',
				placement: 'bottomRight',
				duration: 5,
			});
			navigate(`/fault-reports?storeId=${selectedStore}`);
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
		setIsSubmitting(false);
	};

	const confirm = (fromValues: any) => {
		Modal.confirm({
			title: 'Skicka anmälan',
			content: 'Är du säker på att du vill skicka in anmälan?',
			okText: 'Ja, skicka anmälan',
			cancelText: 'Avbryt',
			onOk: () => submit(fromValues),
		});
	};

	return (
		<PageContainer back={'/fault-reports'} title="Skapa felanmälan">
			<Form
				{...formLayout}
				initialValues={{ store: selectedStore }}
				labelAlign="left"
				form={form}
				size="large"
				onFinish={confirm}
			>
				<Card>
					<Form.Item label="Butik" name="store" rules={[{ required: true, message: 'Vänligen välj en butik' }]}>
						<StoreSelector stores={stores} selectedStore={selectedStore} onChange={setSelectedStore} />
					</Form.Item>
					<Divider />
					<Form.Item
						label="Information"
						name="information"
						rules={[{ required: true, message: 'Vänligen ange information' }]}
					>
						<TextArea placeholder="Information" />
					</Form.Item>
					<Divider />
					<Form.Item label="Bilder" name="image" valuePropName="files" help="Max 10 bilder">
						<Upload
							accept="image/*"
							listType="picture-card"
							multiple={true}
							beforeUpload={() => false}
							onRemove={() => {
								requestAnimationFrame(() => {
									form.validateFields(['image']);
								});
								return true;
							}}
							previewFile={async (file) => URL.createObjectURL(file)}
							maxCount={10}
						>
							<div>
								<PlusOutlined />
								<div style={{ marginTop: 8 }}>Välj bild</div>
							</div>
						</Upload>
					</Form.Item>
				</Card>
				<div className={styles.actions}>
					<Form.Item>
						<Space size="middle">
							<Button type="primary" htmlType="submit" loading={isSubmitting}>
								Skicka anmälan <SendOutlined />
							</Button>
						</Space>
					</Form.Item>
				</div>
			</Form>
		</PageContainer>
	);
};

export default ReportFaultScreen;
