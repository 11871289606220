import React, { useMemo, useState } from 'react';
import Discount from '../models/Discount';

interface State {
	discounts: Discount[];
	setDiscounts: (discounts: Discount[]) => void;
	addDiscount: (discount: Discount) => void;
	updateDiscount: (discount: Discount) => void;
	deleteDiscount: (id: number) => void;
}

const initialState: State = {
	discounts: [],
	setDiscounts: () => undefined,
	addDiscount: () => undefined,
	updateDiscount: () => undefined,
	deleteDiscount: () => undefined,
};

const DiscountContext = React.createContext<State>(initialState);

interface Props {
	children: React.ReactNode;
}

export const DiscountContextProvider = ({ children }: Props) => {
	const [discounts, setDiscounts] = useState<Discount[]>([]);

	const value: State = useMemo(() => {
		return {
			discounts,
			setDiscounts: (Discounts: Discount[]) => setDiscounts(Discounts),
			addDiscount: (Discount: Discount) => setDiscounts(discounts.concat(Discount)),
			updateDiscount: (Discount: Discount) => setDiscounts(discounts.map((o) => (o.id === Discount.id ? Discount : o))),
			deleteDiscount: (id: number) => setDiscounts(discounts.filter((o) => o.id !== id)),
		};
	}, [discounts]);

	return <DiscountContext.Provider value={value}>{children}</DiscountContext.Provider>;
};

export default DiscountContext;
