import { Modal } from 'antd';
import TableActions from '../../../components/table/Actions';
import { useNavigate } from 'react-router-dom';

interface Props {
	id: number;
	identifier: string;
	onDelete: () => void;
}

const BannedUserActions = ({ id, identifier, onDelete }: Props) => {
	const navigate = useNavigate();

	const unbanUserConfirm = () => {
		Modal.warning({
			title: `Ta bort spärrat konto`,
			content: `Är du säker på att du vill ta bort ${identifier} från spärrade konton?`,
			okText: 'Ta bort',
			okCancel: true,
			cancelText: 'Avbryt',
			onOk: onDelete,
		});
	};

	return (
		<TableActions onView={() => navigate(`${id}`)} onEdit={() => navigate(`${id}/edit`)} onDelete={unbanUserConfirm} />
	);
};

export default BannedUserActions;
