import notificationSound from '../assets/audio/notification.wav';
import { Howl } from 'howler';

export const initSound = () => {
	const sound = new Howl({
		src: [notificationSound],
		autoplay: false,
		loop: false,
		volume: 0.0,
	});
	sound.play();
};
