import { Tag } from 'antd';
import { PurchaseStatus } from '../models/event';

const StatusColumn = ({ status }: { status: PurchaseStatus }) => {
  const color = status === PurchaseStatus.Accepted ? 'green' : 'volcano';
  const message = status === PurchaseStatus.Accepted ? 'Godkänd' : 'Nekad';

  return <Tag color={color}>{message}</Tag>;
};

export default StatusColumn;
