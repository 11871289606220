import { Button, Card, Divider, Form, Input, Space, Select, notification } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useContext, useEffect, useState } from 'react';
import StoreContext from '../state/Store';
import styles from './styles/CreateDiscountScreen.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import r from '../http';
import Discount from '../models/Discount';
import DiscountContext from '../state/Discount';
import PageContainer from '../components/PageContainer';
import FormImage from '../components/form/Image';
import LoaderWithLogo from '@components/LoaderWithLogo';
import { formLayout } from '../config/constants';

const EditDiscountScreen = () => {
	const { discounts, updateDiscount } = useContext(DiscountContext);
	const { stores } = useContext(StoreContext);

	const [discount, setDiscount] = useState<Discount>();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const navigate = useNavigate();
	const id = useParams().id!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
	const [form] = Form.useForm();

	useEffect(() => {
		(async () => {
			const savedDiscount = discounts.find((discount) => discount.id === parseInt(id));
			if (savedDiscount) {
				setDiscount(savedDiscount);
				return;
			}

			const { data } = await r.get<Discount>(`/admin/discounts/${id}`);
			setDiscount(data);
		})();
	}, [id, discounts]);

	function selectAllStores() {
		form.setFieldValue(
			'stores',
			stores.map((store) => store.id)
		);
	}

	if (!discount) {
		return <LoaderWithLogo />;
	}

	const submit = async (form: any) => {
		const body = new FormData();
		body.append('id', discount.id.toString());
		body.append('text', form.text);
		if (form.image[0].originFileObj) {
			body.append('image', form.image[0].originFileObj);
		}

		form.stores.forEach((storeId: any) => {
			body.append('storeIds[]', storeId);
		});

		try {
			setIsSubmitting(true);
			const response = await r.put<Discount>(`/admin/discounts/${discount.id}`, body);
			updateDiscount(response.data);

			notification.success({
				message: 'Rabattkod uppdaterad!',
				placement: 'bottomRight',
				duration: 5,
			});

			navigate(-1);
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
		setIsSubmitting(false);
	};

	return (
		<PageContainer back title="Uppdatera rabattkod">
			<Form
				{...formLayout}
				labelAlign="left"
				form={form}
				size="large"
				onFinish={submit}
				initialValues={{
					stores: discount.storeIds,
					text: discount.text,
					image: discount.imageUrl
						? [
								{
									uid: '-1',
									name: discount.imageUrl.split('/').reverse()[0],
									status: 'done',
									url: discount.imageUrl,
								},
						  ]
						: [],
				}}
			>
				<Card>
					<Form.Item
						extra={
							<Button style={{ marginTop: 10 }} onClick={selectAllStores}>
								Välj alla butiker
							</Button>
						}
						label="Butik"
						name="stores"
						rules={[{ required: true, message: 'Vänligen välj minst en butik' }]}
					>
						<Select
							mode="multiple"
							placeholder="Butiker"
							allowClear
							style={{ width: '100%' }}
							optionFilterProp="label"
							options={stores.map((store) => ({
								value: store.id,
								label: store.name,
							}))}
						/>
					</Form.Item>
					<Divider />
					<FormImage
						label="QR-kod"
						name="image"
						rules={[{ required: true, message: 'Vänligen ladda upp en QR-kod' }]}
					/>
					<Divider />
					<Form.Item label="Beskrivning" name="text" rules={[{ required: true, message: 'Vänligen ange beskrivning' }]}>
						<Input placeholder="Beskrivning" />
					</Form.Item>
				</Card>
				<div className={styles.actions}>
					<Form.Item>
						<Space size="middle">
							<Button onClick={() => navigate(-1)} type="text">
								Avbryt
							</Button>
							<Button icon={<EditOutlined />} type="primary" htmlType="submit" loading={isSubmitting}>
								Uppdatera rabattkod
							</Button>
						</Space>
					</Form.Item>
				</div>
			</Form>
		</PageContainer>
	);
};

export default EditDiscountScreen;
