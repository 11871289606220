interface Props {
	content: string;
}

const FaultReportDescription = ({ content }: Props) => {
	const videoLinks = extractYouTubeVideoLinks(content);

	return (
		<div>
			<div className="non-video-content">
				<pre>{content}</pre>
			</div>
			<div className="video-content">
				{videoLinks.map((link, index) => (
					<div key={index} className="video-container">
						<iframe
							src={`https://www.youtube.com/embed/${getYoutubeVideoId(link)}`}
							title={`YouTube Video ${index}`}
							allowFullScreen
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default FaultReportDescription;

export function extractYouTubeVideoLinks(content: string): string[] {
	const videoIdRegex = /(?:https?:\/\/(?:www\.)?youtube\.com\/watch\?v=|https?:\/\/youtu\.be\/)([\w-]{11})/g;
	return Array.from(content.matchAll(videoIdRegex)).map((match) => match[0]);
}

export function splitContentByVideoLinks(content: string, videoLinks: string[]): string[] {
	const segments = content.split(videoLinks[0]);
	for (let i = 1; i < videoLinks.length; i++) {
		const linkIndex = content.indexOf(videoLinks[i]);
		if (linkIndex !== -1) {
			segments.splice(
				i,
				0,
				content.substring(segments[i - 1].length + linkIndex, segments[i - 1].length + linkIndex + videoLinks[i].length)
			);
		}
	}
	return segments;
}

export function getYoutubeVideoId(url: string): string {
	const videoIdRegex = /(?:https?:\/\/(?:www\.)?youtube\.com\/watch\?v=|https?:\/\/youtu\.be\/)([\w-]{11})/;
	const match = url.match(videoIdRegex);
	return match ? match[1] : '';
}
