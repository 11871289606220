import { useContext, useMemo, useState } from 'react';
import { Input, Row, Button, Modal, Typography, Divider, Card } from 'antd';
import { RightOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import OrderContext from '../state/Order';
import styles from './styles/OrderScreen.module.scss';
import { useNavigate } from 'react-router-dom';
import Item from './components/Item';
import PageContainer from '../components/PageContainer';
import ProductContext from '../state/Product';
import StoreSelector from '../components/StoreSelector';
import StoreContext from '../state/Store';
import Product from '../models/Product';
import useStoreProducts from '@hooks/store-products';

const { Title } = Typography;
const { Search } = Input;

const OrderScreen = () => {
	const { stores } = useContext(StoreContext);
	const { products: allProducts } = useContext(ProductContext);
	const { order, totalCost, setOrderNote, resetOrder, setOrderStoreId } = useContext(OrderContext);
	const { storeProducts } = useStoreProducts(order.storeId);
	const [searchTerm, setSearchterm] = useState('');

	const navigate = useNavigate();

	const products = useMemo(
		() =>
			storeProducts
				? storeProducts
						.map((productId: number) => allProducts.find((product) => product.id === productId))
						.filter((product): product is Product => !!product)
				: [],
		[storeProducts, allProducts]
	);

	const displayedProducts = products.filter((product) => {
		return (
			product.name.toLowerCase().indexOf(searchTerm.toString().toLowerCase()) >= 0 ||
			product.ean.toLowerCase().indexOf(searchTerm) >= 0 ||
			product.articleNumber.toLowerCase().indexOf(searchTerm) >= 0
		);
	});

	const { TextArea } = Input;
	const updateField = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const { value } = e.target;
		setOrderNote(value);
	};

	const resetConfirmModal = () => {
		Modal.confirm({
			title: 'Vill du nollställa din order?',
			icon: <ExclamationCircleOutlined />,
			content: `Är du säker på att du vill nollställa din påbörjade order? Den här åtgärden kan inte ångras.`,
			okText: 'Ja, nollställ',
			okType: 'danger',
			cancelText: 'Avbryt',
			onOk: resetOrder,
		});
	};

	const hasProducts = order.products.length > 0;

	return (
		<PageContainer title="Beställ">
			<Card>
				<Title level={4} style={{ margin: '0px 0px 20px 0px' }}>
					Notering
				</Title>
				<TextArea
					name="note"
					placeholder="Skriv meddelande till leverantören här..."
					rows={4}
					onChange={updateField}
					value={order?.note}
				/>
				<Divider />
				<Title level={4}>Butik</Title>
				{order.storeId && <StoreSelector stores={stores} selectedStore={order.storeId} onChange={setOrderStoreId} />}
				<Divider />

				<Title level={4}>Välj produkter</Title>

				<div className={styles.actionBar}>
					<Search
						value={searchTerm}
						onChange={(e) => setSearchterm(e.target.value)}
						size="large"
						placeholder="Sök efter namn, artikelnummer eller EAN"
						style={{ width: 400 }}
					/>
					<Button
						type="primary"
						size="large"
						danger
						icon={<DeleteOutlined />}
						disabled={!hasProducts}
						onClick={resetConfirmModal}
					>
						Nollställ
					</Button>
				</div>
				<Divider />
				<Row gutter={[8, 8]}>
					{displayedProducts.map((product) => (
						<Item key={product.id} {...product}></Item>
					))}
				</Row>
				<Divider />
				<div className={styles.actionBar}>
					<Title level={4}>Totalt: SEK {totalCost.toFixed(2)}</Title>

					<Button type="primary" size="large" onClick={() => navigate('summary')} disabled={!hasProducts}>
						Fortsätt
						<RightOutlined />
					</Button>
				</div>
			</Card>
		</PageContainer>
	);
};

export default OrderScreen;
